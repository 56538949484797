import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/СapabilityBox.module.css';

const СapabilityBox = ({ title, text, image, imagePosition }) => {
    const renderImage = useCallback(() => {
        return (
            <img src={image} alt={title} className={styles.capabilityImage} />
        );
    }, [image, title]);

    return (
        <div className={styles.capabilityBox}>
            {imagePosition === 'left' && renderImage()}
            <div className={styles.capabilityText}>
                <h3 className={styles.capabilityTitle}>{title}</h3>
                <p className={styles.capabilityDescription}>{text}</p>
            </div>
            {imagePosition === 'right' && renderImage()}
        </div>
    );
};

СapabilityBox.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imagePosition: PropTypes.oneOf(['left', 'right']),
};

СapabilityBox.defaultProps = {
    imagePosition: 'right',
};

export default memo(СapabilityBox);
