import React, { useEffect, useCallback, useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { learnIRCode, saveIRCode, sendIRCode } from '../../utils/api';
import { ClipLoader } from 'react-spinners';

import styles from './styles/DevicePopupContent.module.css';

/* Процесс добавления новой команды:
    1. Пользователь нажимает на кнопку "Добавить команду".
    2. Пользователь выбирает или вводит название команды и нажимает "Далее".
    3. Мы отправляем на сервер запрос на включение режима приёма сигнала.
    4. Пользователь нажимает на кнопку на пульте, которую хочет использовать для команды.
    5. Мы получаем код команды в learned_ir_code.
    6. Пользователь нажимает "Добавить".
    7. Мы отправляем на сервер запрос на добавление новой команды с названием и кодом.
*/

const DevicePopupRemote = ({ statusText, personalId, deviceId, handleDeviceToggle, remoteButtons, isZigbee }) => {
    const [isAddingRemoteButton, setIsAddingRemoteButton] = useState(false);
    const [newButtonNumber, setNewButtonNumber] = useState(null);
    const [newButtonName, setNewButtonName] = useState('');
    const [newButtonStep, setNewButtonStep] = useState(1);
    const [newIrCode, setNewIrCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const newRemoteButtons = useMemo(() => {
        if (remoteButtons) {
            return remoteButtons.filter(button => button.ir_code).map(button => ({
                id: button.id,
                buttonName: button.buttonName,
            }));
        }
        return [];
    }, [remoteButtons]);

    const isButtonNameUnique = useCallback(name => {
        return !newRemoteButtons.some(button => button.buttonName.toLowerCase() === name.toLowerCase());
    }, [newRemoteButtons]);

    const handleAddRemoteButton = useCallback(() => {
        setNewButtonNumber(null);
        setNewButtonName('');
        setNewButtonStep(1);
        setIsAddingRemoteButton(true);
        setIsLoading(false);
        setErrorMessage('');
    }, []);

    useEffect(() => {
        if (statusText.learned_ir_code && newButtonStep === 2) {
            setNewIrCode(statusText.learned_ir_code);
            setNewButtonStep(3);
        }
    }, [newButtonStep, statusText]);

    useEffect(() => {
        if (newButtonStep === 1) {
            setNewIrCode(null);
            handleDeviceToggle(deviceId, false, { learned_ir_code: null });
        }
    }, [deviceId, handleDeviceToggle, newButtonStep]);

    const handleAddRemoteButtonNext = useCallback(async () => {
        if (!newButtonName.trim()) {
            setErrorMessage('Название команды не может быть пустым');
            return;
        }

        if (!isButtonNameUnique(newButtonName)) {
            setErrorMessage('Команда с таким названием уже существует');
            return;
        }

        setErrorMessage('');
        setIsLoading(true);
        setNewButtonStep(2);
        await learnIRCode(personalId, deviceId);
        setIsLoading(false);
    }, [deviceId, personalId, newButtonName, isButtonNameUnique]);

    const handleAddRemoteButtonAdd = useCallback(async () => {
        setIsLoading(true);
        await saveIRCode(personalId, deviceId, newButtonName, newIrCode);
        setIsLoading(false);
        setIsAddingRemoteButton(false);
    }, [deviceId, newButtonName, newIrCode, personalId]);

    return (
        <div className={`${styles.statusBox} ${styles.remoteButtonsContainer}`}>
            <div className={styles.remoteButtonsBox}>
                {newRemoteButtons.map((button, index) => (
                    <button
                        key={index}
                        className={styles.remoteButton}
                        onClick={() => sendIRCode(personalId, deviceId, button.id)}
                    >
                        {button.buttonName}
                    </button>
                ))}
                {isZigbee && (
                    <button
                        className={`${styles.remoteButton} ${styles.remoteButtonAdd}`}
                        onClick={isAddingRemoteButton ? () => setIsAddingRemoteButton(false) : handleAddRemoteButton }
                    >{isAddingRemoteButton ? 'Отмена' : 'Добавить команду'}</button>
                )}
            </div>
            {isAddingRemoteButton && (
                <div className={styles.remoteButtonAddNewBox}>
                    <h1 className={styles.remoteButtonAddNewTitle}>Добавить команду</h1>

                    {isLoading && (
                        <div className={styles.remoteButtonAddNewLoading}>
                            <ClipLoader color="#4A90E2" size={25} />
                        </div>
                    )}
                    {!isLoading && newButtonStep === 1 && (<>
                        <p className={styles.remoteButtonAddNewText}>Выберите или введите название команды.</p>
                        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                        <div className={styles.remoteButtonInputBox}>
                            <button
                                className={newButtonNumber === 1 ? styles.remoteButtonInputButtonActive : styles.remoteButtonInputButton}
                                onClick={() => {
                                    setNewButtonNumber(1);
                                    setNewButtonName('Включить');
                                }}
                            >Включить</button>
                            <button
                                className={newButtonNumber === 2 ? styles.remoteButtonInputButtonActive : styles.remoteButtonInputButton}
                                onClick={() => {
                                    setNewButtonNumber(2);
                                    setNewButtonName('Выключить');
                                }}
                            >Выключить</button>
                            <input
                                type="text"
                                placeholder="Своё название"
                                className={newButtonNumber === 3 ? styles.remoteButtonInputButtonActive : styles.remoteButtonInputButton}
                                onClick={() => setNewButtonNumber(3)}
                                onChange={(e) => setNewButtonName(e.target.value)}
                            />
                        </div>
                        <div className={styles.remoteButtonAddNewButtonsBox}>
                            <button
                                className={`${styles.remoteButton} ${styles.remoteButtonAddBack}`}
                                onClick={() => setIsAddingRemoteButton(false)}
                            >Отмена</button>
                            <button
                                className={`${styles.remoteButton} ${styles.remoteButtonAddNew}`}
                                onClick={handleAddRemoteButtonNext}
                            >Далее</button>
                        </div>
                    </>)}
                    {!isLoading && newButtonStep === 2 && (<>
                        <p className={styles.remoteButtonAddNewText}><b>Название новой команды:</b> {newButtonName}</p>
                        <p className={styles.remoteButtonAddNewText}><b>Для записи новой команды:</b></p>
                        <p className={styles.remoteButtonAddNewText}>1. Держите пульт от устройства в 15 см от умного пульта. Ближе не нужно.</p>
                        <p className={styles.remoteButtonAddNewText}>2. Нажмите кнопку на пульте, которую хотите использовать для новой команды.</p>
                        <p className={styles.remoteButtonAddNewText}>3. Подождите несколько секунд. Если ничего не произошло, попробуйте еще раз.</p>

                        <div className={styles.remoteButtonAddNewLoading}>
                            <ClipLoader color="#4A90E2" size={25} />
                        </div>

                        <div className={styles.remoteButtonAddNewButtonsBox}>
                            <button
                                className={`${styles.remoteButton} ${styles.remoteButtonAddBack}`}
                                style={{ width: '50%' }}
                                onClick={() => setNewButtonStep(1)}
                            >Назад</button>
                        </div>
                    </>)}
                    {!isLoading && newButtonStep === 3 && (<>
                        <p className={styles.remoteButtonAddNewText}><b>Название новой команды:</b> {newButtonName}</p>
                        <p className={styles.remoteButtonAddNewText}>Код новой команды расспознан. Нажмите "Добавить", чтобы сохранить новую команду.</p>
                        <div className={styles.remoteButtonAddNewButtonsBox} style={{ marginTop: '20px' }}>
                            <button
                                className={`${styles.remoteButton} ${styles.remoteButtonAddBack}`}
                                onClick={() => setNewButtonStep(1)}
                            >Назад</button>
                            <button
                                className={`${styles.remoteButton} ${styles.remoteButtonAddNew}`}
                                onClick={handleAddRemoteButtonAdd}
                            >Добавить</button>
                        </div>
                    </>)}
                </div>
            )}
        </div>
    );
};

DevicePopupRemote.propTypes = {
    statusText: PropTypes.object,
    deviceId: PropTypes.number.isRequired,
    personalId: PropTypes.string.isRequired,
    handleDeviceToggle: PropTypes.func.isRequired,
    remoteButtons: PropTypes.array,
    isZigbee: PropTypes.bool.isRequired,
};

DevicePopupRemote.defaultProps = {
    statusText: null,
    remoteButtons: null,
};

export default memo(DevicePopupRemote);
