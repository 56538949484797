export const getLanguageFromLocation = async () => {
    try {
        const response = await fetch(`https://ipapi.co/json/`);
        const data = await response.json();
        const countryCode = data.country_code;

        // Определяем язык на основе кода страны
        switch (countryCode) {
            case 'RU':
                return 'ru';
            case 'US':
            case 'GB':
            case 'CA':
            case 'AU':
                return 'en';
            default:
                return 'en'; // Дефолтный язык, если страна не распознана
        }
    } catch (error) {
        console.error('Ошибка при определении локации:', error);
        return 'en'; // Если ошибка, используем английский как дефолт
    }
};
