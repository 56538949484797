import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { getHomeData, saveHomeDevices } from '../../../api';
import DeviceList from './DeviceList';

import styles from './styles/HouseEditBlock.module.css';

const DeviceEditBlock = ({ devicesData, setDevicesData, houseId, blockType }) => {
  const [devices, setDevices] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    if (devicesData && devicesData.length > 0 && blockType === "file") {
      setDevices(devicesData.map(device => {
        if (device.zigbeeName != null) {
          return null;
        }

        let deviceType = null;
        // logic for output and input devices - вынести в отдельную функцию
        if (device.type === 'lineq') {
          if (device.lineType === 0) {
            deviceType = 'light';
          }
        } else if (device.type === 'linei') {
          if (device.lineType === 0) {
            deviceType = 'switch';
          } else if (device.lineType === 2) {
            deviceType = 'motion';
          }
        }

        return {
          id: Number(device.line),
          line: Number(device.line),
          name: device.name,
          lineType: `${device.type}-${device.lineType}`,
          type: deviceType,
          roomId: null,
        };
      }).filter(device => device != null));
    }
  }, [blockType, devicesData]);

  const getData = useCallback(async () => {
    try {
      if (houseId) {
        const { data } = await getHomeData(houseId);
        if (data?.home) {
          const floorsData = data.home.floors ? data.home.floors.map(floor => ({
            id: floor.id,
            name: floor.floorName,
          })) : [];
          setFloors(floorsData);

          const roomsData = data.home.rooms ? data.home.rooms.map(room => ({
            id: room.id,
            name: room.roomName,
            floorId: floorsData.find(floor => (floor.id === room.floorId))?.id,
          })) : [];
          setRooms(roomsData);

          if (blockType === "edit") {
            const homeDevicesData = data.home.devices ? data.home.devices.map(device => {
              if (device.zigbeeName != null) {
                return null;
              }

              return {
                id: device.id,
                tableId: device.id,
                line: device.lineId,
                name: device.deviceName,
                lineType: device.lineType,
                type: device.deviceType,
                roomId: device.roomId,
              };
            }).filter(device => device != null) : [];
            setDevices(homeDevicesData);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [blockType, houseId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleNameChange = useCallback((id, name) => {
    setDevices(devices.map(device => device.id === id ? { ...device, name } : device));
  }, [devices]);
  
  const handleTypeChange = useCallback((id, type) => {
    setDevices(devices.map(device => device.id === id ? { ...device, type: type === "-1" ? null : type } : device));
  }, [devices]);

  const handleRoomChange = useCallback((id, roomId) => {
    setDevices(devices.map(device => device.id === id ? { ...device, roomId: roomId === -1 ? null : roomId } : device));
  }, [devices]);

  const removeDevice = useCallback(id => {
    setDevices(devices.filter(device => device.id !== id));
  }, [devices]);

  const handleSave = useCallback(async () => {
    const hasEmptyNames = devices.some(device => !device.name || device.name.trim() === '');
    if (hasEmptyNames) {
      alert('Все устройства должны иметь названия.');
      return;
    }

    try {
      await saveHomeDevices({ homeId: houseId, devices, isFromFile: blockType === "file" });
      alert('Устройства успешно сохранены!');
    } catch (error) {
      console.error("Ошибка при сохранении:", error);
      alert('Ошибка при сохранении');
    }
  }, [houseId, devices, blockType]);

  const outputDevices = useMemo(() => {
    return devices.filter(device => {
      const lineType = device.lineType.split('-')[0];
      return lineType === 'lineq';
    }).sort((a, b) => {
      const lineA = a.line != null ? a.line : Infinity;
      const lineB = b.line != null ? b.line : Infinity;
      return lineA - lineB;
    });
  }, [devices]);

  const inputDevices = useMemo(() => {
    return devices.filter(device => {
      const lineType = device.lineType.split('-')[0];
      return lineType === 'linei';
    }).sort((a, b) => {
      const lineA = a.line != null ? a.line : Infinity;
      const lineB = b.line != null ? b.line : Infinity;
      return lineA - lineB;
    });
  }, [devices]);

  const deviceListProps = {
    handleNameChange,
    handleTypeChange,
    handleRoomChange,
    removeDevice,
    floors,
    rooms,
  };

  return (
    <div>
      <DeviceList
        {...deviceListProps}
        type="output"
        devices={outputDevices}
      />

      <DeviceList
      {...deviceListProps}
        type="input"
        devices={inputDevices}
      />

      <button onClick={handleSave} className={styles.saveButton}>
        {blockType === "file" ? "Добавить устройства" : "Сохранить изменения"}
      </button>
      {blockType === "file" && devicesData.length > 0 && (
        <button onClick={() => setDevicesData([])} className={styles.button}>
          Очистить
        </button>
      )}
    </div>
  );
}

DeviceEditBlock.propTypes = {
  devicesData: PropTypes.arrayOf(PropTypes.object),
  setDevicesData: PropTypes.func,
  houseId: PropTypes.number.isRequired,
  blockType: PropTypes.oneOf(["file", "edit"]),
};

DeviceEditBlock.defaultProps = {
  devicesData: [],
  setDevicesData: () => {},
  blockType: null,
};

export default memo(DeviceEditBlock);
