import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { getActiveButtonType } from '../../utils/buttons';
import DeviceIcon from './DeviceIcon';
import { BUTTONS } from '../../../constants/deviceTypes';

import styles from './styles/ControlsButton.module.css';

const ControlsButton = ({ type, left, top, deviceId, handleDeviceToggle, status, isPositionAbsolute }) => {
  const buttonType = useMemo(() => getActiveButtonType(type), [type]);

  const isSensor = useMemo(() => buttonType === BUTTONS.BUTTONS_TYPES.SENSORS, [buttonType]);
  const isCurtain = useMemo(() => buttonType === BUTTONS.BUTTONS_TYPES.CURTAIN, [buttonType]);

  if (isPositionAbsolute && (left === null || top === null)) {
    return null;
  }

  return (
    <button
      className={`${styles.button} ${isSensor ? styles.sensor : ''} ${status && !isCurtain ? (
        isSensor ? styles.activeSensor : styles.activeDevice
      ) : ''} ${isCurtain && !status ? styles.activeCurtain : ''}`}
      style={{
        position: isPositionAbsolute ? 'absolute' : 'relative',
        left,
        top,
      }}
      onClick={() => {
        if (!isSensor && type !== BUTTONS.BUTTONS_TYPES.IR_REMOTE) {
          handleDeviceToggle(deviceId, !status === true);
        }
      }}
    >
      <DeviceIcon type={type} style={{ zIndex: 2 }} />
    </button>
  );
};

ControlsButton.propTypes = {
  type: PropTypes.string.isRequired,
  left: PropTypes.number,
  top: PropTypes.number,
  deviceId: PropTypes.number,
  handleDeviceToggle: PropTypes.func.isRequired,
  status: PropTypes.bool,
  isPositionAbsolute: PropTypes.bool,
};

ControlsButton.defaultProps = {
  deviceId: null,
  left: null,
  top: null,
  status: null,
  isPositionAbsolute: true,
};

export default memo(ControlsButton);
