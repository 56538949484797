import React, { memo } from 'react';
import { Route, Routes, Navigate, Link } from 'react-router-dom';
import styles from './styles/Admin.module.css';

// admin components
import Users from './Users';
import Houses from './Houses';

const Admin = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Admin Panel</h1>
      <div className={styles.adminContainer}>
        <div className={styles.menuContainer}>
          <Link className={styles.menuItem} to="/app/admin/users">Пользователи</Link>
          <Link className={styles.menuItem} to="/app/admin/houses">Дома</Link>
        </div>
        <div className={styles.panelContainer}>
          <Routes>
            <Route path="users" element={<Users />} />
            <Route path="houses" element={<Houses />} />

            <Route path="*" element={<Navigate to="/app/admin/users" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default memo(Admin);
