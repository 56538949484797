import React from 'react';

function HomeIcon() {
  return (
    <svg width="19pt" height="19pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '2px' }}>
      <g>
        <path d="m325.25 77.246-192.5 192.5c-6.5625 6.5625-10.25 15.461-10.254 24.742v178.01c0 9.2812 3.6875 18.184 10.25 24.75 6.5664 6.5625 15.469 10.25 24.75 10.25h87.5c9.2812 0 18.184-3.6875 24.75-10.25 6.5625-6.5664 10.25-15.469 10.25-24.75v-87.5c0-9.2812 3.6875-18.184 10.25-24.75 6.5664-6.5625 15.469-10.25 24.75-10.25h70c9.2812 0 18.184 3.6875 24.75 10.25 6.5625 6.5664 10.25 15.469 10.25 24.75v87.5c0 9.2812 3.6875 18.184 10.25 24.75 6.5664 6.5625 15.469 10.25 24.75 10.25h87.5c9.2812 0 18.184-3.6875 24.75-10.25 6.5625-6.5664 10.25-15.469 10.25-24.75v-178.01c-0.003906-9.2812-3.6914-18.18-10.254-24.742l-192.5-192.5c-6.5664-6.5625-15.465-10.25-24.746-10.25s-18.18 3.6875-24.746 10.25z"/>
      </g>
    </svg>
  );
}

export default HomeIcon;
