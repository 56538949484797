import React, { useContext, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import InfoPopup from '../common/InfoPopup';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/PwaInstruction.translations.js';

import styles from './styles/Instruction.module.css';

function PwaInstruction({ setIsShowIOSInstruction }) {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [currentSlide, setCurrentSlide] = useState(0);

    const { current: handleClose } = useRef(() => {
        setIsShowIOSInstruction(false);
    });

    return (
        <InfoPopup onClose={handleClose} isShouldCloseOnOutsideClick={false}>
            <h1 className={styles.title}>{t.title}</h1>
            {!!t.slides[currentSlide]?.image && <img src={t.slides[currentSlide].image} alt="instruction" className={styles.image}/>}
        
            <p className={styles.slideContent}>{t.slides[currentSlide].content}</p> {/* Используем переведенные слайды */}
            <div className={styles.slideControls}>
                <div>
                    {currentSlide > 0 && <button onClick={() => setCurrentSlide(prev => Math.max(prev - 1, 0))} className={styles.button}>{t.buttonBack}</button>}
                </div>
                <div>
                    {currentSlide < t.slides.length - 1 ? (
                        <button onClick={() => setCurrentSlide(prev => Math.min(prev + 1, t.slides.length - 1))} className={styles.button}>{t.buttonNext}</button>
                    ) : (
                        <button onClick={handleClose} className={styles.button}>{t.buttonClose}</button>
                    )}
                </div>
            </div>
        </InfoPopup>
    );
}

PwaInstruction.propTypes = {
    setIsShowIOSInstruction: PropTypes.func.isRequired,
};

export default memo(PwaInstruction);
