import React from 'react';

function WaterLeakIcon() {
  return (
    <svg width="25pt" height="25pt" viewBox="0 0 32 40" fill="inherit" style={{ paddingTop: '5px' }}>
      <path d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM14.8295 8.75351C14.9855 8.5989 15.1404 8.44534 15.2929 8.29289C15.6834 7.90237 16.3166 7.90237 16.7071 8.29289C16.8596 8.44539 17.0146 8.599 17.1707 8.75365L17.1711 8.75413L17.1712 8.7542L17.1712 8.75421L17.1712 8.75424C18.0044 9.57991 18.8677 10.4355 19.5423 11.3114C20.3532 12.3643 21 13.5784 21 15C21 17.7614 18.7614 20 16 20C13.2386 20 11 17.7614 11 15C11 13.5784 11.6468 12.3643 12.4577 11.3114C13.1323 10.4355 13.9956 9.57989 14.8288 8.75421L14.8288 8.75421L14.8288 8.75415L14.8295 8.75351ZM14.0423 12.5317C13.3532 13.4265 13 14.2125 13 15C13 16.6569 14.3431 18 16 18C17.6569 18 19 16.6569 19 15C19 14.2125 18.6468 13.4265 17.9577 12.5317C17.4223 11.8365 16.7498 11.1553 16 10.4101C15.2502 11.1553 14.5777 11.8365 14.0423 12.5317ZM12 23C12 22.4477 12.4477 22 13 22H19C19.5523 22 20 22.4477 20 23C20 23.5523 19.5523 24 19 24H13C12.4477 24 12 23.5523 12 23Z" fillRule="evenodd"/>
    </svg>
  );
}

export default WaterLeakIcon;
