import React, { useContext, useMemo, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import ControlsBigButton from './ControlsBigButton';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/FavoriteDevicesBlock.translations.js';

import styles from './styles/FavoriteDevicesBlock.module.css';

const MOBILE_WIDTH = 480;

const FavoriteDevicesBlock = ({ devices, handleDeviceToggle, roomAndFloorData, handleAddToFavorites }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [isShowAllButtons, setIsShowAllButtons] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH);
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

    const favoriteDevices = useMemo(() => {
        return devices.filter(device => device.isFavorite);
    }, [devices]);

    const devicesToShow = useMemo(() => isMobile ? 3 : 6, [isMobile]);

    if (favoriteDevices.length === 0) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div>
                <div className={styles.buttonsBox}>
                    {favoriteDevices.slice(0, isShowAllButtons ? favoriteDevices.length : devicesToShow).map((device, index) => (
                        <ControlsBigButton
                            key={index}
                            type={device.deviceType}
                            name={device.deviceName}
                            deviceId={device.id}
                            handleDeviceToggle={handleDeviceToggle}
                            status={device.status}
                            statusText={device.statusText}
                            roomName={device.roomName}
                            position={device.position}
                            roomAndFloorData={roomAndFloorData}
                            floorId={device.floorId}
                            isFavorite={device.isFavorite}
                            handleAddToFavorites={handleAddToFavorites}
                            isShowFloorName={true}
                            isZigbee={device.isZigbee}
                            roomId={device.roomId}
                            positionLeft={device.positionLeft}
                            positionTop={device.positionTop}
                            updatedAt={device.updatedAt}
                            remoteButtons={device.remoteButtons}
                        />
                    ))}
                </div>
            </div>
            {favoriteDevices.length > devicesToShow && (
                <div className={styles.headerBox}>
                    <div className={styles.buttons}>
                        <div onClick={() => setIsShowAllButtons(!isShowAllButtons)} className={styles.button}>
                            {isShowAllButtons ? t.hide : `${t.all} ${favoriteDevices.length}`}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

FavoriteDevicesBlock.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    handleDeviceToggle: PropTypes.func.isRequired,
    roomAndFloorData: PropTypes.shape({}),
    handleAddToFavorites: PropTypes.func.isRequired,
};

FavoriteDevicesBlock.defaultProps = {
    devices: [],
    roomAndFloorData: {},
};

export default memo(FavoriteDevicesBlock);
