import React, { useState, useEffect, useCallback, memo } from 'react';
import { getAllUsers } from '../../api';

import styles from './styles/Houses.module.css';

const Users = () => {
  const [users, setUsers] = useState([]);

  const getUsers = useCallback(async () => {
    try {
      const { data } = await getAllUsers();
      if (data?.users) {
        setUsers(data.users);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (!users || users.length === 0) {
    return (
      <div>
        <div className={styles.titleBox}>
          <h1 className={styles.panelTitle}>Список пользователей</h1>
        </div>
        <div>
          <p className={styles.text}>Нет данных</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.titleBox}>
        <h1 className={styles.panelTitle}>Список пользователей</h1>
      </div>
      <div>
        <table className={styles.housesTable}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Номер телефона</th>
              <th>Email</th>
              <th>Admin</th>
              <th>Test</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.phone ? `+${user.phone}` : "-"}</td>
                <td>{user.email ? user.email : "-"}</td>
                <td>{user.admin ? "admin" : ""}</td>
                <td>{user.test ? "test" : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(Users);
