import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import RoomDevices from '../common/RoomDevices';

import styles from './styles/RoomPage.module.css';

const RoomPage = ({ devices, rooms, floors, handleDeviceToggle, roomAndFloorData, handleAddToFavorites }) => {
    const { roomId } = useParams();
    const roomData = rooms.find(room => room.id === Number(roomId));

    if (!roomData) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.sideBlockLeft}>
                    <p className={styles.title}>{roomData.name}</p>
                    {roomAndFloorData?.showFloorNames && <p className={styles.subtitle}>{floors.find(floor => floor.id === roomData.floorId)?.name}</p>}
                </div>
                <div className={styles.centerBlock}></div>
                <div className={styles.sideBlockRight}></div>
            </div>

            <div className={styles.container}>
                <div className={styles.allComponentsBox}>
                    <RoomDevices
                        devices={devices}
                        name={roomData.name}
                        roomId={Number(roomId)}
                        handleDeviceToggle={handleDeviceToggle}
                        roomAndFloorData={roomAndFloorData}
                        isShowName={false}
                        handleAddToFavorites={handleAddToFavorites}
                    />
                </div>
            </div>
        </div>
    );
};

RoomPage.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    floors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleDeviceToggle: PropTypes.func.isRequired,
    roomAndFloorData: PropTypes.shape({}),
    handleAddToFavorites: PropTypes.func.isRequired,
};

RoomPage.defaultProps = {
    roomAndFloorData: {},
};

export default memo(RoomPage);
