import React, { useCallback, useContext, memo } from 'react';
import { submitApplication } from '../utils/api.js';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from '../language/LandingContainer.translations.js';

import styles from './styles/Form.module.css';

const Form = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      telegram: event.target.telegram.value,
      location: event.target.location.value,
      type: event.target.type.value,
      comment: event.target.comment.value,
      page: "main_landing",
    };

    try {
      const response = await submitApplication(formData);
      if (response.status === 200) {
        alert(language === "ru" ? "Заявка успешно отправлена!" : "Application has been sent successfully!");
      }
    } catch (error) {
      console.error('Ошибка при отправке формы:', error);
    }
  }, [language]);

  return (
    <div className={styles.contactForm} id="requestForm">
      <h1 className={styles.title}>{t.formTitle}</h1>
      <form id="contact-form" className={styles.formBox} onSubmit={handleSubmit}>
        <div className={styles.formColumns}>
          <div className={styles.formColumn}>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="name" className={styles.fieldLabel}>{t.formName}</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="name"
                placeholder={t.formNamePlaceholder}
                type="text"
                id="name"
                required
              />
            </div>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="phone" className={styles.fieldLabel}>{t.formPhone}</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="phone"
                placeholder={t.formPhonePlaceholder}
                type="tel"
                id="phone"
                required
              />
            </div>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="type" className={styles.fieldLabel}>{t.formType}</label>
              <select id="type" name="type" className={styles.formInput} required>
                  <option value="">{t.formTypePlaceholder}</option>
                  <option value="apartment">{t.formTypeApartment}</option>
                  <option value="house">{t.formTypeHouse}</option>
                  <option value="office">{t.formTypeOffice}</option>
              </select>
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="email" className={styles.fieldLabel}>Email</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="email"
                placeholder={t.formEmailPlaceholder}
                type="email"
                id="email"
                required
              />
            </div>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="telegram" className={styles.fieldLabel}>{t.formTelegram}</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="telegram"
                placeholder="@username"
                type="text"
                id="telegram"
              />
            </div>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="location" className={styles.fieldLabel}>{t.formLocation}</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="location"
                placeholder={t.formLocationPlaceholder}
                type="text"
                id="location"
                required
              />
            </div>
          </div>
        </div>
        <div className={styles.formFieldWrapper}>
          <label htmlFor="comment" className={styles.fieldLabel}>{t.formComment}</label>
          <textarea
            id="comment"
            name="comment"
            maxLength="5000"
            placeholder={t.formCommentPlaceholder}
            className={styles.formInput}
          ></textarea>
        </div>
        <input type="submit" value={t.formButton} className={styles.submitButton} />
      </form>
    </div>
  );
};

export default memo(Form);
