
const translations = {
    ru: {
        title: 'GETHOME - Личный кабинет',

        // menu
        menuAdmin: 'Admin',
        menuHome: 'Главная',
        menuSettings: 'Настройки',
        menuLogout: 'Выйти',
    },
    en: {
        title: 'GETHOME - Personal account',

        // menu
        menuAdmin: 'Admin',
        menuHome: 'Home',
        menuSettings: 'Settings',
        menuLogout: 'Logout',
    },
};

export default translations;
