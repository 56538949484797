import { BUTTONS } from '../../constants/deviceTypes';

// По типу устройства определяем к какой группе кнопок оно относится
export const getActiveButtonType = deviceType => {

  switch (deviceType) {
    case 'light':
      return BUTTONS.BUTTONS_TYPES.LIGHT;
    case 'socket':
      return BUTTONS.BUTTONS_TYPES.SOCKET;
    case 'water':
      return BUTTONS.BUTTONS_TYPES.WATER;
    case 'motion':
      return BUTTONS.BUTTONS_TYPES.SENSORS;
    case 'magnet':
      return BUTTONS.BUTTONS_TYPES.SENSORS;
    case 'water_leak':
      return BUTTONS.BUTTONS_TYPES.SENSORS;
    case 'smoke':
      return BUTTONS.BUTTONS_TYPES.SENSORS;
    case 'temperature_humidity':
      return BUTTONS.BUTTONS_TYPES.SENSORS;
    case 'temperature':
      return BUTTONS.BUTTONS_TYPES.SENSORS;
    case 'one_button_switch':
      return BUTTONS.BUTTONS_TYPES.SENSORS;
    case 'curtain':
      return BUTTONS.BUTTONS_TYPES.CURTAIN;
    case 'switch':
      return BUTTONS.BUTTONS_TYPES.SWITCH;
    case 'ir_remote':
      return BUTTONS.BUTTONS_TYPES.IR_REMOTE;
    default:
      return null;
  }

};
