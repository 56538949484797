import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { getHomeData, saveHomeDevices } from '../../../api';

import styles from './styles/HouseEditBlock.module.css';

const Zigbee2mqtt = ({ houseId }) => {
    const [devices, setDevices] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [floors, setFloors] = useState([]);

    // TODO: добавить вывод типов, сделать скачивание дааных в файл

    const fetchData = useCallback(async () => {
        try {
            const { data } = await getHomeData(houseId);
            if (data?.home) {
                setFloors(data.home.floors || []);
                setRooms(data.home.rooms || []);

                const devices = data.home.devices || [];
                const devicesWithZigbee = devices.filter(device => device.zigbeeName).map(device => {
                    const lineType = device.lineType ? (device.lineType === 'linei-' ? 1 : 2) : null;
                    return { ...device, lineType };
                });
                setDevices(devicesWithZigbee);
            }
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    }, [houseId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleLineChange = useCallback((id, newLineId) => {
        setDevices(devices.map(device => device.id === id ? { ...device, lineId: newLineId } : device));
    }, [devices]);

    const saveDeviceChanges = useCallback(async () => {
        try {
            const saveDevices = devices.filter(device => device.zigbeeName != null).map(device => {
                let lineType = null;
                if (device.lineType) {
                    if (device.lineType === 1) {
                        lineType = 'linei-';
                    } else if (device.lineType === 2) {
                        lineType = 'lineq-';
                    }
                }

                return {
                    ...device,
                    tableId: device.id,
                    name: device.deviceName,
                    type: device.deviceType,
                    line: Number(device.lineId) || null,
                    lineType,
                };
            });

            // isFromFile: true - чтобы не удалялись устройства
            await saveHomeDevices({ homeId: houseId, devices: saveDevices, isFromFile: true });
            alert('Изменения успешно сохранены!');
        } catch (error) {
            console.error("Ошибка при сохранении:", error);
            alert('Ошибка при сохранении');
        }
    }, [devices, houseId]);

    const getRoomAndFloorName = useCallback(roomId => {
        const room = rooms.find(r => r.id === roomId);
        const floor = floors.find(f => f.id === room?.floorId);
        return `${room ? room.roomName : 'Без комнаты'}${floor ? `, ${floor.floorName}` : ''}`;
    }, [floors, rooms]);

    return (
        <div>
            <h3 className={styles.title}>Настройка Zigbee Устройств</h3>
            <div className={styles.inputBox}>
                <p className={styles.textZigbee}>Устройство (ID, Название, Место)</p>
                <p className={styles.textZigbee}>Тип линии, ID Линии</p>
            </div>
            {devices.map(device => (
                <div key={device.id} className={styles.inputBox}>
                    <p className={styles.textZigbee}>{`${device.id} - ${device.deviceName} (${getRoomAndFloorName(device.roomId)})`}</p>
                    <div>
                        <select
                            onChange={e => setDevices(devices.map(d => d.id === device.id ? { ...d, lineType: Number(e.target.value) === -1 ? null : Number(e.target.value) } : d))}
                            className={styles.select}
                            value={device.lineType === null ? -1 : device.lineType}
                        >
                            <option value={-1}>Выберите тип</option>
                            <option value={1}>Вход (датчик)</option>
                            <option value={2}>Выход (устройство)</option>
                        </select>
                        <input
                            type="number"
                            className={styles.inputNumber}
                            value={device.lineId || ''}
                            onChange={e => handleLineChange(device.id, e.target.value)}
                        />
                    </div>
                </div>
            ))}
            <button onClick={saveDeviceChanges} className={styles.saveButton}>Сохранить изменения</button>
        </div>
    );
};

Zigbee2mqtt.propTypes = {
    houseId: PropTypes.number.isRequired,
};

export default memo(Zigbee2mqtt);
