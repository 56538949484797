import React from 'react';
import PropTypes from 'prop-types';

function CloseIcon({ className, isWhite }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1280" version="1.1" className={className}>
      <path fill="#000000" d="M574.48028556 512l406.25441686-406.25441687a44.18690634 44.18690634 0 0 0-62.48028555-62.48028555L512 449.51971444 105.74558313 43.26529758a44.18690634 44.18690634 0 0 0-62.48028555 62.48028555L449.51971444 512l-406.25441686 406.25441687a44.18690634 44.18690634 0 0 0 62.48028555 62.48028555L512 574.48028556l406.25441687 406.25441686a44.18690634 44.18690634 0 0 0 62.48028555-62.48028555L574.48028556 512z" style={{ fill: isWhite ? 'white' : 'black' }} />
    </svg>
  );
}

CloseIcon.propTypes = {
  className: PropTypes.string,
  isWhite: PropTypes.bool,
};

CloseIcon.defaultProps = {
  className: '',
  isWhite: true,
};

export default CloseIcon;
