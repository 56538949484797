import React, { useContext, useCallback, useRef, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import IosButton from './IosButton';
import { getActiveButtonType } from '../../utils/buttons';
import { BUTTONS } from '../../../constants/deviceTypes';
import { Armchair, Footprints, ShowerHead, Droplet, BedDouble, Utensils, Shapes, Printer, Tv, Warehouse } from 'lucide-react';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/RoomButton.translations.js';

import styles from './styles/RoomButton.module.css';

const RoomButton = ({ name, roomId, className, floorName, deviceStats, handleDeviceToggle, devices }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const navigate = useNavigate();

    const navigateToRoom = useCallback(() => {
        navigate(`../room/${roomId}`);
    }, [navigate, roomId]);

    const handleClick = useCallback((deviceType, status) => {
        if (deviceType === 'light') {
            if (status) {
                const activeLightIds = deviceStats.activeLights;
                handleDeviceToggle(activeLightIds, false);
            } else {
                const notActiveLights = devices
                    .filter(device => device.deviceType === 'light' && !device.status && device.roomId === roomId)
                    .map(device => device.id);
                handleDeviceToggle(notActiveLights, true);
            }
        } else if (deviceType === 'all') {
            if (status) {
                const activeDeviceIds = deviceStats.activeDevices;
                handleDeviceToggle(activeDeviceIds, false);
            } else {
                const notActiveDeviceIds = devices
                    .filter(device => {
                        const buttonType = getActiveButtonType(device.deviceType);
                        return buttonType !== BUTTONS.BUTTONS_TYPES.SENSORS && !device.status && device.roomId === roomId;
                    })
                    .map(device => device.id);
                handleDeviceToggle(notActiveDeviceIds, true);
            }
        }
    }, [deviceStats, devices, handleDeviceToggle, roomId]);

    const totalDevicesCount = useMemo(() => deviceStats.totalDevices, [deviceStats.totalDevices]);
    const activeDevicesCount = useMemo(() => deviceStats.activeDevices.length, [deviceStats.activeDevices]);
    const activeLightsCount = useMemo(() => deviceStats.activeLights.length, [deviceStats.activeLights]);

    const { current: getIconByName } = useRef(name => {
        const lowerCaseName = name.toLowerCase();

        // for russian
        if (lowerCaseName.includes('зал')) return <Tv />;
        if (lowerCaseName.includes('коридор')) return <Footprints />;
        if (lowerCaseName.includes('ванна')) return <ShowerHead />;
        if (lowerCaseName.includes('туалет')) return <Droplet />;
        if (lowerCaseName.includes('спальня')) return <BedDouble />;
        if (lowerCaseName.includes('кухня')) return <Utensils />;
        if (lowerCaseName.includes('детская')) return <Shapes />;
        if (lowerCaseName.includes('кабинет')) return <Printer />;
        if (lowerCaseName.includes('столовая')) return <Utensils />;
        if (lowerCaseName.includes('гостиная')) return <Tv />;
        if (lowerCaseName.includes('прихожая')) return <Footprints />;
        if (lowerCaseName.includes('кладовая')) return <Warehouse />;
        if (lowerCaseName.includes('гараж')) return <Warehouse />;

        // for english
        if (lowerCaseName.includes('hall')) return <Tv />;
        if (lowerCaseName.includes('corridor')) return <Footprints />;
        if (lowerCaseName.includes('bathroom')) return <ShowerHead />;
        if (lowerCaseName.includes('toilet')) return <Droplet />;
        if (lowerCaseName.includes('bedroom')) return <BedDouble />;
        if (lowerCaseName.includes('kitchen')) return <Utensils />;
        if (lowerCaseName.includes('children')) return <Shapes />;
        if (lowerCaseName.includes('office')) return <Printer />;
        if (lowerCaseName.includes('dining')) return <Utensils />;
        if (lowerCaseName.includes('living')) return <Tv />;
        if (lowerCaseName.includes('stoop')) return <Footprints />;
        if (lowerCaseName.includes('foyer')) return <Footprints />;
        if (lowerCaseName.includes('pantry')) return <Warehouse />;
        if (lowerCaseName.includes('garage')) return <Warehouse />;

        return <Armchair />;
    });

    return (
        <div className={`${styles.root} ${className}`}>
            <div onClick={navigateToRoom} className={styles.iconBox}>
                {getIconByName(name)}
            </div>
            <div onClick={navigateToRoom} className={styles.textBox}>
                <p className={styles.title}>{name}</p>
                {floorName && <p className={styles.text}>{floorName}</p>}
                <p className={styles.text}>{t.devices}: {totalDevicesCount}, {t.on}: {activeDevicesCount}, {t.light}: {activeLightsCount}</p>
            </div>
            <div className={styles.buttonBox}>
                <IosButton
                    type="light"
                    onClick={() => handleClick('light', activeLightsCount > 0)}
                    isActive={activeLightsCount > 0}
                    style={{ marginBottom: '5px' }}
                />
                <IosButton
                    type="all"
                    onClick={() => handleClick('all', activeDevicesCount > 0)}
                    isActive={activeDevicesCount > 0}
                />
            </div>
        </div>
    );
};

RoomButton.propTypes = {
    name: PropTypes.string.isRequired,
    roomId: PropTypes.number.isRequired,
    className: PropTypes.string,
    floorName: PropTypes.string,
    deviceStats: PropTypes.shape({
        totalDevices: PropTypes.number.isRequired,
        activeDevices: PropTypes.arrayOf(PropTypes.number).isRequired,
        activeLights: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    handleDeviceToggle: PropTypes.func.isRequired,
    devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

RoomButton.defaultProps = {
    className: '',
    floorName: null,
};

export default memo(RoomButton);
