import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Lightbulb, LightbulbOff, Power, PowerOff } from 'lucide-react';

import styles from './styles/IosButton.module.css';

const IosButton = ({ type, style, isActive, onClick }) => {
    return (
        <div
            className={`${styles.toggleContainer} ${isActive ? styles.toggled : ''}`}
            onClick={onClick}
            style={style}
        >
            {type === 'light' && <Lightbulb className={styles.iconLeft} />}
            {type === 'all' && <Power className={styles.iconLeft} />}

            <div className={styles.toggleCircle}></div>

            {type === 'light' && <LightbulbOff className={styles.iconRight} />}
            {type === 'all' && <PowerOff className={styles.iconRight} />}
        </div>
    );
};

IosButton.propTypes = {
    type: PropTypes.string,
    style: PropTypes.object,
    isActive: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

IosButton.defaultProps = {
    type: null,
    style: {},
    isActive: false,
};

export default memo(IosButton);
