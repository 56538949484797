import React from 'react';

function WaterIcon() {
  return (
    <svg width="18pt" height="18pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '5px' }}>
      <g>
        <path d="m367.36 22.961c-8.9609-11.762-26.32-11.762-35.281 0-45.922 61.602-166.88 233.52-166.88 339.92 0 132.16 95.762 183.12 184.24 183.12 89.039 0 184.24-50.961 184.24-183.12 0.5625-106.96-119.84-278.32-166.32-339.92zm-17.359 460.88c-61.039 0-122.64-37.52-122.64-121.52 0-8.3984 6.7188-15.68 15.68-15.68 8.9609 0 15.68 6.7188 15.68 15.68 0 67.199 47.039 90.719 91.281 90.719 8.3984 0 15.68 6.7188 15.68 15.68 0 8.4023-7.2812 15.121-15.68 15.121z"/>
      </g>
    </svg>
  );
}

export default WaterIcon;
