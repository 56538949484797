
const translations = {
    ru: {
        title: 'GETHOME - Вход и регистрация',

        loginTitle: 'Введите номер телефона',
        loginSubtitle: 'чтобы войти или зарегистрироваться на GETHOME',

        phonePlaceholder: 'Номер телефона',
        phoneMask: '+7 (999) 999-99-99',
        loginButton: 'Продолжить',

        privacyText: 'Нажимая «Продолжить», я принимаю условия',
        privacyLink: 'Пользовательского соглашения',

        codeTitle: 'Введите код из СМС',
        codeSubtitle: 'Код отправлен на номер телефона',
        confirmButton: 'Подтвердить',

        timerResend: 'Отправить код повторно через',
        timerSeconds: 'секунд',
        timerResendButton: 'Отправить код повторно',
    },
    en: {
        title: 'GETHOME - Login and registration',

        loginTitle: 'Enter your phone number',
        loginSubtitle: 'to log in or register on GETHOME',

        phonePlaceholder: 'Phone number',
        phoneMask: '+1 (999) 999-99-99',
        loginButton: 'Continue',

        privacyText: 'By clicking "Continue", I accept the terms of',
        privacyLink: 'User Agreement',

        codeTitle: 'Enter the code from SMS',
        codeSubtitle: 'The code was sent to the phone number',
        confirmButton: 'Confirm',

        timerResend: 'Resend the code in',
        timerSeconds: 'seconds',
        timerResendButton: 'Resend the code',
    },
};

export default translations;
