
const WEBSOCKET_URL = '/control/socket';

const EVENTS = {
    // Home events
    DEVICE_UPDATE_STATUS: 'device_update_status',
    DEVICE_UPDATE: 'device_update',
    REMOVE_FROM_FAVORITES: 'remove_from_favorites',
    ADD_TO_FAVORITES: 'add_to_favorites',
    UPDATE_HOME_DATA: 'update_home_data',

    // Security
    ACTIVATE_SECURITY_MODE: 'activate_security_mode',
    DEACTIVATE_SECURITY_MODE: 'deactivate_security_mode',

    // IR remote
    LEARNED_IR_CODE: 'learned_ir_code',
};

module.exports = {
    WEBSOCKET_URL,
    EVENTS,
};
