
const translations = {
    ru: {
        hide: 'Скрыть',
        all: 'Все',
    },
    en: {
        hide: 'Hide',
        all: 'All',
    },
};

export default translations;
