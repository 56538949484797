
const translations = {
    ru: {
        title: 'Инструкция по добавлению приложения на домашний экран',

        // Buttons
        buttonNext: 'Вперед',
        buttonBack: 'Назад',
        buttonClose: 'Закрыть',

        // Slide content
        slides: [
            {
                content: '1. Нажмите на иконку "Поделиться"',
                image: '../../../images/pwa/1.jpg',
            },
            {
                content: '2. Выберите "На экран Домой"',
                image: '../../../images/pwa/2_ru.jpg',
            },
            {
                content: '3. Измените название приложения, если хотите, и нажмите "Добавить" в верхнем правом углу',
                image: '../../../images/pwa/3.jpg',
            },
            {
                content: '4. Теперь вы можете открыть наше приложение прямо с главного экрана',
            },
        ],
    },
    en: {
        title: 'Instructions for Adding the App to the Home Screen',

        // Buttons
        buttonNext: 'Next',
        buttonBack: 'Back',
        buttonClose: 'Close',

        // Slide content
        slides: [
            { 
                content: '1. Tap the "Share" icon',
                image: '../../../images/pwa/1.jpg',
            },
            {
                content: '2. Select "Add to Home Screen"',
                image: '../../../images/pwa/2_en.jpg',
            },
            {
                content: '3. Change the name of the app if you want, then tap "Add" in the top right corner',
                image: '../../../images/pwa/3.jpg',
            },
            {
                content: '4. Now you can open our app directly from the home screen',
            },
        ],
    },
};

export default translations;
