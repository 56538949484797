import axios from 'axios';

const apiApp = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/app`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// for all users
export function getUser() {
  return apiApp.get('/app');
}

export function getHomes() {
  return apiApp.get('/homes');
}

export function getHome(personalId) {
  return apiApp.get(`/home/${personalId}`);
}

export function getAliceCode(data) {
  return apiApp.post('/alice-code', data);
}

// for admin
export function getAllHomes() {
  return apiApp.get('/all-homes');
}

export function createHome(homeData) {
  return apiApp.post('/home', homeData);
}

export function getAllUsers() {
  return apiApp.get('/all-users');
}

// for edit home data by admin
export function editHomeData(homeId, homeData) {
  return apiApp.post(`/home-edit/${homeId}`, homeData);
}

export function getHomeData(homeId) {
  return apiApp.get(`/home-edit/${homeId}`);
}

export function saveHomeDevices({ homeId, devices, isFromFile }) {
  return apiApp.post(`/home-devices/${homeId}`, { devices, isFromFile });
}

export function uploadHomeFile({ homeId, file }) {
  const formData = new FormData();
  formData.append('file', file);

  return apiApp.post(`/upload-home-file/${homeId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function uploadFlourFile({ floorId, file }) {
  const formData = new FormData();
  formData.append('file', file);

  return apiApp.post(`/upload-floor-file/${floorId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteFlourFile(floorId) {
  return apiApp.delete(`/delete-floor-file/${floorId}`);
}

export function saveDevicesPosition({ homeId, devices }) {
  return apiApp.post(`/devices-position/${homeId}`, { devices });
}

export function deleteHome(homeId) {
  return apiApp.delete(`/home/${homeId}`);
}
