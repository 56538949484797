
const translations = {
    ru: {
        title: 'Настройки разрешений',
        subtitle: 'Настройте разрешения для разных типов пользователей',

        // guests
        guests: 'Гости',
        guestsSecurity: 'Включать и выключать режим охраны',
    },
    en: {
        title: 'Permissions settings',
        subtitle: 'Set permissions for different types of users',

        // guests
        guests: 'Guests',
        guestsSecurity: 'Activate and deactivate security mode',
    },
};

export default translations;
