import React, { useContext, memo } from 'react';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from '../language/LandingContainer.translations.js';

import styles from './styles/ComparisonWithOthers.module.css';

const ComparisonWithOthers = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <div className={styles.comparisonBox}>
      <h1 className={styles.title}>{t.comparisonWithOthersTitle}</h1>
      <div className={styles.comparisonContainer}>
        <div className={styles.comparisonBlock}>
          <h2>{t.comparison1Title}</h2>
          <div className={styles.textContainer}>
            <p>{t.comparison1Description1}</p>
            <p>{t.comparison1Description2}</p>
            <p>{t.comparison1Description3}</p>
          </div>
        </div>

        <div className={styles.comparisonBlock}>
          <h2>{t.comparison2Title}</h2>
          <div className={styles.textContainer}>
            <p>{t.comparison2Description1}</p>
            <p>{t.comparison2Description2}</p>
          </div>
        </div>

        <div className={styles.comparisonBlock}>
          <h2>{t.comparison3Title}</h2>
          <div className={styles.textContainer}>
            <p>{t.comparison3Description1}</p>
            <p>{t.comparison3Description2}</p>
            <p>{t.comparison3Description3}</p>
            <p>{t.comparison3Description4}</p>
            <p>{t.comparison3Description5}</p>
            <p>{t.comparison3Description6}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ComparisonWithOthers);
