import React, { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { getHomeData, editHomeData, deleteHome } from '../../../api';

import styles from './styles/HouseEditBlock.module.css';

const HouseEditBlock = ({ houseId, closeForm, getHouses }) => {
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);

  const getData = useCallback(async () => {
    try {
      if (houseId) {
        const { data } = await getHomeData(houseId);

        if (data?.home) {
          const newFloors = data.home.floors ? data.home.floors.map((floor, index) => ({
            id: index,
            name: floor.floorName,
            tableId: floor.id,
          })) : [];
          setFloors(newFloors);

          const newRooms = data.home.rooms ? data.home.rooms.map((room, index) => ({
            id: index,
            name: room.roomName,
            tableId: room.id, floorId: newFloors?.find(floor => (floor.tableId === room.floorId))?.id,
          })) : [];
          setRooms(newRooms);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [houseId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const addFloor = useCallback(() => {
    setFloors([...floors, { id: floors.length, name: 'Новый этаж', tableId: null }]);
  }, [floors]);

  const addRoom = useCallback(() => {
    setRooms([...rooms, { id: rooms.length, name: 'Новая комната', floorId: null, tableId: null }]);
  }, [rooms]);

  const editFloor = useCallback((id, name) => {
    setFloors(floors.map(floor => floor.id === id ? { ...floor, name } : floor));
  }, [floors]);

  const editRoom = useCallback((id, name, floorId) => {
    setRooms(rooms.map(room => room.id === id ? { ...room, name, floorId } : room));
  }, [rooms]);

  const removeFloor = useCallback(id => {
    setFloors(floors.filter(floor => floor.id !== id));
    setRooms(rooms.map(room => room.floorId === id ? { ...room, floorId: null } : room));
  }, [floors, rooms]);

  const removeRoom = useCallback(id => {
    setRooms(rooms.filter(room => room.id !== id));
  }, [rooms]);

  const saveChanges = useCallback(async () => {
    // TODP: добавить проверку, чтобы названия этажей и комнат были не пустыми

    const floorNames = floors.map(f => f.name);
    const uniqueFloorNames = new Set(floorNames);

    if (floorNames.length !== uniqueFloorNames.size) {
      alert('Названия этажей должны быть уникальными!');
      return;
    }

    if (rooms.some(room => room.floorId === null)) {
      alert('Все комнаты должны быть привязаны к этажу!');
      return;
    }

    try {
      await editHomeData(houseId, { floors, rooms });
      alert('Изменения успешно сохранены!');
    } catch (error) {
      console.error("Ошибка при сохранении:", error);
    }
  }, [floors, rooms, houseId]);

  const homeDelete = useCallback(async () => {
    if (window.confirm('Вы уверены, что хотите удалить дом?')) {
      try {
        await deleteHome(houseId);
        alert('Дом успешно удален!');
        getHouses();
        closeForm();
      } catch (error) {
        console.error("Ошибка при удалении:", error);
        alert(error.response.data.error);
      }
    }
  }, [closeForm, getHouses, houseId]);

  return (
    <div className={styles.container}>
      <div>
        <h2>Этажи</h2>
        {floors.map(floor => (
          <div key={floor.id} className={styles.inputBox}>
            <input
              type="text"
              value={floor.name}
              onChange={e => editFloor(floor.id, e.target.value)}
              className={styles.input}
            />
            <button onClick={() => removeFloor(floor.id)} className={styles.button}>Удалить</button>
          </div>
        ))}
        <button onClick={addFloor} className={styles.button}>Добавить этаж</button>
      </div>

      <div>
        <h2>Комнаты</h2>
        {rooms.map(room => (
          <div key={room.id} className={styles.inputBox}>
            <input
              type="text"
              value={room.name}
              onChange={e => editRoom(room.id, e.target.value, room.floorId)}
              className={styles.input}
            />
            <select
              onChange={e => editRoom(room.id, room.name, Number(e.target.value) === -1 ? null : Number(e.target.value))}
              className={`${styles.select} ${room.floorId === null ? styles.highlight : ''}`}
              value={room.floorId === null ? -1 : room.floorId}
            >
              <option value={-1}>Выберите этаж</option>
              {floors.map(floor => (
                <option key={floor.id} value={floor.id}>{floor.name}</option>
              ))}
            </select>
            <button onClick={() => removeRoom(room.id)} className={styles.button}>Удалить</button>
          </div>
        ))}
        <button onClick={addRoom} className={styles.button}>Добавить комнату</button>
      </div>

      <div>
        <button onClick={saveChanges} className={styles.saveButton}>Сохранить изменения</button>
      </div>

      <div className={styles.deleteBox}>
        <button onClick={homeDelete} className={styles.saveButton}>Удалить дом</button>
      </div>
    </div>
  );
};

HouseEditBlock.propTypes = {
  houseId: PropTypes.number.isRequired,
  closeForm: PropTypes.func.isRequired,
  getHouses: PropTypes.func.isRequired,
};

export default memo(HouseEditBlock);
