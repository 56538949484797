
const translations = {
    ru: {
        title: 'Настройки профиля',

        // profile
        namePlaceholder: 'Ваше имя',
        saveButton: 'Сохранить',

        // messages
        successMessage: 'Данные успешно сохранены',
        errorMessage: 'Ошибка сохранения данных',
    },
    en: {
        title: 'Profile settings',

        // profile
        namePlaceholder: 'Your name',
        saveButton: 'Save',

        // messages
        successMessage: 'Data successfully saved',
        errorMessage: 'Error saving data',
    },
};

export default translations;
