import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/LanguageSettings.translations.js';

import styles from './styles/settings.module.css';
import pageStyles from './styles/LanguageSettings.module.css';

const LanguageSettings = ({ isMobile, onClose }) => {
    const { language, setNewLanguage } = useContext(LanguageContext);
    const t = translations[language];

    return (
        <div className={styles.container}>
            {isMobile && <BackButton onClick={onClose} />}
            <div className={styles.formGroup}>
                <h3 className={styles.title}>{t.title}</h3>
                <p className={styles.text}>{t.subtitle}</p>

                <div className={styles.blockGray}>
                    <p className={styles.blockTitle}>{t.chooseLanguage}</p>

                    <div className={pageStyles.buttonBox}>
                        <button
                            className={`${pageStyles.languageButton} ${language === 'en' ? pageStyles.active : ''}`}
                            onClick={() => setNewLanguage('en')}
                        >English</button>
                        <button
                            className={`${pageStyles.languageButton} ${language === 'ru' ? pageStyles.active : ''}`}
                            onClick={() => setNewLanguage('ru')}
                        >Русский</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

LanguageSettings.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default memo(LanguageSettings);
