import { useState, useEffect, useCallback } from 'react';
import { getCookie } from './cookie';
import { WEBSOCKET_URL } from '../../constants/socket';

export const useWebSocket = (onMessage, fetchControlData) => {
    const [ws, setWs] = useState(null);

    const connectWebSocket = useCallback(async () => {
        if (!ws || ws?.readyState !== WebSocket.OPEN) {
            const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
            const ws_token = getCookie('token');
            const ws_port =
                window.location.protocol === 'https:' ? process.env.REACT_APP_HTTPS_PORT : process.env.REACT_APP_HTTP_PORT;
            const hostname = `${window.location.hostname}:${ws_port}`;

            const newWs = new WebSocket(`${protocol}://${hostname}${WEBSOCKET_URL}?token=${ws_token}`);
            setWs(newWs);

            fetchControlData();

            return newWs;
        }
    }, [fetchControlData, ws]);

    const handleVisibilityChange = useCallback(() => {
        setTimeout(() => {
            if (document.visibilityState === 'visible') {
                if (!ws || ws.readyState === WebSocket.CLOSED) {
                    connectWebSocket();
                }
            }
        }, 500);
    }, [ws, connectWebSocket]);

    useEffect(() => {
        if (ws) {
            ws.onmessage = onMessage;
        } else {
            connectWebSocket();
        }

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [connectWebSocket, ws, onMessage]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    return { ws, connectWebSocket };
};
