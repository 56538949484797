import React from 'react';

function MenuIcon() {
  return (
    <svg width="18pt" height="18pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '3px' }}>
      <g>
        <path d="m246.97 8.1875h-92.25c-20.289 0.027344-39.742 8.0977-54.09 22.445-14.348 14.348-22.418 33.801-22.445 54.09v88.328c0.027344 20.289 8.1016 39.742 22.449 54.09 14.344 14.344 33.797 22.418 54.086 22.445h92.25c20.289-0.027344 39.742-8.0977 54.09-22.445 14.344-14.348 22.418-33.797 22.441-54.09v-88.316c-0.019531-20.289-8.0938-39.742-22.441-54.094-14.348-14.348-33.801-22.418-54.09-22.441zm298.3 0h-92.242c-20.293 0.027344-39.742 8.0977-54.09 22.445-14.348 14.348-22.422 33.797-22.445 54.09v88.328c0.027344 20.293 8.0977 39.742 22.445 54.09 14.348 14.348 33.797 22.418 54.09 22.445h92.242c20.289-0.027344 39.742-8.1016 54.086-22.445 14.348-14.348 22.422-33.801 22.449-54.09v-88.316c-0.027344-20.289-8.0977-39.742-22.445-54.09-14.348-14.348-33.797-22.418-54.09-22.445zm-298.3 302.21h-92.25c-20.289 0.027344-39.738 8.1016-54.086 22.449-14.348 14.344-22.418 33.793-22.449 54.082v88.355c0.027344 20.293 8.0977 39.742 22.445 54.09 14.348 14.348 33.797 22.422 54.09 22.445h92.25c20.289-0.023438 39.742-8.0938 54.09-22.441 14.348-14.348 22.422-33.801 22.441-54.094v-88.336c-0.027344-20.289-8.0977-39.738-22.445-54.086-14.348-14.348-33.797-22.418-54.086-22.445zm298.3 0h-92.242c-20.289 0.027344-39.742 8.1016-54.086 22.445-14.348 14.348-22.422 33.797-22.449 54.086v88.355c0.023438 20.293 8.0938 39.746 22.441 54.094 14.348 14.348 33.801 22.418 54.094 22.441h92.242c20.293-0.023438 39.742-8.0977 54.09-22.445 14.348-14.348 22.418-33.797 22.445-54.09v-88.336c-0.023438-20.293-8.0938-39.746-22.441-54.098-14.348-14.352-33.801-22.426-54.094-22.453z" fillRule="evenodd"/>
      </g>
    </svg>
  );
}

export default MenuIcon;
