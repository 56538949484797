import React, { useContext, memo } from 'react';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from '../language/LandingContainer.translations.js';

import styles from './styles/YourCapabilities.module.css';

const YourCapabilities = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <div className={styles.capabilitiesBox}>
      <h1 className={styles.title}>{t.yourCapabilitiesTitle}</h1>
      <div className={styles.capabilitiesContainer}>
        <div className={styles.row}>
          <div className={styles.capabilityBlock1}>
            <div className={styles.iconAndText}>
              <h2>{t.capability1Title}</h2>
              <p>{t.capability1Description}</p>
            </div>
            <img className={styles.integrationsImg} src="../../images/site/capabilities/integrations.png" alt="Integrations" />
          </div>

          <div className={styles.capabilityBlock2}>
            <div className={styles.iconAndText}>
              <h2>{t.capability2Title}</h2>
              <p>{t.capability2Description1}</p>
              <p>{t.capability2Description2}</p>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.capabilityBlock3}>
            <div className={styles.iconAndText}>
              <h2>{t.capability3Title}</h2>
              <p>{t.capability3Description}</p>
            </div>
            <img className={styles.appScreenshot} src={`../../images/site/capabilities/shots4_${language === 'ru' ? 'ru' : 'en'}.png`} alt="App" />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.capabilityBlockOffer}>
            <img className={styles.offerImg} src="../../images/site/capabilities/offer1.jpeg" alt="Offer" />
            <div className={styles.offerText}>
              <h2>{t.offer1Title}</h2>
              <p><b>{t.offerAdvantages}:</b> {t.offer1Advantages}</p>
              <p><b>{t.offerPeculiarities}:</b> {t.offer1Peculiarities}</p>
            </div>
          </div>

          <div className={styles.capabilityBlockOffer}>
            <img className={styles.offerImg} src="../../images/site/capabilities/offer2.jpeg" alt="Offer" />
            <div className={styles.offerText}>
              <h2>{t.offer2Title}</h2>
              <p><b>{t.offerAdvantages}:</b> {t.offer2Advantages}</p>
              <p><b>{t.offerPeculiarities}:</b> {t.offer2Peculiarities}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(YourCapabilities);
