
const translations = {
    ru: {
        title: 'Настройки',
        loading: 'Загрузка...',

        // menu titles
        menuTitleHome: 'Настройки дома',
        menuTitleUsers: 'Настройки пользователя',

        // menu
        menuUsers: 'Пользователи дома',
        menuZigbee: 'Zigbee Устройства',
        menuPermissions: 'Разрешения',
        menuNotifications: 'Уведомления',
        menuTelegram: 'Telegram-бот',
        menuLanguage: 'Язык приложения',
    },
    en: {
        title: 'Settings',
        loading: 'Loading...',

        // menu titles
        menuTitleHome: 'Home Settings',
        menuTitleUsers: 'User Settings',

        // menu
        menuUsers: 'Home Users',
        menuZigbee: 'Zigbee Devices',
        menuPermissions: 'Permissions',
        menuNotifications: 'Notifications',
        menuTelegram: 'Telegram Bot',
        menuLanguage: 'App Language',
    },
};

export default translations;
