import React from 'react';

function SettingsIcon() {
  return (
    <svg width="18pt" height="18pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '3px' }}>
      <path d="m583.45 232.93-38.852-14.176c-3.5-11.375-8.0508-22.398-13.648-33.074l17.5-37.625c2.9766-6.6484 1.5742-14.523-3.5-19.602l-43.398-43.398c-5.0742-5.0742-13.125-6.4766-19.602-3.5l-37.625 17.5c-10.676-5.6016-21.699-10.148-33.074-13.648l-14.176-38.852c-2.625-7-9.0977-11.551-16.449-11.551h-61.25c-7.3516 0-13.824 4.5508-16.449 11.551l-14.176 38.848c-11.375 3.5-22.398 8.0508-33.074 13.648l-37.625-17.5c-6.6484-2.9766-14.523-1.5742-19.602 3.5l-43.398 43.402c-5.0742 5.0742-6.4766 12.949-3.5 19.602l17.5 37.625c-5.6016 10.676-10.148 21.699-13.648 33.074l-38.852 14.176c-7 2.625-11.551 9.0977-11.551 16.449v61.25c0 7.3516 4.5508 13.824 11.551 16.449l38.852 14.176c3.5 11.375 8.0508 22.398 13.648 33.074l-17.5 37.625c-2.9766 6.6484-1.5742 14.523 3.5 19.602l43.398 43.398c5.0742 5.0742 12.949 6.4766 19.602 3.5l37.625-17.5c10.676 5.6016 21.699 10.148 33.074 13.648l14.176 38.852c2.625 7 9.1016 11.551 16.449 11.551h61.25c7.3516 0 13.824-4.5508 16.449-11.551l14.176-38.852c11.375-3.5 22.398-8.2266 33.074-13.648l37.625 17.5c6.6484 2.9766 14.523 1.5742 19.602-3.5l43.398-43.398c5.0742-5.0742 6.4766-12.949 3.5-19.602l-17.5-37.625c5.4258-10.676 10.148-21.699 13.648-33.074l38.852-14.176c7-2.625 11.551-9.1016 11.551-16.449v-61.25c0-7.3516-4.5508-13.824-11.551-16.449zm-233.45 140.88c-51.801 0-93.801-42-93.801-93.801s42-93.801 93.801-93.801 93.801 42 93.801 93.801-42 93.801-93.801 93.801z"/>
    </svg>
  );
}

export default SettingsIcon;
