import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
// import { Lightbulb, Power, MoreHorizontal } from 'lucide-react';
import { Lightbulb, Power } from 'lucide-react';
import SecurityButton from './SecurityButton';

import styles from './styles/ScenariosBlock.module.css';

const ScenariosBlock = ({ devices, handleDeviceToggle, handleSecurityToggle, securityActivationTime, homeData }) => {
    const [recentlyTurnedOffLights, setRecentlyTurnedOffLights] = useState([]);
    const [recentlyTurnedOffDevices, setRecentlyTurnedOffDevices] = useState([]);

    const isAnyLightOn = useMemo(() => devices.some(device => device.deviceType === 'light' && device.status), [devices]);
    const isAnyDeviceOn = useMemo(() => devices.some(device => device.status), [devices]);

    const toggleAllLights = useCallback(() => {
        if (isAnyLightOn) {
            const lightDeviceIds = devices.filter(device => device.deviceType === 'light' && device.status).map(device => device.id);
            setRecentlyTurnedOffLights(lightDeviceIds);
            handleDeviceToggle(lightDeviceIds, false);
        } else {
            handleDeviceToggle(recentlyTurnedOffLights, true);
            setRecentlyTurnedOffLights([]);
        }
    }, [devices, handleDeviceToggle, isAnyLightOn, recentlyTurnedOffLights]);

    const turnOffAllDevices = useCallback(() => {
        if (isAnyDeviceOn) {
            const activeDeviceIds = devices.filter(device => device.status).map(device => device.id);
            setRecentlyTurnedOffDevices(activeDeviceIds);
            handleDeviceToggle(activeDeviceIds, false);
        } else {
            handleDeviceToggle(recentlyTurnedOffDevices, true);
            setRecentlyTurnedOffDevices([]);
        }
    }, [devices, handleDeviceToggle, isAnyDeviceOn, recentlyTurnedOffDevices]);

    return (
        <div className={styles.root}>
            <div className={`${styles.row} ${styles.firstRow}`}>
                <div className={`${styles.button} ${isAnyLightOn ? styles.active : ''}`} onClick={toggleAllLights}>
                    <Lightbulb className={styles.icon} />
                </div>
                <div className={`${styles.button} ${isAnyDeviceOn ? styles.active : ''}`} onClick={turnOffAllDevices}>
                    <Power className={styles.icon} />
                </div>
            </div>
            <div className={styles.row}>
                <SecurityButton
                    activationTime={securityActivationTime}
                    handleSecurityToggle={handleSecurityToggle}
                    homeData={homeData}
                />
                {/* <div className={styles.button}>
                    <MoreHorizontal className={styles.icon} />
                </div> */}
            </div>
        </div>
    );
};

ScenariosBlock.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    handleDeviceToggle: PropTypes.func.isRequired,
    handleSecurityToggle: PropTypes.func.isRequired,
    securityActivationTime: PropTypes.instanceOf(Date),
    homeData: PropTypes.object.isRequired,
};

ScenariosBlock.defaultProps = {
    devices: [],
    securityActivationTime: null,
};

export default memo(ScenariosBlock);
