import React, { useRef, useEffect, useState, useMemo, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getActiveButtonType } from '../../utils/buttons';
import DeviceIcon from './DeviceIcon';
import { BUTTONS } from '../../../constants/deviceTypes';
import InfoPopup from './InfoPopup';
import DevicePopupContent from './DevicePopupContent';
import { ChevronDown, ChevronsDown, Minus, BatteryLow } from 'lucide-react';

import styles from './styles/ControlsBigButton.module.css';

const holdDuration = 1000; // 1000 мс = 1 секунды

const ControlsBigButton = ({
  type,
  name,
  deviceId,
  handleDeviceToggle,
  status,
  statusText,
  roomName,
  roomAndFloorData,
  floorId,
  isFavorite,
  handleAddToFavorites,
  isLightBackground,
  isShowFloorName,
  isZigbee,
  roomId,
  positionLeft,
  positionTop,
  updatedAt,
  remoteButtons,
}) => {
  const elementRef = useRef(null);
  const [elementHeight, setElementHeight] = useState(0);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const holdTimer = useRef();

  const buttonType = useMemo(() => getActiveButtonType(type), [type]);

  const isSensor = useMemo(() => buttonType === BUTTONS.BUTTONS_TYPES.SENSORS, [buttonType]);
  const isCurtain = useMemo(() => buttonType === BUTTONS.BUTTONS_TYPES.CURTAIN, [buttonType]);

  const floorName = roomAndFloorData?.floorsWithRooms?.find(floor => floor.id === floorId)?.name;
  const newRoomName = roomAndFloorData.showFloorNames && isShowFloorName && floorName ? `${roomName} (${floorName})` : roomName;

  const handleResize = useCallback(() => {
    if (elementRef.current) {
      setElementHeight(elementRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    const resizeTimeout = setTimeout(() => {
      handleResize();
    }, 500);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimeout);
    };
  }, [handleResize]);

  const clearHoldTimer = () => {
    clearTimeout(holdTimer.current);
  };

  const startHoldTimer = () => {
    holdTimer.current = setTimeout(() => {
      setIsShowInfo(true);
    }, holdDuration);
  };

  const handleMouseDown = () => {
    startHoldTimer();
  };

  const handleMouseUp = () => {
    clearHoldTimer();
  };

  const handleTouchStart = () => {
    startHoldTimer();
  };

  const handleTouchEnd = () => {
    clearHoldTimer();
  };

  useEffect(() => {
    return () => clearHoldTimer();
  }, []);

  useEffect(() => {
    const allowDeviceType = ['one_button_switch'];

    if (statusText) {
      try {
        if (statusText.action && allowDeviceType.includes(type)) {
          // Сначала проверяем состояние кнопки, потому что оно сбрасывается через несколько секунд
          const action = statusText.action;
          if (action === 'single') {
            setStatusData(<ChevronDown />);
          } else if (action === 'double') {
            setStatusData(<ChevronsDown />);
          } else if (action === 'hold') {
            setStatusData(<Minus />);
          } else {
            setStatusData(null);
          }
        } else if (statusText.battery && (statusText.battery <= 15 || statusText.battery_low)) {
          setStatusData(<BatteryLow size={22} color={'red'} />);
        } else if (statusText.temperature) {
          const roundedTemperature = parseFloat(statusText.temperature).toFixed(1);
          setStatusData(roundedTemperature + '°C');
        } else {
          setStatusData(null);
        }
      } catch (e) {
        console.error('Error parsing sensor data:', e);
        setStatusData(null);
      }
    } else {
      setStatusData(null);
    }
  }, [statusText, status, type]);

  useEffect(() => {
    setIsShowInfo(false);
  }, [deviceId]);

  const renderInfoPopup = useCallback(() => {
    return (
      <InfoPopup onClose={() => {
        setIsShowInfo(false);
      }}>
        <DevicePopupContent
          type={type}
          deviceId={deviceId}
          handleDeviceToggle={handleDeviceToggle}
          status={status}
          statusText={statusText}
          name={name}
          roomName={roomName}
          roomAndFloorData={roomAndFloorData}
          floorId={floorId}
          isFavorite={isFavorite}
          handleAddToFavorites={handleAddToFavorites}
          isZigbee={isZigbee}
          roomId={roomId}
          positionLeft={positionLeft}
          positionTop={positionTop}
          updatedAt={updatedAt}
          remoteButtons={remoteButtons}
        />
      </InfoPopup>
    );
  }, [
    deviceId,
    floorId,
    handleAddToFavorites,
    handleDeviceToggle,
    isFavorite,
    isZigbee,
    name,
    roomAndFloorData,
    roomId,
    roomName,
    status,
    statusText,
    type,
    positionLeft,
    positionTop,
    updatedAt,
    remoteButtons,
  ]);

  return (
    <>
      <button
        ref={elementRef}
        style={{ height: `${elementHeight}px` }}
        className={`${styles.button} ${isLightBackground ? styles.buttonLightBackground : ''} ${isSensor
          ? styles.sensor : ''} ${status && !isCurtain ? (isSensor ? styles.activeSensor : styles.activeDevice) : ''}`}
        onClick={() => {
          if (type === BUTTONS.BUTTONS_TYPES.IR_REMOTE) {
            setIsShowInfo(true);
          } else if (!isSensor) {
            handleDeviceToggle(deviceId, !status);
          }
        }}

        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={clearHoldTimer}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={clearHoldTimer}
      >
        {isCurtain && (
          <div className={styles.buttonCurtain} style={{ width: !status ? '100%' : '0' }}></div>
        )}
        <div className={styles.iconAndStatusBox}>
          <DeviceIcon type={type} />
          {statusData && <div className={styles.status}>
            {statusData}
          </div>}
        </div>
        <div className={styles.textBox}>
          <p className={styles.title}>{name}</p>
          <p className={styles.text}>{newRoomName}</p>
        </div>
      </button>
      {isShowInfo && renderInfoPopup()}
    </>
  );
};

ControlsBigButton.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  deviceId: PropTypes.number,
  handleDeviceToggle: PropTypes.func.isRequired,
  status: PropTypes.bool,
  statusText: PropTypes.object,
  roomName: PropTypes.string.isRequired,
  roomAndFloorData: PropTypes.shape({}),
  floorId: PropTypes.number,
  isFavorite: PropTypes.bool.isRequired,
  handleAddToFavorites: PropTypes.func.isRequired,
  isLightBackground: PropTypes.bool,
  isShowFloorName: PropTypes.bool,
  isZigbee: PropTypes.bool,
  roomId: PropTypes.number,
  positionLeft: PropTypes.number,
  positionTop: PropTypes.number,
  updatedAt: PropTypes.string,
  remoteButtons: PropTypes.array,
};

ControlsBigButton.defaultProps = {
  deviceId: null,
  status: null,
  statusText: null,
  position: null,
  roomAndFloorData: {},
  floorId: null,
  isLightBackground: false,
  isShowFloorName: false,
  isZigbee: false,
  roomId: null,
  positionLeft: null,
  positionTop: null,
  updatedAt: null,
  remoteButtons: null,
};

export default memo(ControlsBigButton);
