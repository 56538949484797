import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/FileUpload.module.css';

const FileUpload = ({ onFileLoaded, uploadFile, params, id }) => {
  const [fileName, setFileName] = useState('Выберите файл');

  const handleFileChange = useCallback(async event => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      try {
        const response = await uploadFile({ ...params, file });
        const parsedData = response?.data?.data;
        onFileLoaded(parsedData);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  }, [onFileLoaded, uploadFile, params]);

  return (
    <div className={styles.fileUpload}>
      <input type="file" id={`file-${id || 0}`} className={styles.hiddenInput} onChange={handleFileChange} />
      <label htmlFor={`file-${id || 0}`} className={styles.customButton}>Выбрать файл</label>
      <label id={`filename-${id || 0}`} className={styles.fileName}>{fileName}</label>
    </div>
  );
};

FileUpload.propTypes = {
  onFileLoaded: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  id: PropTypes.number,
};

FileUpload.defaultProps = {
  id: null,
};

export default memo(FileUpload);
