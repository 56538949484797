import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import ControlsButton from './ControlsButton';

import styles from './styles/ControlsDevices.module.css';

const ControlsDevices = ({ devices, handleDeviceToggle, floorImgWidth }) => {
  const originalWidth = 600;

  const scaledDevices = useMemo(() => {
    const scale = floorImgWidth / originalWidth;
    return devices.map(device => ({
      ...device,
      positionLeft: device.positionLeft ? device.positionLeft * scale : null,
      positionTop: device.positionTop ? device.positionTop * scale : null,
    }));
  }, [floorImgWidth, devices]);

  return (
    <div className={styles.root}>
      {scaledDevices?.map(device => (
        <ControlsButton
          key={device.id}
          type={device.deviceType}
          left={device.positionLeft}
          top={device.positionTop}
          deviceId={device.id}
          handleDeviceToggle={handleDeviceToggle}
          status={device.status}
        />
      ))}
    </div>
  );
};

ControlsDevices.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  handleDeviceToggle: PropTypes.func.isRequired,
  floorImgWidth: PropTypes.number,
};

ControlsDevices.defaultProps = {
  devices: [],
  floorImgWidth: 600,
};

export default memo(ControlsDevices);
