import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Shield } from 'lucide-react';
import { ROLES_ID } from '../../../constants/homeRoles';

import styles from './styles/ScenariosBlock.module.css';

const SecurityButton = ({ activationTime, handleSecurityToggle, homeData }) => {
    const [countdown, setCountdown] = useState(null);
    const [isGuest, setIsGuest] = useState(false);

    useEffect(() => {
        if (!homeData?.permissions?.guests?.security && homeData.roleId === ROLES_ID.GUEST) {
            setIsGuest(true);
        }
    }, [homeData]);

    useEffect(() => {
        if (activationTime) {
            setCountdown(0);

            const intervalId = setInterval(() => {
                const now = new Date();
                const timeLeft = Math.max(0, Math.round((activationTime - now) / 1000));
                setCountdown(timeLeft);
                if (timeLeft === 0) {
                    clearInterval(intervalId);
                }
            }, 1000);

            return () => clearInterval(intervalId);
        } else {
            setCountdown(null);
        }
    }, [activationTime]);

    const startCountdown = useCallback(() => {
        setCountdown(0);
        handleSecurityToggle(true);
    }, [handleSecurityToggle]);

    const turnOffSecurity = useCallback(() => {
        setCountdown(null);
        handleSecurityToggle(false);
    }, [handleSecurityToggle]);

    const renderContent = useCallback(() => {
        if (countdown === null || countdown === 0) {
            return <Shield className={styles.icon} />;
        }

        return <span className={styles.countdown}>{countdown}</span>;
    }, [countdown]);

    const handleClick = useCallback(() => {
        if (!isGuest) {
            if (countdown === null) {
                startCountdown();
            } else {
                turnOffSecurity();
            }
        }
    }, [countdown, isGuest, startCountdown, turnOffSecurity]);

    return (
        <div
            className={`${styles.button} ${countdown !== null ? styles.activeShield : ''}`}
            style={{ cursor: isGuest ? 'default' : 'pointer' }}
            onClick={handleClick}
        >
            {renderContent()}
        </div>
    );
};

SecurityButton.propTypes = {
    activationTime: PropTypes.instanceOf(Date),
    handleSecurityToggle: PropTypes.func.isRequired,
    homeData: PropTypes.object.isRequired,
};

SecurityButton.defaultProps = {
    activationTime: null,
};

export default memo(SecurityButton);
