import React, { useContext, useState, useMemo, useCallback, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { getActiveButtonType } from '../../utils/buttons';
import { BUTTONS } from '../../../constants/deviceTypes';
import { HelpCircle } from 'lucide-react';
import RoomButton from './RoomButton';
import RoomsInstruction from '../instructions/RoomsInstruction';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/RoomsBlock.translations.js';

import styles from './styles/RoomsBlock.module.css';

const MOBILE_WIDTH = 480;

const RoomsBlock = ({ devices, handleDeviceToggle, roomAndFloorData }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [isShowAllRooms, setIsShowAllRooms] = useState(false);
    const [isShowRoomsInstruction, setIsShowRoomsInstruction] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);

    const floorsWithRooms = useMemo(() => roomAndFloorData?.floorsWithRooms, [roomAndFloorData]);
    const roomsByFloor = useMemo(() => roomAndFloorData?.roomsByFloor, [roomAndFloorData]);
    const showFloorNames = useMemo(() => roomAndFloorData?.showFloorNames, [roomAndFloorData]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH);
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

    const getDeviceStats = useCallback(roomId => {
        const roomDevices = devices.filter(device => {
            const buttonType = getActiveButtonType(device.deviceType);
            return device.roomId === roomId && buttonType !== BUTTONS.BUTTONS_TYPES.SENSORS;
        });
        const activeDevices = roomDevices.filter(device => device.status);
        const activeLights = activeDevices.filter(device => device.deviceType === 'light');

        return {
            totalDevices: roomDevices.length,
            activeDevices: activeDevices.map(device => device.id),
            activeLights: activeLights.map(device => device.id),
        };
    }, [devices]);

    const filteredRoomsByFloor = useMemo(() => {
        const result = {};
        if (floorsWithRooms && roomsByFloor) {
            floorsWithRooms.forEach(floor => {
                result[floor.id] = roomsByFloor[floor.id].filter(room => {
                    const roomDevices = devices.filter(device => device.roomId === room.id);
                    return roomDevices.length > 0;
                });
            });
        }
        return result;
    }, [floorsWithRooms, roomsByFloor, devices]);

    const importantRooms = useMemo(() => {
        const roomsToShow = [];
        let roomsToShowCount = isMobile ? 3 : 5;
        for (let i = 0; i < floorsWithRooms.length; i++) {
            const floor = floorsWithRooms[i];
            const rooms = filteredRoomsByFloor[floor.id];
            for (let j = 0; j < rooms.length; j++) {
                const room = rooms[j];
                room.floorName = floor.name;
                roomsToShow.push(room);
                roomsToShowCount--;
                if (roomsToShowCount === 0) {
                    return roomsToShow;
                }
            }
        }
        return roomsToShow;
    }, [floorsWithRooms, filteredRoomsByFloor, isMobile]);

    // Функция для рендеринга комнат
    const renderRooms = useCallback((roomsToRender, floorName) => {
        return roomsToRender.map((room, index, array) => {
            const deviceStats = getDeviceStats(room.id);
            return (
                <RoomButton
                    key={room.id}
                    name={room.name}
                    roomId={room.id}
                    className={`${styles.buttonWithSeparator} ${index === array.length - 1 ? styles.lastButton : ''}`}
                    floorName={showFloorNames ? floorName || room.floorName : null}
                    deviceStats={deviceStats}
                    handleDeviceToggle={handleDeviceToggle}
                    devices={devices}
                />
            );
        });
    }, [getDeviceStats, showFloorNames, handleDeviceToggle, devices]);

    // Функция для рендеринга этажей и комнат
    const renderFloorsAndRooms = useCallback(() => {
        if (isShowAllRooms) {
            return floorsWithRooms.map(floor => (
                <div key={floor.id} className={styles.floorBox}>
                    {showFloorNames && <p className={styles.name}>{floor.name}</p>}
                    <div>
                        {renderRooms(filteredRoomsByFloor[floor.id], floor.name)}
                    </div>
                </div>
            ));
        } else {
            return (
                <div>
                    {renderRooms(importantRooms, null)}
                </div>
            );
        }
    }, [floorsWithRooms, importantRooms, isShowAllRooms, renderRooms, filteredRoomsByFloor, showFloorNames]);

    const { current: handleCloseRoomsInstruction } = useRef(() => {
        setIsShowRoomsInstruction(false);
    });

    const roomsToShowCount = isMobile ? 3 : 5;
    const totalRoomsCount = floorsWithRooms.reduce((count, floor) => count + filteredRoomsByFloor[floor.id].length, 0);

    return (
        <div className={styles.root}>
            <div className={styles.headerBox}>
                <p className={styles.title}>{t.rooms}</p>
                <div className={styles.buttons}>
                    {/* TODO: сделать инструкцию на английском */}
                    {language === 'ru' && (<div className={styles.helpButtonBox}>
                        <HelpCircle className={styles.helpButton} onClick={() => {
                            setIsShowRoomsInstruction(true);
                        }} />
                    </div>)}
                    {totalRoomsCount > roomsToShowCount && (
                        <div onClick={() => setIsShowAllRooms(!isShowAllRooms)} className={styles.button}>
                            {isShowAllRooms ? t.hide : `${t.all} ${totalRoomsCount}`}
                        </div>
                    )}
                </div>
            </div>
            {renderFloorsAndRooms()}
            {isShowRoomsInstruction && <RoomsInstruction onClose={handleCloseRoomsInstruction} />}
        </div>
    );
};

RoomsBlock.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleDeviceToggle: PropTypes.func.isRequired,
    roomAndFloorData: PropTypes.shape({}),
};

RoomsBlock.defaultProps = {
    roomAndFloorData: {},
};

export default memo(RoomsBlock);
