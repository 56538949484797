export const identifyProvider = queryJson => {
    if (queryJson.redirect_uri) {
        const redirectUri = queryJson.redirect_uri;
        if (redirectUri.includes('yandex')) {
            return 'yandex';
        } else if (redirectUri.includes('sberdevices')) {
            return 'sber';
        } else if (redirectUri.includes('google')) {
            return 'google';
        } else if (redirectUri.includes('mail.ru')) {
            return 'vk';
        } else if (redirectUri.includes('amazon')) {
            return 'amazon';
        }
    }

    return 'yandex';
};

export const getServiceName = provider => {
    switch (provider) {
        case 'yandex':
            return 'с Яндексом';
        case 'sber':
            return 'со Сбером';
        case 'google':
            return 'с Google';
        case 'vk':
            return 'с ВК';
        case 'amazon':
            return 'с Amazon';
        default:
            return 'с сервисом';
    }
}
