import { openDB } from 'idb';

const DB_NAME = 'languageDB';
const DB_VERSION = 1;
const STORE_NAME = 'languageStore';

const initDB = async () => {
    const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            db.createObjectStore(STORE_NAME);
        },
    });
    return db;
};

export const setLanguage = async language => {
    const db = await initDB();
    await db.put(STORE_NAME, language, 'currentLanguage');
};

export const getLanguage = async () => {
    const db = await initDB();
    return db.get(STORE_NAME, 'currentLanguage');
};
