import React, { useEffect, useState, useCallback, memo } from "react";
import PropTypes from 'prop-types';
import { submitApplication } from '../utils/api.js';

import YandexMetrika from '../utils/YandexMetrika.js';
import VideoPlayer from './components/VideoPlayer.js';
import СapabilityBox from './components/СapabilityBox.js';

import styles from './styles/CityLanding.module.css';

const capabilitiesBlocks = [
  {
    title: "Проектирование, инженерия и установка",
    text: "Мы определяем какие системы Вам нужны. Проводные мы устанавливаем на большие дома, беспроводные на небольшие дома и квартиры. Мы создаем проект системы умного дома, отопления, вентиляции и прочих необходимых Вам систем. После создания проекта мы предоставляем услуги по установке или тех надзору, если у Вас уже есть своя команда инженеров и электриков.",
    image: "../../images/site/capabilities/offer2.jpeg",
  },
  {
    title: "Наш хаб и беспроводные устройства",
    text: "После создания проводной системы умного дома, наш хаб позволяет вам легко добавлять беспроводные устройства. Наше решение поддерживает широкий спектр беспроводных устройств, обеспечивая гибкость и масштабируемость вашей системы умного дома. К хабу можно подключить любые Zigbee-устройства (датчики, розетки, выключатели и т.д.).",
    image: "../../images/city/city2.jpeg",
  },
  {
    title: "Наше приложение",
    text: "Наше красивое и удобное приложение позволяет вам управлять всеми устройствами умного дома с одного интерфейса. Легкость использования и интуитивно понятный дизайн делают управление умным домом простым и приятным.",
    image: "../../images/site/shots/shots6.png",
  },
  {
    title: "Интеграция с сервисами",
    text: "Наша система умного дома интегрируются со всеми популярными сервисами: Яндекс Алиса, Сбер Салют, Маруся от ВК, Apple Home (Siri) и Google Home. Это позволяет вам управлять устройствами умного дома голосом с умных колонок, смартфонов и умных часов.",
    image: "../../images/city/apple-watch.avif",
  },
];

const appFeatures = [
  {
    title: "Управление устройства",
    text: "Удобное управление всеми устройствами вашего дома с разделением по этажам, комнатам и типам устройств. Отображение состояний утсроств и данных датчиков в реальном времени.",
    image: "../../images/site/shots/shots6.png",
  },
  {
    title: "Главный экран",
    text: "Удобный главный экран с нужной информацией о вашем доме, комнатах и устройствах. Возможность выключить всё освещение или все устройства в доме одним нажатием. Сюда также выведены быстрые сценарии дома.",
    image: "../../images/site/shots/shots5.png",
  },
  {
    title: "Комнаты",
    text: "С главного экрана вы можете выключить всё освещение или все устройства в комнате одним нажатием. Или перейти в комнату для управления устройствами в ней.",
    image: "../../images/site/shots/shots7.png",
  },
  {
    title: "Избранные устройства",
    text: "Вы можете добавить самые важные устройства в избранное и получить к ним быстрый доступ на главном экране.",
    image: "../../images/city/shots1.png",
  },
  {
    title: "Пользователи",
    text: "Вы можете легко добавлять пользователей в приложение по номеру телефона и удалять их. При добавлении вы можете выбрать их роль - владелец, пользователь или гость.\n\nСистема ролей позволяет настраивать ограничения в приложении, например, запретить добавлять новых пользователей и новые устройства, включать и выключать режим охраны и другие функции.",
    image: "../../images/site/shots/shots9.png",
  },
  {
    title: "Добавление устройств",
    text: "Вы за пару минут можете добавлять новые Zigbee устройства в приложении. Просто включите режим добавления устройств и подключите новые устройства.",
    image: "../../images/site/shots/shots10.png",
  },
  {
    title: "Десктопное управление",
    text: "В версии приложения для компьютера и планшета вы можете видеть расположение устройств на плане этажа и управлять ими.",
    image: "../../images/city/shots2.png",
  },
  {
    title: "Охрана и уведомления",
    text: "В приложении Вы можете включать и выключать режим охраны, настраивать уведомления о событиях в доме и видеть историю событий. Вы сможете настроить уведомления о проникновении, протечке воды, пожаре и других событиях.\n\nВы сможете настроить получение уведомлений по смс, push-уведомлениям в приложении и в Telegram.",
    image: "../../images/city/shots3.png",
  },
];

const CityLanding = ({ cityTag, cityName }) => {
  const [formResponse, setFormResponse] = useState(null);

  const scrollToForm = useCallback(() => {
    const formElement = document.getElementById("request");
    if(formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      telegram: event.target.telegram.value,
      page: `${cityTag}_landing`,
    };

    try {
      const response = await submitApplication(formData);
      if (response.status === 200) {
        setFormResponse("Заявка успешно отправлена!");
      } else {
        setFormResponse(null);
      }
    } catch (error) {
      console.error('Ошибка при отправке формы:', error);
      setFormResponse(null);
    }
  }, [cityTag]);

  useEffect(() => {
    document.title = `GETHOME - Установка системы Умный дом в ${cityName}`;
  }, [cityName]);

  return (
    <div className={styles.landingPage}>
      <YandexMetrika />

      <header className={styles.headerBox}>
        <img
          src="../../images/logo/icon_black.png"
          alt="GETHOME Logo"
          className={styles.headerIcon}
          onClick={() => window.location.href = "/"}
        />
      </header>

      <div className={styles.landingContentContainer}>
        <div className={styles.frameFirstContainer}>
          <h2 className={styles.firstFrameTitle}>Установка системы Умный дом в {cityName}</h2>
          <h4 className={styles.firstFrameSubtitle}>Создайте современный и удобный умный дом с помощью GETHOME.<br />Мы предлагаем установку проводных системы, интеграцию с популярными сервисами, красивое приложение и простое добавление беспроводных устройств.</h4>
          <a onClick={scrollToForm} href="#requestForm" className={styles.firstFrameButton}>Запросить расчет стоимости</a>
        </div>

        <div className={styles.frame}>
          <VideoPlayer src="../../videos/video2.mp4" type="video/mp4" />
        </div>

        <div className={styles.blockContainer}>
          <p className={styles.text}>Мы компания GETHOME занимаемся разработкой и установкой систем умного дома в {cityName}. Мы проектируем и устанавливаем большие проводные системы умного дома на собственном ПО и проверенном оборудовании. Все устройства управляются через наше красивое и удобное приложение. Наше приложение и хаб позволяют уже после создания проводной системы умного дома добавлять Вам самостоятельно беспроводные устройства.</p>
          <p className={styles.text}>У нас сделаны интеграции со всеми популярными сервисами умного дома: Яндекс Алиса, Сбер Салют, Маруся от ВК, Apple Home и Google Home. Все устройства умного дома можно будет добавить в эти сервисы и управлять ими голосом.</p>
        </div>

        <div className={styles.frameSecondContainer}>
          <h2 className={styles.blockTitle}>Что мы предлагаем</h2>

          {capabilitiesBlocks.map((block, index) => (
            <СapabilityBox key={index} {...block} imagePosition={index % 2 === 0 ? "right" : "left"} />
          ))}
        </div>

        <div className={styles.blockContainer}>
          <p className={styles.text}>Наш процесс работы начинается с консультации, где мы определяем ваши потребности и решаем, какое решение подойдет вам лучше: проводное или беспроводное. Если вам нужен проводной умный дом, мы разрабатываем проект и устанавливаем нашу систему. Если достаточно беспроводного решения, мы подбираем и интегрируем необходимые устройства.</p>
          <p className={styles.text}>После установки нашей системы Вы сможете самостоятельно добавлять новые беспроводные устройства и интегрировать их с помощью нашего приложения и хаба. При покупке новых колонок или других устройств вы сможете легко включить их в свою систему умного дома.</p>
          <p className={styles.text}>Если у вас возникнут вопросы, наша служба поддержки всегда готова помочь вам с любыми техническими проблемами.</p>
          <div className={styles.buttonBox}>
            <a onClick={scrollToForm} href="#requestForm" className={styles.firstFrameButton}>Получить консультацию</a>
          </div>
        </div>

        <div className={styles.frameSecondContainer}>
          <h2 className={styles.blockTitle}>Функционал нашего приложения</h2>

          {appFeatures.map((feature, index) => (
            <СapabilityBox key={index} {...feature} imagePosition={index % 2 === 0 ? "right" : "left"} />
          ))}
        </div>

        <div className={styles.blockContainer}>
          <p className={styles.text}>Мы предоставляем полный спектр услуг по разработке и установке умных домов, включая создание собственного программного обеспечения, его установку и интеграцию с другими популярными сервисами. Наше приложение отличается стильным и интуитивно понятным интерфейсом, в отличие от большинства приложений интеграторов, которые выглядят так, как будто управляют заводом, а не домом.</p>
          <p className={styles.text}>Наши решения интегрируются с такими популярными сервисами, как Яндекс Алиса, Маруся от ВК, Сбер Салют, Google Home и Apple Home (Siri), что позволяет вам управлять вашим умным домом через голосовые команды или приложения на вашем устройстве. После установки проводного умного дома, вы получите возможность самостоятельно добавлять беспроводные устройства, расширяя функциональность вашей системы.</p>
          <p className={styles.text}>Мы гордимся нашим опытом на рынке и патентом, полученным в 2011 году. Это подтверждает нашу экспертизу и надежность. С GETHOME вы получите не только все те же услуги, что и у конкурентов, но и значительно больше – качество, удобство и инновационные решения, которые выделяют нас на рынке. Обратитесь к нам и убедитесь, что умный дом может быть действительно умным и удобным.</p>
          <div className={styles.buttonBox}>
            <a onClick={scrollToForm} href="#requestForm" className={styles.firstFrameButton}>Заказать установку умного дома</a>
          </div>
        </div>

        <div id="requestForm" className={styles.formContainer}>
          <h2 className={styles.blockTitle}>Форма обратной связи</h2>
          <p className={styles.text} style={{ textAlign: "center" }}>Оставьте заявку на консультацию по установке умного дома. Мы свяжемся с вами в ближайшее время и ответим на все ваши вопросы.</p>

          {formResponse && <p className={styles.formResponse}>{formResponse}</p>}
          {!formResponse && (<form className={styles.formBox} onSubmit={handleSubmit}>
            <input type="text" placeholder="Имя" className={styles.formInput} name="name" required />
            <input type="text" placeholder="Номер телефона" className={styles.formInput} name="phone" required />
            <input type="text" placeholder="Email (по желанию)" className={styles.formInput} name="email" />
            <input type="text" placeholder="Telegram для связи (по желанию)" className={styles.formInput} name="telegram" />
            <button type="submit" className={styles.firstFrameButton} style={{ width: "150px", margin: "auto" }}>Отправить</button>
          </form>)}
        </div>
      </div>

      <footer className={styles.footerBox}>
        <p className={styles.footerText}>GETHOME © 2024</p>
      </footer>
    </div>
  );
};

CityLanding.propTypes = {
  cityTag: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
};

export default memo(CityLanding);
