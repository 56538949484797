import React, { useContext, memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/BackButton.translations.js';

import styles from './styles/BackButton.module.css';

const BackButton = ({ onClick }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  if (onClick) {
    return (
      <Link className={styles.backButton} onClick={onClick}>← {t.back}</Link>
    );
  }

  return null;
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default memo(BackButton);
