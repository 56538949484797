import React from 'react';

function SmokeIcon() {
  return (
    <svg width="25pt" height="25pt" version="1.1" viewBox="0 0 60 75" style={{ paddingTop: '2px' }}>
      <path d="M42.5610352,21.4364014c0.0165405-0.3061523-0.140686-0.5957642-0.4136963-0.7529907  c-0.2648315-0.1489258-0.5958252-0.140686-0.8605957,0.0247803c-2.2423706,1.4398193-3.1443481,3.8890991-3.5084229,5.6433105  c-0.9432983-1.5721436-1.133606-3.4505005-1.4562988-7.5960693c-0.4468384-5.7425537-5.7508545-9.1682129-5.9742432-9.3171997  c-0.2813721-0.1737061-0.637146-0.1654663-0.9102173,0.0166016c-0.2648315,0.1820068-0.4054565,0.5130005-0.3475342,0.8357544  c0.0083008,0.0496216,0.8605347,5.0391846-2.4740601,8.9116211c-2.3251953,2.6975708-4.6751709,6.2804565-5.4777832,9.3668213  c-0.5130005-1.4562988-0.8936768-3.2849731-0.5792236-5.1467285c0.0662231-0.3972168-0.1655273-0.7860718-0.5379028-0.918457  c-0.3723145-0.1324463-0.7943115,0.0247803-1.0011597,0.3723145c-0.1820679,0.3226929-4.5013428,7.9683838-3.8311157,14.5053101  c0.7695313,7.5050659,4.4682007,11.7250977,11.6257324,13.2559204c0.3392334,0.0744019,0.6785278-0.0662231,0.8688354-0.355896  c0.1820679-0.28125,0.1820679-0.6536255-0.0165405-0.9349365c-3.6408081-5.1385498-0.6702881-8.4400635,1.1005249-10.4094238  c0.1903076-0.2069092,0.3640747-0.3972168,0.5046997-0.5709839c0.7282104-0.8687744,1.1171265-1.944519,1.3322754-2.8795166  c0.7612305,1.307373,1.5142212,3.3759766,0.9267578,5.9907837c-0.0579834,0.2730103,0.0247803,0.5543823,0.2151489,0.7529907  c0.1902466,0.1985474,0.4716187,0.2896118,0.7446289,0.2399292c0.0910034-0.0165405,1.6384277-0.3143921,2.7388916-1.4811401  c0.4882202,1.8452148,0.7033691,5.1219482-2.3499146,8.2911377c-0.24823,0.2647705-0.2979736,0.6536865-0.1324463,0.9680786  c0.1489258,0.2731323,0.4302979,0.4385986,0.7281494,0.4385986c0.0496826,0,0.107605-0.0083008,0.1572266-0.0165405  c4.741333-0.918457,8.0429077-3.2601929,9.8136597-6.9506836c2.8050537-5.8252563,0.777771-13.2393188-0.0496826-14.8197021  C42.5113525,27.2202759,42.494873,22.9423828,42.5610352,21.4364014z"/>
    </svg>
  );
}

export default SmokeIcon;
