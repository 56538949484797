import React, { useContext, useEffect, useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Star, Pencil } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import ControlsButton from './ControlsButton';
import DevicePopupRemote from './DevicePopupRemote';
import { deleteZigbeeDevice, updateDeviceData } from '../../utils/api';
import { formatInTimeZone } from 'date-fns-tz';
import { BUTTONS } from '../../../constants/deviceTypes';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/DevicePopupContent.translations.js';

import styles from './styles/DevicePopupContent.module.css';

const DevicePopupContent = ({
    type,
    deviceId,
    handleDeviceToggle,
    status,
    statusText,
    name,
    roomName,
    roomAndFloorData,
    floorId,
    isFavorite,
    handleAddToFavorites,
    isZigbee,
    roomId,
    positionLeft,
    positionTop,
    updatedAt,
    remoteButtons,
}) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const { personalId } = useParams();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({
        name,
        roomId,
        positionLeft: positionLeft ?? 0,
        positionTop: positionTop ?? 0,
    });
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const floorName = roomAndFloorData?.floorsWithRooms?.find(floor => floor.id === floorId)?.name;
    const newRoomName = roomAndFloorData.showFloorNames && floorName ? `${roomName} (${floorName})` : roomName;

    useEffect(() => {
        const handleOrientationChange = e => setIsLandscape(e.matches);

        const mediaQuery = window.matchMedia("(orientation: landscape)");
        mediaQuery.addListener(handleOrientationChange);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            mediaQuery.removeListener(handleOrientationChange);
        };
    }, []);

    const handleDeleteZigbeeDevice = useCallback(async () => {
        try {
            setIsDeleting(true);
            await deleteZigbeeDevice(personalId, deviceId);
        } catch (error) {
            console.error('Ошибка при удалении Zigbee-устройства:', error);
        } finally {
            setIsDeleting(false);
        }
    }, [deviceId, personalId]);

    const handleStartEditing = useCallback(() => {
        setEditedData({ name, roomId, positionLeft, positionTop });
        setIsEditing(prevState => !prevState);
        setIsEditLoading(false);
    }, [name, positionLeft, positionTop, roomId]);

    const handleEditChange = useCallback((field, value) => {
        let newValue = value;

        if (field === 'positionLeft' || field === 'positionTop') {
            newValue = value === '' ? 0 : parseInt(value);
        } else if (field === 'roomId' && value === -1) {
            newValue = null;
        }

        setEditedData(prevData => ({
            ...prevData,
            [field]: newValue,
        }));
    }, []);

    const handleCancelEditing = useCallback(() => {
        setIsEditing(false);
        setEditedData({ name, roomId, positionLeft, positionTop });
    }, [name, positionLeft, positionTop, roomId]);

    const handleSaveEditing = useCallback(async () => {
        if (editedData.name === name
                && editedData.roomId === roomId
                && editedData.positionLeft === positionLeft
                && editedData.positionTop === positionTop
        ) {
            setIsEditing(false);
            return;
        }

        if (editedData.name === '') {
            alert(t.alertDeviceName);
            return;
        }

        setIsEditLoading(true);
        await updateDeviceData(deviceId, editedData.name, editedData.roomId, editedData.positionLeft, editedData.positionTop);
        setIsEditLoading(false);
        setIsEditing(false);
    }, [deviceId, editedData, name, positionLeft, positionTop, roomId, t]);

    const renderRoomOptions = () => {
        const floors = roomAndFloorData.floorsWithRooms;
        const roomsByFloor = roomAndFloorData.roomsByFloor;

        let options = [];

        floors.forEach(floor => {
            const rooms = roomsByFloor[floor.id];
            rooms.forEach(room => {
                const optionText = floors.length > 1 ? `${room.name}, ${floor.name}` : room.name;
                options.push(
                    <option key={room.id} value={room.id}>
                        {optionText}
                    </option>
                );
            });
        });

        return options;
    };

    const formatStatusText = useCallback(() => {
        let count = 0;
        let statusItems;

        if (statusText) {
            statusItems = Object.entries(statusText).map(([key, value]) => {
                let formattedKey, formattedValue;
                let isRed = false;

                switch (key) {
                    case 'state':
                        formattedKey = t.stateKey;
                        if (type === 'curtain') {
                            formattedValue = value === false ? t.curtainClosed : t.curtainOpened;
                        } else if (type === 'smoke') {
                            formattedValue = value === false ? t.smokeNo : t.smokeYes;
                        } else {
                            formattedValue = value ? t.on : t.off;
                        }
                        break;
                    case 'battery':
                        formattedKey = t.batteryKey;
                        formattedValue = `${value}%`;
                        if (value <= 10) {
                            isRed = true;
                        }
                        break;
                    case 'battery_low':
                        formattedKey = t.batteryLowKey;
                        formattedValue = value ? t.yes : t.no;
                        if (value) {
                            isRed = true;
                        }
                        break;
                    case 'temperature':
                        formattedKey = t.temperatureKey;
                        formattedValue = `${value}°C`;
                        break;
                    case 'humidity':
                        formattedKey = t.humidityKey;
                        formattedValue = `${value}%`;
                        break;
                    case 'pressure':
                        formattedKey = t.pressureKey;
                        // formattedValue = `${value} мм рт. ст.`;
                        formattedValue = `${value} ${t.pressureValue}`;
                        break;
                    case 'contact':
                        formattedKey = t.contactKey;
                        formattedValue = value ? t.contactOpened : t.contactClosed;
                        break;
                    case 'action':
                        formattedKey = t.actionKey;
                        if (value === 'single') {
                            formattedValue = t.actionSingle;
                        } else if (value === 'double') {
                            formattedValue = t.actionDouble;
                        } else if (value === 'hold') {
                            formattedValue = t.actionHold;
                        } else {
                            formattedValue = null;
                        }
                        break;
                    case 'occupancy':
                        formattedKey = t.occupancyKey;
                        formattedValue = value ? t.occupancyYes : t.occupancyNo;
                        break;
                    case 'water_leak':
                        formattedKey = t.waterLeakKey;
                        formattedValue = value ? t.waterLeakYes : t.waterLeakNo;
                        break;
                    case 'device_temperature':
                        formattedKey = t.deviceTemperatureKey;
                        formattedValue = `${value}°C`;
                        break;
                    case 'illuminance':
                        formattedKey = t.illuminationKey;
                        formattedValue = `${value} лк`;
                        break;
                    case 'linkquality':
                        formattedKey = t.linkqualityKey;
                        formattedValue = `${value}/255 LQI`;
                        break;
                    case 'power':
                        formattedKey = t.powerKey;
                        formattedValue = `${value} ${t.powerValue}`;
                        break;
                    case 'current':
                        formattedKey = t.currentKey;
                        formattedValue = `${value} ${t.currentValue}`;
                        break;
                    case 'consumer_connected':
                        formattedKey = t.consumerConnectedKey;
                        formattedValue = value ? t.yes : t.no;
                        break;
                    case 'charging':
                        formattedKey = t.chargingKey;
                        formattedValue = value ? t.chargingYes : t.chargingNo;
                        break;
                    case 'power_source':
                        formattedKey = t.powerSourceKey;
                        if (value === 'battery') {
                            formattedValue = t.powerSourceBattery;
                        } else if (value === 'dc_source') {
                            formattedValue = t.powerSourceDC;
                        } else {
                            formattedValue = null;
                        }
                        break;
                    case 'smoke_density':
                        formattedKey = t.smokeDensityKey;
                        formattedValue = `${value} ppm`;
                        break;
                    case 'smoke_density_dbm':
                        formattedKey = t.smokeDensityDbmKey;
                        formattedValue = `${value} dB/m`;
                        break;
                    default:
                        formattedKey = null;
                        formattedValue = null;
                }

                if (!formattedKey || !formattedValue) {
                    return null;
                }

                count += 1;
                return (
                    <div key={key} className={styles.statusItem}>
                        <span className={styles.statusKey}>{formattedKey}</span>
                        <span className={isRed ? styles.statusValueRed : styles.statusValue}>{formattedValue}</span>
                    </div>
                );
            });
        }

        if (count === 0 || !statusItems) {
            return null;
        } else {
            return statusItems;
        }
    }, [statusText, type, t]);

    const formatUpdatedAt = useCallback(() => {
        if (!updatedAt || !timeZone) return null;

        const zonedDate = new Date(updatedAt);
        return formatInTimeZone(zonedDate, timeZone, 'HH:mm dd.MM.yyyy');
    }, [updatedAt, timeZone]);

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.headerLeft}>
                    <div className={styles.buttonBox}>
                        <ControlsButton
                            type={type}
                            deviceId={deviceId}
                            handleDeviceToggle={handleDeviceToggle}
                            status={status}
                            isPositionAbsolute={false}
                        />
                    </div>
                    <div>
                        <div className={styles.headerTextBox}>
                            <p className={styles.popupTitle}>{name}</p>
                            {isZigbee && <button onClick={handleStartEditing} className={styles.editButton}>
                                <Pencil size={18} />
                            </button>}
                        </div>
                        <p className={styles.popupSubitle}>{newRoomName}</p>
                    </div>
                </div>
                <button
                    className={styles.starButton}
                    onClick={() => handleAddToFavorites(deviceId, isFavorite)}
                >
                    <Star className={isFavorite ? styles.starIconActive : styles.starIcon} size={24} />
                </button>
            </div>

            {type === BUTTONS.BUTTONS_TYPES.IR_REMOTE && <DevicePopupRemote
                statusText={statusText}
                deviceId={deviceId}
                personalId={personalId}
                handleDeviceToggle={handleDeviceToggle}
                remoteButtons={remoteButtons}
                isZigbee={isZigbee}
            />}

            {!isEditing && (
                <div className={styles.statusBox}>
                    {formatStatusText()}
                    {updatedAt && (
                        <div key={'updatedAt'} className={styles.statusItem}>
                            <div className={styles.statusKey}>{t.lastUpdate}</div>
                            <div className={styles.statusValue}>{formatUpdatedAt()}</div>
                        </div>
                    )}
                </div>
            )}

            {isEditing && isZigbee && (
                <div className={styles.editBox}>
                    <input
                        type="text"
                        value={editedData.name}
                        onChange={e => handleEditChange('name', e.target.value)}
                        className={styles.input}
                        placeholder={t.deviceName}
                    />
                    <div className={styles.divider} />
                    <select
                        value={editedData.roomId === null ? -1 : editedData.roomId}
                        onChange={e => handleEditChange('roomId', Number(e.target.value))}
                        className={`${styles.select} ${editedData.roomId === null ? styles.highlight : ''}`}
                    >
                        <option value={-1}>{t.selectRoom}</option>
                        {renderRoomOptions()}
                    </select>
                    {isLandscape && (
                        <>
                            <div className={styles.divider} />
                            <div>
                                <input
                                    value={Number(editedData.positionLeft) || 0}
                                    onChange={e => handleEditChange('positionLeft', e.target.value)}
                                    className={`${styles.input} ${styles.inputPosition}`}
                                    placeholder={t.leftDistance}
                                />
                                <input
                                    value={Number(editedData.positionTop) || 0}
                                    onChange={e => handleEditChange('positionTop', e.target.value)}
                                    className={`${styles.input} ${styles.inputPosition}`}
                                    placeholder={t.topDistance}
                                />
                            </div>
                        </>
                    )}
                    <div className={styles.editButtonsBox}>
                        <button onClick={handleCancelEditing} className={styles.cancelButton}>{t.cancel}</button>
                        {isEditLoading ? (
                            <ClipLoader color="#4A90E2" size={25} />
                        ) : (
                            <button onClick={handleSaveEditing} className={styles.saveButton}>{t.save}</button>
                        )}
                    </div>
                </div>
            )}
            {isEditing && isZigbee && <div className={styles.deleteButtonBox}>
                {isDeleting ? <ClipLoader color="#4A90E2" size={25} /> : (
                    <button className={styles.deleteButton} onClick={handleDeleteZigbeeDevice}>{t.deleteZigbeeDevice}</button>
                )}
            </div>}
        </div>
    );
};

DevicePopupContent.propTypes = {
    type: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    handleDeviceToggle: PropTypes.func.isRequired,
    status: PropTypes.bool,
    statusText: PropTypes.object,
    name: PropTypes.string.isRequired,
    roomName: PropTypes.string.isRequired,
    roomAndFloorData: PropTypes.object.isRequired,
    floorId: PropTypes.number,
    isFavorite: PropTypes.bool.isRequired,
    handleAddToFavorites: PropTypes.func.isRequired,
    isZigbee: PropTypes.bool,
    roomId: PropTypes.number,
    positionLeft: PropTypes.number,
    positionTop: PropTypes.number,
    updatedAt: PropTypes.string,
    remoteButtons: PropTypes.array,
};

DevicePopupContent.defaultProps = {
    status: null,
    statusText: null,
    floorId: null,
    isZigbee: false,
    roomId: null,
    positionLeft: null,
    positionTop: null,
    updatedAt: null,
    remoteButtons: null,
};

export default memo(DevicePopupContent);
