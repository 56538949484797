
const translations = {
    ru: {
        controlApp: 'Приложение',
        openApp: 'Открыть приложение',

        loading: 'Загрузка...',
    },
    en: {
        controlApp: 'App',
        openApp: 'Open app',

        loading: 'Loading...',
    },
};

export default translations;
