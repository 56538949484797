import React, { useEffect, useContext, useCallback, memo } from "react";
import PropTypes from 'prop-types';

import YandexMetrika from './utils/YandexMetrika.js';
import Header from './components/Header.js';
import ScrollColorChange from './components/ScrollColorChange.js';
import FeaturesBlock from './components/FeaturesBlock.js';
import AppFeatures from './components/AppFeatures.js';
import YourCapabilities from './components/YourCapabilities.js';
import ComparisonWithOthers from './components/ComparisonWithOthers.js';
// import PricesBlock from './components/PricesBlock.js';
import Form from './components/Form.js';

import { LanguageContext } from '../routes/LanguageContext';
import translations from './language/LandingContainer.translations.js';

import styles from './styles/LandingContainer.module.css';

const LandingContainer = ({ urlLang }) => {
  const { language, setNewLanguageURL } = useContext(LanguageContext);
  const t = translations[language];

  useEffect(() => {
    if (urlLang) {
      setNewLanguageURL(urlLang);
    }
  }, [urlLang, setNewLanguageURL]);

  useEffect(() => {
    document.title = t.title;
  }, [t]);

  const scrollToForm = useCallback(() => {
    const formElement = document.getElementById("request");
    if(formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={styles.landingPage}>
      <YandexMetrika />
      <Header scrollToForm={scrollToForm} scrollToTop={scrollToTop} />

      <div className={styles.landingContentContainer}>
        <div className={styles.frameFirstContainer}>
          <div className={styles.firstFrameBox}>
            <a onClick={scrollToForm} href="#requestForm" className={styles.firstFrameButton}>{t.transformYourHomeButtonText}</a>
            <h2 className={styles.firstFrameTitle}>{t.transformYourHomeTitle}</h2>
          </div>
          <h4 className={styles.firstFrameSubtitle}>{t.transformYourHomeSubtitle}</h4>
        </div>

        <div className={styles.frameSecondContainer}>
          <div className={styles.landingImage}></div>
          <img
            src={`../../images/site/iphone${language === 'ru' ? '_ru' : '_en'}.png`}
            alt="GETHOME App"
            className={styles.frameSecondImage}
          />
        </div>

        <div className={styles.frameThirdContainer}>
          <ScrollColorChange text={t.scrollColorChangeText1} />
          <ScrollColorChange text={t.scrollColorChangeText2} />
          <ScrollColorChange text={t.scrollColorChangeText3} />
        </div>

        <FeaturesBlock />
        <AppFeatures />

        {language === 'ru' && (<div className={styles.visionContainer}>
          <img src="../../images/site/vision1.png" alt="GETHOME App on Apple Vision Pro" className={styles.visionImage} />
        </div>)}

        <YourCapabilities />
        <ComparisonWithOthers />
        {/* <PricesBlock /> */}
        <Form />
      </div>

    </div>
  );
};

LandingContainer.propTypes = {
  urlLang: PropTypes.string,
};

LandingContainer.defaultProps = {
  urlLang: null,
};

export default memo(LandingContainer);
