import React from 'react';

function DoorIcon() {
  return (
    <svg width="25pt" height="25pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '5px' }}>
      <path d="m358.35 296.7c9.2266 0 16.703-7.4727 16.703-16.703 0-9.2266-7.4727-16.703-16.703-16.703-9.2266 0-16.703 7.4727-16.703 16.703 0 9.2266 7.4766 16.703 16.703 16.703zm-133.61-150.32h-33.145c-18.512 0-33.66 15.172-33.66 33.52v200.19c0 18.582 14.941 33.52 33.66 33.52l33.145 0.003906c9.2266 0 16.703-7.4727 16.703-16.703 0-9.2266-7.4727-16.703-16.703-16.703h-33.145c-0.27734 0-0.25781 0.015625-0.25781-0.11719v-200.19c0 0.09375 0.20703-0.11719 0.25781-0.11719l33.145 0.003907c9.2266 0 16.703-7.4727 16.703-16.703 0-9.2266-7.4766-16.703-16.703-16.703zm83.508-11.848c0-9.1367 7.6172-15.625 16.402-14.555l167.62 20.391c9.0586 1.1133 16.398 9.3711 16.398 18.398v242.46c0 9.0508-7.6172 17.344-16.402 18.445l-167.62 20.953c-9.0586 1.125-16.398-5.3477-16.398-14.508zm-33.402-21.469c0-18.496 15.133-31.59 33.301-29.32l200.62 25.078c18.391 2.2969 33.305 19.379 33.305 37.402v267.53c0 18.363-15.133 35.141-33.301 37.41l-200.62 25.078c-18.391 2.3047-33.305-11.055-33.305-29.32z" fillRule="evenodd"/>
    </svg>
  );
}

export default DoorIcon;
