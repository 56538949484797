import React, { useEffect, useState, useRef, useContext, useMemo, memo } from 'react';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from '../language/LandingContainer.translations.js';

import styles from './styles/FeaturesBlock.module.css';

const FeaturesBlock = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const features = useMemo(() => [
    { description: t.features1, image: '../../images/site/features/features3.png' },
    { description: t.features2, image: '../../images/site/features/features2.png' },
    { description: t.features3, image: '../../images/site/features/features6.jpg' },
    { description: t.features4, image: '../../images/site/features/features4.jpg' },
    { description: t.features5, image: '../../images/site/features/features1.png' },
    { description: t.features6, image: '../../images/site/features/features5.jpg' },
  ], [t]);

  const containerRef = useRef(null);
  const [animate, setAnimate] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let scrollingTimeout;

    const handleScroll = () => {
      if (!containerRef.current) return;
      
      clearTimeout(scrollingTimeout);
      setIsScrolling(true);

      scrollingTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 100);

      const { top } = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (top - windowHeight < 0) {
        setAnimate(true);
      } else {
        setAnimate(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollingTimeout);
    };
  }, []);

  return (
    <div ref={containerRef} className={styles.featuresContainer}>
      <div className={`${styles.column} ${animate ? styles.slideInLeft : ''}`}>
        {features.slice(0, features.length / 2).map((feature, index) => (
          <div key={index} className={`${styles.featureBlock} ${isScrolling ? styles.scroll : ''}`}>
            <img src={feature.image} alt="Feature" />
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
      <div className={`${styles.column} ${animate ? styles.slideInRight : ''}`}>
        {features.slice(features.length / 2).map((feature, index) => (
          <div key={index + 2} className={`${styles.featureBlock} ${isScrolling ? styles.scroll : ''}`}>
            <img src={feature.image} alt="Feature" />
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(FeaturesBlock);
