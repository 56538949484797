import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/MenuPoint.module.css';

const MOBILE_WIDTH = 480;

const MenuPoint = ({ buttonsCount, selectedPageNum }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);

  const pointBoxHeight = 60;
  const pointBoxWidth = 55;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <div
      className={styles.pointsBox}
      style={isMobile ? {
        width: 'calc(100% - 10px)'
      } : {
        height: buttonsCount * pointBoxHeight
      }}
    >
      <div
        className={styles.pointBox}
        style={isMobile ? {
          width: `${pointBoxWidth}px`,
          left: `calc(${(pointBoxWidth + 15) * selectedPageNum}px)`,
        } : {
          height: pointBoxHeight,
          top: pointBoxHeight * selectedPageNum
        }}
      >
        <div className={styles.point}></div>
      </div>
    </div>
  );
};

MenuPoint.propTypes = {
  buttonsCount: PropTypes.number.isRequired,
  selectedPageNum: PropTypes.number,
};

MenuPoint.defaultProps = {
  selectedPageNum: 0,
};

export default memo(MenuPoint);

