import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { uploadHomeFile } from '../../../api';

import HouseForm from './HouseForm';
import FileUpload from './FileUpload';
import HouseEditBlock from './HouseEditBlock';
import DeviceEditBlock from './DeviceEditBlock';
import DevicePositionBlock from './DevicePositionBlock';
import Zigbee2mqtt from './Zigbee2mqtt';

import styles from './styles/HouseUpdateForm.module.css';

const HouseUpdateForm = ({ closeForm, getHouses, houseId, houses }) => {
  const [activeTab, setActiveTab] = useState('mqtt');
  const [devices, setDevices] = useState([]);

  const handleFileLoaded = useCallback(data => {
    if (data.length > 0) {
      setDevices(data);
    }
  }, []);

  const houseData = useMemo(() => {
    return houses.find(house => house.id === houseId);
  }, [houses, houseId]);

  if (!houseId) {
    return null;
  }

  return (
    <HouseForm title={`Настройки дома - ID ${houseId}`} closeForm={closeForm}>
      <div className={styles.tabMenu}>
        <button
          className={`${styles.tabButton} ${activeTab === 'mqtt' ? `${styles.active}` : ''}`}
          onClick={() => setActiveTab('mqtt')}
        >
          MQTT
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'homeSettings' ? `${styles.active}` : ''}`}
          onClick={() => setActiveTab('homeSettings')}
        >
          Настройка дома
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'fileUpload' ? `${styles.active}` : ''}`}
          onClick={() => setActiveTab('fileUpload')}
        >
          Загрузка config-файлов
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'deviceSettings' ? `${styles.active}` : ''}`}
          onClick={() => setActiveTab('deviceSettings')}
        >
          Настройка устройств
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'devicePositions' ? `${styles.active}` : ''}`}
          onClick={() => setActiveTab('devicePositions')}
        >
          Настройка расположения устройств
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'zigbee2mqtt' ? `${styles.active}` : ''}`}
          onClick={() => setActiveTab('zigbee2mqtt')}
        >
          Zigbee2mqtt
        </button>
      </div>

      {activeTab === 'mqtt' && (
        <div className={styles.mqttData}>
          {!houseData && (
            <p className={styles.text}>Нет данных</p>
          )}
          {houseData && (
            <>
              <p><b>MQTT включён:</b> {houseData.mqttEnabled ? "Да" : "Нет"}</p>
              <p><b>Адрес:</b> gethome.me</p>
              <p><b>Порт:</b> 1883</p>
              <p><b>Топики:</b> home/{houseData.id}/{'*'}</p>
              <p><b>Пользователь:</b> {houseData.mqtt_username}</p>
              <p><b>Пароль:</b> {houseData.mqtt_password}</p>
            </>
          )}
        </div>
      )}

      {activeTab === 'homeSettings' && (
        <HouseEditBlock houseId={houseId} closeForm={closeForm} getHouses={getHouses} />
      )}

      {activeTab === 'fileUpload' && (
        <>
          {devices.length === 0 && (
            <>
              <p className={styles.text}>Загрузите lineq или linei файл:</p>
              <FileUpload params={{ homeId: houseId }} onFileLoaded={handleFileLoaded} uploadFile={uploadHomeFile} />
            </>
          )}
          {devices.length > 0 && (
            <DeviceEditBlock devicesData={devices} setDevicesData={setDevices} houseId={houseId} blockType="file" />
          )}
        </>
      )}

      {activeTab === 'deviceSettings' && (
        <DeviceEditBlock houseId={houseId} blockType="edit" />
      )}

      {activeTab === 'devicePositions' && (
        <DevicePositionBlock houseId={houseId} />
      )}

      {activeTab === 'zigbee2mqtt' && (
        <Zigbee2mqtt houseId={houseId} />
      )}
    </HouseForm>
  );
};

HouseUpdateForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  houseId: PropTypes.number.isRequired,
  houses: PropTypes.arrayOf(PropTypes.object),
  getHouses: PropTypes.func.isRequired,
};

HouseUpdateForm.defaultProps = {
  houses: [],
};

export default memo(HouseUpdateForm);
