import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import InfoPopup from '../common/InfoPopup';

import styles from './styles/Instruction.module.css';

function RoomsInstruction({ onClose }) {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        { content: 'Нажмите на название или иконку комнаты, чтобы зайти в неё и управлять устройствами' },
        { content: 'Используйте кнопку с лампочкой для включения или выключения всего освещения в комнате. Кнопка активна, если в комнате включен хотя бы один свет' },
        { content: 'Кнопка с иконкой питания управляет всеми устройствами в комнате, за исключением датчиков. Она активна, если в комнате включено хотя бы одно устройство' },
        { content: 'Под названием каждой комнаты отображается информация о количестве устройств (без датчиков), количестве включенных устройств и включенном свете' },
        { content: 'Нажмите на кнопку "Все", чтобы показать все комнаты' },
    ];

    return (
        <InfoPopup onClose={onClose}>
            <h1 className={styles.title}>Инструкция по блоку комнат</h1>
            {currentSlide < 5 && <img src={`../../../images/rooms-instruction/${currentSlide + 1}.jpg`} alt="instruction" className={styles.image}/>}
            <p className={styles.slideContent}>{slides[currentSlide].content}</p>
            <div className={styles.slideControls}>
                <div>
                    {currentSlide > 0 && <button onClick={() => setCurrentSlide(prev => Math.max(prev - 1, 0))} className={styles.button}>Назад</button>}
                </div>
                <div>
                    {currentSlide < slides.length - 1 ? (
                        <button onClick={() => setCurrentSlide(prev => Math.min(prev + 1, slides.length - 1))} className={styles.button}>Вперед</button>
                    ) : (
                        <button onClick={onClose} className={styles.button}>Закрыть</button>
                    )}
                </div>
            </div>
        </InfoPopup>
    );
}

RoomsInstruction.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default memo(RoomsInstruction);
