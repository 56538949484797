import React, { useCallback, useRef, useState, useEffect, memo } from 'react';
import { getAllHomes } from '../../api';
import HouseCreateForm from './houseForms/HouseCreateForm';
import HouseUpdateForm from './houseForms/HouseUpdateForm';

import styles from './styles/Houses.module.css';

const Houses = () => {
  const [isCreateHomeFormOpen, setCreateHomeFormOpen] = useState(false);
  const [isUpdateHomeFormOpen, setUpdateHomeFormOpen] = useState(false);
  const [houses, setHouses] = useState([]);
  const [selectedHouseId, setSelectedHouseId] = useState(null);

  const { current: openCreateHomeForm } = useRef(() => setCreateHomeFormOpen(true));
  const { current: closeCreateHomeForm } = useRef(() => setCreateHomeFormOpen(false));

  const { current: openUpdateHomeForm } = useRef(id => {
    setUpdateHomeFormOpen(true);
    setSelectedHouseId(id);
  });
  const { current: closeUpdateHomeForm } = useRef(() => setUpdateHomeFormOpen(false));

  const getHouses = useCallback(async () => {
    try {
      const { data } = await getAllHomes();
      if (data?.homes) {
        setHouses(data.homes);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getHouses();
  }, [getHouses]);

  // TODO: выводить количество пользователей у дома и количество устройств, выводить подключенные подписки дома

  return (
    <div>
      <div>
        <div className={styles.titleBox}>
          <h1 className={styles.panelTitle}>Список домов</h1>
          <button onClick={openCreateHomeForm} className={styles.button}>Создать новый дом</button>
        </div>
        <div>
          {houses && houses.length > 0 && (
            <table className={styles.housesTable}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Название дома</th>
                  <th>Телефон владельца</th>
                  <th>Настройки дома</th>
                </tr>
              </thead>
              <tbody>
                {houses.map(house => (
                  <tr key={house.id}>
                    <td>{house.id}</td>
                    <td>{house.name}</td>
                    <td>{house.ownerPhone ? `+${house.ownerPhone}` : "-"}</td>
                    <td>
                      <button onClick={() => openUpdateHomeForm(house.id)} className={styles.menuButton}>Открыть настройки</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {(!houses || houses.length === 0) && <p className={styles.text}>Нет данных</p>}
        </div>
      </div>
      {isCreateHomeFormOpen && (
        <HouseCreateForm
          closeForm={closeCreateHomeForm}
          getHouses={getHouses}
        />
      )}
      {isUpdateHomeFormOpen && (
        <HouseUpdateForm
          closeForm={closeUpdateHomeForm}
          getHouses={getHouses}
          houseId={selectedHouseId}
          houses={houses}
        />
      )}
    </div>
  );
};

export default memo(Houses);
