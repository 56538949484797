import React, { useContext, useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import IosButton from '../common/IosButton';
import ControlsBigButton from '../common/ControlsBigButton';
import { getZigbeeData, setZigbeeConnectionState } from '../../utils/api';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/ZigbeeSettings.translations.js';

import styles from './styles/settings.module.css';
import pageStyles from './styles/ZigbeeSettings.module.css';

const ZigbeeSettings = ({
    isMobile,
    onClose,
    personalId,
    devices,
    handleDeviceToggle,
    roomAndFloorData,
    handleAddToFavorites,
}) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [isCanConnect, setIsCanConnect] = useState(false);
    const zigbeeDevices = devices.filter(device => device.isZigbee);

    const fetchZigbeeState = useCallback(async () => {
        const { data } = await getZigbeeData(personalId);
        if (data) {
            setIsCanConnect(data.isCanConnect);
        }
    }, [personalId]);

    useEffect(() => {
        fetchZigbeeState();
    }, [fetchZigbeeState]);

    const handleConnectionToggle = useCallback(async () => {
        try {
            const response = await setZigbeeConnectionState(personalId, !isCanConnect);
            if (response.status === 200) {
                setIsCanConnect(!isCanConnect);
            }
        } catch (error) {
            console.error('Ошибка при изменении состояния подключения Zigbee:', error);
        }
    }, [personalId, isCanConnect]);

    return (
        <div className={styles.container}>
            {isMobile && <BackButton onClick={onClose} />}
            <div className={styles.formGroup}>
                <h3 className={styles.title}>{t.title}</h3>

                <div className={`${styles.blockGray} ${pageStyles.newConnectBox}`}>
                    <p className={styles.name}>{t.connect}</p>
                    <div>
                        <IosButton onClick={handleConnectionToggle} isActive={isCanConnect} />
                    </div>
                </div>

                <div className={styles.blockGray}>
                    <p className={styles.blockTitle}>{t.connectedDevices}</p>
                    <div className={pageStyles.buttonsBox}>
                        {zigbeeDevices?.map((device, index) => (
                            <ControlsBigButton
                                key={index}
                                type={device.deviceType}
                                name={device.deviceName}
                                deviceId={device.id}
                                handleDeviceToggle={handleDeviceToggle}
                                status={device.status}
                                statusText={device.statusText}
                                roomName={device.roomName}
                                position={device.position}
                                roomAndFloorData={roomAndFloorData}
                                floorId={device.floorId}
                                isFavorite={device.isFavorite}
                                handleAddToFavorites={handleAddToFavorites}
                                isLightBackground={true}
                                isShowFloorName={true}
                                isZigbee={device.isZigbee}
                                roomId={device.roomId}
                                positionLeft={device.positionLeft}
                                positionTop={device.positionTop}
                                updatedAt={device.updatedAt}
                                remoteButtons={device.remoteButtons}
                            />
                        ))}
                        {zigbeeDevices.length === 0 && <p>{t.noDevices}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

ZigbeeSettings.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    personalId: PropTypes.string.isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    handleDeviceToggle: PropTypes.func.isRequired,
    roomAndFloorData: PropTypes.shape({}),
    handleAddToFavorites: PropTypes.func.isRequired,
};

ZigbeeSettings.defaultProps = {
    devices: [],
    roomAndFloorData: {},
};

export default memo(ZigbeeSettings);
