
const translations = {
    ru: {
        back: 'Назад',
    },
    en: {
        back: 'Back',
    },
};

export default translations;
