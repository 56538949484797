import React, { useState, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import HouseForm from './HouseForm';
import { createHome } from '../../../api';

import styles from './styles/HouseCreateForm.module.css';

const HouseCreateForm = ({ closeForm, getHouses }) => {
  const [homeName, setHomeName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const { current: handleInputChange } = useRef(event => setHomeName(event.target.value));

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    try {
      await createHome({ name: homeName, phoneNumber });

      setHomeName('');
      setPhoneNumber('');

      getHouses();
      closeForm();
    } catch (error) {
      console.log(error);
    }
  }, [getHouses, homeName, phoneNumber, closeForm]);

  return (
    <HouseForm title={"Создать дом"} closeForm={closeForm}>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={homeName}
          onChange={handleInputChange}
          placeholder="Имя дома"
          required
          className={styles.formInput}
        />
        <InputMask
          mask="+9 (999) 999-99-99"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          placeholder="Номер телефона владельца дома"
          required
          className={styles.formInput}
        />
        <button type="submit" className={styles.formButton}>Создать</button>
      </form>
    </HouseForm>
  );
};

HouseCreateForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  getHouses: PropTypes.func.isRequired,
};

export default memo(HouseCreateForm);
