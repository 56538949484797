import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';

import styles from './styles/InfoPopup.module.css';

const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

const InfoPopup = ({ children, onClose, isShouldCloseOnOutsideClick }) => {
    const popupRef = useRef();
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
        }, 300); // Устанавливаем таймаут соответствующий продолжительности анимации
    }, [onClose]);

    const handleClickOutside = useCallback((event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            event.stopPropagation();

            if (isShouldCloseOnOutsideClick && isTouchDevice) {
                handleClose();
            }
        }
    }, [handleClose, isShouldCloseOnOutsideClick]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, { capture: true });

        return () => {
            document.removeEventListener('click', handleClickOutside, { capture: true });
        };
    }, [handleClickOutside]);

    return (
        <div ref={popupRef} className={`${styles.container} ${isClosing ? styles.closing : ''}`}>
            <button onClick={handleClose} className={styles.closeIconBtn}>
                <X className={styles.closeIcon} />
            </button>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

InfoPopup.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    isShouldCloseOnOutsideClick: PropTypes.bool,
};

InfoPopup.defaultProps = {
    isShouldCloseOnOutsideClick: true,
};

export default memo(InfoPopup);
