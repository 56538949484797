import React, { memo } from 'react';
import PropTypes from 'prop-types';

import LightIcon from '../../../images/icons/LightIcon';
import WaterIcon from '../../../images/icons/WaterIcon';
import MotionIcon from '../../../images/icons/MotionIcon';
import DoorIcon from '../../../images/icons/DoorIcon';
import WaterLeakIcon from '../../../images/icons/WaterLeakIcon';
import SmokeIcon from '../../../images/icons/SmokeIcon';

// Power, Command
import { Plug, Thermometer, ToggleRight, Blinds, Power, Nfc } from 'lucide-react';

const DeviceIcon = ({ type }) => {

  switch (type) {
    case "light":
      return <LightIcon />;
    case "water":
      return <WaterIcon />;
    case "motion":
      return <MotionIcon />;
    case "magnet":
      return <DoorIcon />;
    case "water_leak":
      return <WaterLeakIcon />;
    case "smoke":
      return <SmokeIcon />;
    case "socket":
      return <Plug />;
    case "temperature_humidity":
      return <Thermometer />;
    case "temperature":
      return <Thermometer />;
    case "one_button_switch":
      return <ToggleRight />;
    case "curtain":
      return <Blinds />;
    case "switch":
      return <Power />;
    case "ir_remote":
      return <Nfc />;
    default:
      return null;
  }

};

DeviceIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default memo(DeviceIcon);
