import React, { useEffect, useRef, useState, useCallback, memo } from 'react';
import { Play, Pause } from 'lucide-react';
import PropTypes from 'prop-types';

import styles from './styles/VideoPlayer.module.css';

const VideoPlayer = ({ src, type }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const handlePlayPause = useCallback(() => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play().catch(error => {
                console.error('Failed to play the video:', error);
            });
        }
        setIsPlaying(!isPlaying);
    }, [isPlaying]);

    const { current: handleMouseEnter} = useRef(() => {
        setShowButton(true);
    });

    const { current: handleMouseLeave } = useRef(() => {
        setShowButton(false);
    });

    useEffect(() => {
        videoRef.current.play();
        setIsPlaying(true);
    }, []);

    useEffect(() => {
        const videoElement = videoRef.current;

        const playPromise = videoElement.play();
        if (playPromise !== undefined) {
            playPromise.then(() => {
                setIsPlaying(true);
            }).catch(error => {
                setIsPlaying(false);
                console.log('Автовоспроизведение заблокировано:', error);
            });
        }
        
        const handlePause = () => setIsPlaying(false);
        const handlePlay = () => setIsPlaying(true);
        
        videoElement.addEventListener('pause', handlePause);
        videoElement.addEventListener('play', handlePlay);

        return () => {
            videoElement.removeEventListener('pause', handlePause);
            videoElement.removeEventListener('play', handlePlay);
        };
    }, []);

    if (!src) {
        return null;
    }

    return (
        <div className={styles.videoContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <video className={styles.videoPlayer} ref={videoRef} autoPlay muted loop>
                <source src={src} type={type} />
                Ваш браузер не поддерживает тег video.
            </video>
            <button className={styles.playPauseButton} onClick={handlePlayPause} style={{ opacity: showButton ? 1 : 0 }}>
                {isPlaying ? <Pause size={14} className={styles.icon} /> : <Play size={14} className={styles.icon} />}
            </button>
        </div>
    );
};

VideoPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default memo(VideoPlayer);
