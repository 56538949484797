
const translations = {
    ru: {
        title: 'Пользователи дома',
        text: 'Добавлять и удалять пользователей может только владелец дома.',
        addButton: 'Добавить пользователя',

        // errors
        error: 'Ошибка при добавлении пользователя',
        userAlreadyExists: 'Пользователь уже добавлен в дом',
        userNotFound: 'Пользователь не найден',
        wrongPhoneNumber: 'Неверный формат номера телефона',

        // popup
        popupTitle: 'Добавить пользователя',
        popupText: 'Введите номер телефона пользователя, которого хотите добавить в дом',
        popupPhoneMask: '+7 (999) 999-99-99',
        popupPhonePlaceholder: 'Номер телефона пользователя',
        popupCancel: 'Отмена',
        popupAdd: 'Добавить',
    },
    en: {
        title: 'Home users',
        text: 'Only the owner of the house can add and remove users.',
        addButton: 'Add user',

        // errors
        error: 'Error adding user',
        userAlreadyExists: 'User is already added to the house',
        userNotFound: 'User not found',
        wrongPhoneNumber: 'Invalid phone number format',

        // popup
        popupTitle: 'Add user',
        popupText: 'Enter the phone number of the user you want to add to the house',
        popupPhoneMask: '+1 (999) 999-9999',
        popupPhonePlaceholder: 'User phone number',
        popupCancel: 'Cancel',
        popupAdd: 'Add',
    },
};

export default translations;
