import React, { useState, useCallback, useEffect, useContext, memo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
// TODO: InputMask скорее всего использует findDOMNode, который устарел, это надо исправить
import OtpInput from 'react-otp-input';
import { sendSms, verifyCode } from '../api';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from './language/Login.translations.js';

import styles from './styles/Login.module.css';

// TODO: Надо сделать вывод ошибок на русском и английском языках - сейчас только на русском
// TODO: Надо сделать выбор страны номера телефона - сейчас только Россия и США

const Login = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    const t = translations[language];

    const [phoneNumber, setPhoneNumber] = useState(language === 'ru' ? '7' : '1');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(1);
    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();

    const location = useLocation();
    const currentQuery = location.search;

    useEffect(() => {
      document.title = t.title;
    }, [t]);

    useEffect(() => {
        setPhoneNumber(language === 'ru' ? '7' : '1');
    }, [language]);

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timer]);

    const handlePhoneChange = useCallback(e => {
        let value = e.target.value;

        // Удаляем не цифры
        value = value.replace(/[^\d]/g, '');

        if(language === 'ru' && value[1] === '8') {
            value = '7' + value.slice(1); // Если была вставка номера с 8, то заменяем на 7
        }

        setPhoneNumber(value);
        setMessage('');
    }, [language]);

    const handlePhoneSubmit = useCallback(async e => {
        e?.preventDefault();
        if (timer > 0) return;

        try {
            await sendSms(phoneNumber);
            setMessage('');
            setStep(2);
            setTimer(60);
        } catch (error) {
            setMessage(error.response.data.message);
        }
    }, [phoneNumber, timer]);

    const handleCodeSubmit = useCallback(async (e, otp) => {
        e?.preventDefault();
        const codeValue = otp || code;
        if (codeValue.length < 4) return;

        try {
            await verifyCode(phoneNumber, codeValue);

            // Возвращаем пользователя на сохраненный URL или на /app/home по умолчанию
            const redirectTo = location.state && location.state.from 
                ? location.state.from.pathname + location.state.from.search
                : `/app/home${currentQuery}`;

            navigate(redirectTo);
        } catch (error) {
            setMessage(error.response.data.message);
        }
    }, [code, currentQuery, location, navigate, phoneNumber]);

    const handleOtpChange = useCallback(otp => {
        setCode(otp);
        if (otp.length === 4) {
            handleCodeSubmit(null, otp);
        }
    }, [handleCodeSubmit]);

    return (
        <div className={styles.loginPage}>
            <div className={styles.logoContainer}>
                <Link to="/">
                    <img src="../../images/logo/icon_black.png" alt="GetHome Logo" className="icon" />
                </Link>
                <button onClick={toggleLanguage} className={styles.button}>{language}</button>
            </div>
            <div className={styles.loginContainer}>
                {step === 1 ? (
                    <>
                        <div className={styles.loginBox}>
                            <h1 className={styles.loginTitle}>{t.loginTitle}</h1>
                            <h2 className={styles.loginTitle}>{t.loginSubtitle}</h2>
                        </div>
                        <form onSubmit={handlePhoneSubmit} className={styles.loginForm}>
                            <InputMask
                                mask={t.phoneMask}
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                className={styles.loginInput}
                                placeholder={t.phonePlaceholder}
                                type="tel"
                            />
                            <button type="submit" className={styles.loginButton}>{t.loginButton}</button>
                            <p className={styles.privacyText}>
                                {t.privacyText} <Link to="/privacy" className={styles.privacyLink}>{t.privacyLink}</Link>
                            </p>
                        </form>
                    </>
                ) : (
                    <>
                        <div className={styles.loginBox}>
                            <h1 className={styles.loginTitle}>{t.codeTitle}</h1>
                            <h2 className={styles.loginTitle}>{t.codeSubtitle}<br />{phoneNumber}</h2>
                        </div>
                        <form onSubmit={handleCodeSubmit} className={styles.loginForm}>
                            <div className={styles.otpContainer}>
                                <OtpInput
                                    value={code}
                                    onChange={handleOtpChange}
                                    numInputs={4}
                                    shouldAutoFocus
                                    renderInput={props => <input {...props} />}
                                    inputType="tel"
                                    inputStyle={styles.otpInput}
                                />
                            </div>
                            <button type="submit" className={styles.loginButton}>{t.confirmButton}</button>
                            <button type="button" onClick={handlePhoneSubmit} className={styles.resendButton} disabled={timer > 0}>
                                {timer > 0 ? `${t.timerResend} ${timer} ${t.timerSeconds}` : t.timerResendButton}
                            </button>
                        </form>
                    </>
                )}
                {message && <p className={styles.messageText}>{message}</p>}
            </div>
        </div>
    );
};

export default memo(Login);
