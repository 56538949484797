import React, { useContext, useState, useRef, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import { getTelegramData, deleteTelegram, getTelegramLink } from '../../utils/api';
import { Trash2, Check } from 'lucide-react';
import { ClipLoader } from 'react-spinners';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/TelegramSettings.translations.js';

import styles from './styles/settings.module.css';
import pageStyles from './styles/TelegramSettings.module.css';

const TelegramSettings = ({ isMobile, onClose }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [username, setUsername] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);

    const fetchData = useCallback(async () => {
        const { data } = await getTelegramData();
        if (data.telegramUsername) {
            setUsername(data.telegramUsername);
        } else {
            setUsername(null);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [fetchData]);

    const { current: handleDeleteClick } = useRef(() => {
        setIsDeleting(true);
    });

    const { current: handleConfirmDelete } = useRef(async () => {
        try {
            await deleteTelegram();
            setUsername(null);
        } catch (error) {
            console.error('Ошибка при удалении Telegram:', error);
        }

        setIsDeleting(false);
    });

    const handleConnectClick = useCallback(async () => {
        setIsLoading(true);

        try {
            const { data } = await getTelegramLink();
            if (data?.link) {
                window.location.href = data.link;
            }
        } catch (error) {
            console.error('Ошибка при подключении Telegram:', error);
        }

        setIsLoading(false);
    }, []);

    return (
        <div className={styles.container}>
            {isMobile && <BackButton onClick={onClose} />}
            <div className={styles.formGroup}>
                <h3 className={styles.title}>{t.title}</h3>
                <p className={styles.text}>{t.subtitle}</p>

                {isLoading && (
                    <div className={styles.block}>
                        <ClipLoader color="#4A90E2" size={25} />
                    </div>
                )}

                {!isLoading && (
                    <div className={styles.blockGray}>
                        {username ? (
                            <>
                                <p className={styles.blockTitle}>{t.connected}</p>
                                <div className={pageStyles.box}>
                                    <p className={pageStyles.username}>@{username}</p>
                                    <div className={pageStyles.userButton}>
                                        {isDeleting ? (
                                            <button
                                                className={`${pageStyles.trashButton} ${pageStyles.confirmButton}`}
                                                onClick={() => handleConfirmDelete()}
                                            >
                                                <Check size={20} />
                                            </button>
                                        ) : (
                                            <button className={pageStyles.trashButton} onClick={() => handleDeleteClick()}>
                                                <Trash2 size={18} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className={styles.blockTitle}>{t.notConnected}</p>
                                <div className={pageStyles.box}>
                                    <button className={pageStyles.addUserButton} onClick={handleConnectClick}>
                                        {t.connectButton}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

TelegramSettings.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default memo(TelegramSettings);
