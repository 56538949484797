
const translations = {
    ru: {
        title: "GETHOME - Пользовательское соглашение",

        titlePrivacy: "Пользовательское Соглашение для сайта GETHOME",

        section1: "Данный документ (\"Пользовательское соглашение\") регулирует отношения между индивидуальным пользователем (\"Пользователь\") и компанией GetHome (\"Компания\"), владеющей веб-сайтом https://gethome.me (\"Сайт\").",

        section2: "1. Общие положения",
        section2_1: "1.1. Регистрируясь на Сайте, Пользователь соглашается с условиями настоящего Соглашения.",
        section2_2: "1.2. Компания оставляет за собой право вносить изменения в Соглашение в любое время без предварительного уведомления Пользователя.",

        section3: "2. Регистрация и учетная запись",
        section3_1: "2.1. Для доступа к личному кабинету Пользователь должен зарегистрироваться, используя свой номер телефона.",
        section3_2: "2.2. Пользователь обязуется предоставить актуальную и полную информацию о себе. Компания не несет ответственности за точность и правильность предоставленной Пользователем информации.",

        section4: "3. Услуги",
        section4_1: "3.1. Через личный кабинет Пользователь может оплачивать единоразовые услуги и подписки.",
        section4_2: "3.2. Пользователь может отменить подписку в любой момент. Оплаченный период подписки не возвращается.",
        section4_3: "3.3. Пользователь может получить доступ к дополнительным услугам, оплачивая их отдельно.",
        section4_4: "3.4. Пользователь может получить доступ к услугам, предоставляемым сторонними сервисами, через Сайт.",
        
        section5: "4. Персональные данные",
        section5_1: "4.1. Компания собирает и хранит следующие данные: номер телефона, email, ФИО, аватар, дата рождения, данные о домах.",
        section5_2: "4.2. Компания может передать данные об устройствах умного дома Пользователя третьим сторонам (например, Яндекс), но только с разрешения Пользователя.",

        section6: "5. Защита данных",
        section6_1: "5.1. Компания применяет различные методы защиты данных, такие как шифрование, физическая защита серверов и прочие.",

        section7: "6. Несоблюдение условий",
        section7_1: "6.1. В случае нарушения условий настоящего Соглашения, учетная запись Пользователя может быть заблокирована или удалена без возврата средств.",

        section8: "7. Юрисдикция",
        section8_1: "7.1. Данный документ и все правоотношения, возникающие из этого или в связи с ним, регулируются в соответствии с законодательством Российской Федерации, если дом находится в России.",
        section8_2: "7.2. Данный документ и все правоотношения, возникающие из этого или в связи с ним, регулируются в соответствии с законодательством страны, в которой находится дом Пользователя, если дом находится за пределами России.",

        section9: "8. Отказ от ответственности",
        section9_1: "8.1. Компания не несет ответственности за ущерб, причиненный Пользователю в результате использования Сайта.",
        section9_2: "8.2. Компания не несет ответственности за ущерб, причиненный Пользователю в результате использования сторонних сервисов, доступных через Сайт.",
        section9_3: "8.3. Компания не несет ответственности за ущерб, причиненный Пользователю в результате использования устройств умного дома, доступных через Сайт.",

        section10: "9. Заключительные положения",
        section10_1: "9.1. Все возможные споры по поводу Соглашения решаются в соответствии с действующим законодательством по месту регистрации Компании.",
        section10_2: "9.2. Ничто в Соглашении не может пониматься как установление агентских отношений, партнерских отношений, совместной деятельности, личных трудовых отношений между Пользователем и Компанией или каких-либо других отношений, прямо не предусмотренных Соглашением.",
        section10_3: "Для дополнительных вопросов обращайтесь по адресу: mail@gethome.me",
    },
    en: {
        title: "GETHOME - User Agreement",

        titlePrivacy: "User Agreement for the GETHOME website",

        section1: "This document (\"User Agreement\") regulates the relationship between an individual user (\"User\") and the company GetHome (\"Company\"), which owns the website https://gethome.me (\"Site\").",

        section2: "1. General provisions",
        section2_1: "1.1. By registering on the Site, the User agrees to the terms of this Agreement.",
        section2_2: "1.2. The Company reserves the right to make changes to the Agreement at any time without prior notice to the User.",

        section3: "2. Registration and account",
        section3_1: "2.1. To access the personal account, the User must register using their phone number.",
        section3_2: "2.2. The User undertakes to provide accurate and complete information about themselves. The Company is not responsible for the accuracy and correctness of the information provided by the User.",

        section4: "3. Services",
        section4_1: "3.1. Through the personal account, the User can pay for one-time services and subscriptions.",
        section4_2: "3.2. The User can cancel the subscription at any time. The paid subscription period is non-refundable.",
        section4_3: "3.3. The User can access additional services by paying for them separately.",
        section4_4: "3.4. The User can access services provided by third-party services through the Site.",
        
        section5: "4. Personal data",
        section5_1: "4.1. The Company collects and stores the following data: phone number, email, full name, avatar, date of birth, data about houses.",
        section5_2: "4.2. The Company may transfer data about the User's smart home devices to third parties (for example, Yandex), but only with the User's permission.",

        section6: "5. Data protection",
        section6_1: "5.1. The Company applies various methods of data protection, such as encryption, physical protection of servers, and others.",
        
        section7: "6. Non-compliance with the conditions",
        section7_1: "6.1. In case of violation of the terms of this Agreement, the User's account may be blocked or deleted without refund.",

        section8: "7. Jurisdiction",
        section8_1: "7.1. This document and all legal relations arising from or in connection with it are governed by the laws of the Russian Federation if the house is located in Russia.",
        section8_2: "7.2. This document and all legal relations arising from or in connection with it are governed by the laws of the country where the User's house is located if the house is outside Russia.",

        section9: "8. Disclaimer",
        section9_1: "8.1. The Company is not responsible for any damage caused to the User as a result of using the Site.",
        section9_2: "8.2. The Company is not responsible for any damage caused to the User as a result of using third-party services available through the Site.",
        section9_3: "8.3. The Company is not responsible for any damage caused to the User as a result of using smart home devices available through the Site.",

        section10: "9. Final provisions",
        section10_1: "9.1. All possible disputes regarding the Agreement are resolved in accordance with the current legislation at the place of registration of the Company.",
        section10_2: "9.2. Nothing in the Agreement may be construed as establishing agency relationships, partnership relationships, joint activities, personal employment relationships between the User and the Company, or any other relationships not directly provided for by the Agreement.",
        section10_3: "For additional questions, please contact: mail@gethome.me",
    },
};

export default translations;
