
const translations = {
    ru: {
        // LandingContainer
        title: 'GETHOME - Система Умный Дом',

        loginButtonText: 'Войти',
        ctaButtonText: 'Начать',
        transformYourHomeTitle: <>Интеллектуальное<br/>преобразование<br/>вашего дома</>,
        transformYourHomeSubtitle: 'Удобство и защита на высшем уровне',
        transformYourHomeButtonText: '✦ Умный дом начинается здесь!',

        scrollColorChangeText1: 'GETHOME - ваш умный дом под ключ.',
        scrollColorChangeText2: 'Сделайте свой дом умнее с помощью нашего приложения, которое позволяет управлять всеми устройствами через проводные и беспроводные технологии. Мы гарантируем совместимость и безопасность всех устройств. Удобство и простота использования - вот что делает наше решение идеальным для вашего умного дома.',
        scrollColorChangeText3: 'Улучшите свой дом с GETHOME и наслаждайтесь комфортом и безопасностью, которые предлагает умный дом.',

        // FeaturesBlock
        features1: 'Управляйте всеми аспектами вашего дома с любого устройства, где бы вы ни находились',
        features2: 'Круглосуточное спокойствие с защитой от проникновений, которая предупреждает о подозрительных активностях и обеспечивает безопасность вашего дома',
        features3: 'Сократите расходы на электроэнергию с интеллектуальной системой управления энергопотреблением',
        features4: 'Управляйте вашим умным домом при помощи голосовых команд для максимального удобства',
        features5: 'Защитите свой дом от неожиданностей с нашими решениями по предотвращению протечек и задымлений',
        features6: 'Поддерживайте идеальный микроклимат в доме с системой автоматического управления температурой и влажностью',

        // AppFeatures
        titleFeatures: 'Функции',
        titleApp: 'приложения',
        appFeatures1Title: 'Управление устройствами',
        appFeatures1Description: 'Удобное управление всеми устройствами вашего дома с разделением по этажам, комнатам и типам устройств.\n\nОтображение состояний утсроств и данных датчиков в реальном времени.',
        appFeatures2Title: 'Главный экран',
        appFeatures2Description: 'Удобный главный экран с нужной информацией о вашем доме, комнатах и устройствах.\n\nВозможность выключить всё освещение или все устройства в доме одним нажатием.',
        appFeatures3Title: 'Комнаты',
        appFeatures3Description: 'С главного экрана вы можете выключить всё освещение или все устройства в комнате одним нажатием.\n\nИли перейти в комнату для управления устройствами в ней.',
        appFeatures4Title: 'Избранные устройства',
        appFeatures4Description: 'Вы можете добавить самые важные устройства в избранное и получить к ним быстрый доутуп на главном экране.',
        appFeatures5Title: 'Пользователи',
        appFeatures5Description: 'У вас есть возможность легко добавлять пользователей в приложение по номеру телефона и удалять их.\n\nПри добавлении вы можете выбрать их роль - владелец, пользователь или гость.',
        appFeatures6Title: 'Добавление устройств',
        appFeatures6Description: 'Вы за пару минут можете добавлять новые Zigbee устройства в приложении.\n\nПросто включите режим добавления устройств и подключите новые устройства.',
        appFeatures7Title: 'Десктопное управление',
        appFeatures7Description: 'В версии приложения для компьютера и планшета вы можете видеть расположение устройств на плане этажа и управлять ими.',

        // YourCapabilities
        yourCapabilitiesTitle: 'Наши преимущества',

        capability1Title: 'Интеграции со Сторонними Сервисами',
        capability1Description: 'Все устройсвта из нашего приложения можно пернести в другие сервисы, такие как Яндекс Алиса, Маруся от ВК, Сбер Салют, Google Home и Apple Home.',

        capability2Title: 'Проводные и Беспроводные Системы',
        capability2Description1: 'Если у вас квартира с отделкой, то мы сможем установить вам наше приложение и подключить к нему беспроводные устройства.',
        capability2Description2: 'А если у вас дом с большой территорией и большим количеством утсройств, то мы можем установить вам проводную систему умного дома. И подключить все устройства к нашему приложению.',

        capability3Title: 'Удобное и Современное Приложение',
        capability3Description: 'Наше приложение не только красивое, но оно также разработано с учетом удобства пользователя, обеспечивая легкость управления умным домом.',

        offerAdvantages: 'Преимущества',
        offerPeculiarities: 'Особенности',
        offer1Title: 'Беспроводные решения для умного дома',
        offer1Advantages: 'Идеальный вариант для квартир и помещений с уже завершенной отделкой. Простота установки без необходимости прокладывания проводов.',
        offer1Peculiarities: 'Быстрое подключение и настройка, возможность легкого добавления устройств в будущем.',
        offer2Title: 'Проводные системы для умного дома',
        offer2Advantages: 'Подходит для больших домов на стадии строительства, где можно прокладывать провода. Обеспечивает более высокую надежность и масштабируемость.',
        offer2Peculiarities: 'Установка мощного контроллера и нашей уникальной системы управления. Интеграция с беспроводными устройствами для расширения функциональности.',

        // ComparisonWithOthers
        comparisonWithOthersTitle: 'Сравнение с другими системами умного дома',

        comparison1Title: 'Яндекс Алиса, Сбер Салют, Google Assistant, Apple Home',
        comparison1Description1: 'Системы умного дома от крупных компаний, таких как Яндекс Алиса, Сбер Салют и Google Assistant, имеют свои ограничения. Одна из главных проблем заключается в том, что они не полностью интегрированы друг с другом. Например, устройства из одной системы нельзя напрямую добавить в другую. Пользователям приходится вручную добавлять каждое устройство в каждую систему, что может быть очень трудоемким процессом.',
        comparison1Description2: 'Более того, некоторые системы могут поддерживать только определенные типы устройств, такие как Wi-Fi устройства от конкретных производителей, но не поддерживают другие технологии, например, Zigbee.',
        comparison1Description3: 'Наше решение предлагает интеграцию с различными сторонними сервисами, позволяя пользователям управлять своими устройствами как в нашем приложении, так и в приложениях других сервисов. Таким образом, пользователи получают возможность использовать все преимущества умного дома без необходимости вручную добавлять каждое устройство в каждую систему.',

        comparison2Title: 'Home Assistant',
        comparison2Description1: 'Если сравнивать нашу систему с HomeAssistant, то стоит отметить, что для работы с HomeAssistant требуется знание программирования. Процесс создания полноценной системы и добавления устройств может занять много времени, а результат может вас разочаровать из-за сложности использования и не самого интуитивного интерфейса.',
        comparison2Description2: 'Мы предлагаем решение, которое позволяет быстро подключать и настраивать беспроводные устройства, интегрируя их с различными сторонними системами, такими как Яндекс, ВКонтакте, Google и Сбербанк. Это означает, что вы можете легко управлять всеми своими устройствами через одно удобное и красивое приложение.',

        comparison3Title: 'Другие системы умного дома',
        comparison3Description1: 'Большинство систем умного дома от небольших компаний предлагают приложения, которые не всегда отличаются высоким качеством дизайна и функциональностью. Мы же предлагаем не только красивое и современное приложение, но и ряд преимуществ:',
        comparison3Description2: '- Интеграция с наиболее популярными системами умного дома и их умными колонками.',
        comparison3Description3: '- Возможность добавления устройств, работающих по протоколу Zigbee, что расширяет возможности управления вашим домом.',
        comparison3Description4: '- Многофункциональное приложение, доступное на всех основных операционных системах, а также возможность использования его прямо в браузере на компьютере.',
        comparison3Description5: '- Постоянная работа над улучшением приложения, добавлением новых функций, устройств и разработкой инновационных продуктов.',
        comparison3Description6: 'Кроме того, если у вас дом с большой территорией, мы можем предложить установку проводной системы умного дома. Все подключенные устройства будут доступны в нашем приложении GETHOME и могут быть интегрированы в сторонние сервисы умных домов.',

        // PricesBlock
        pricesTitle: 'Тарифы',
        priceFrequency: '/мес',
        priceFrequencyOneTime: 'единоразово',

        zigbeeSmallDescription: 'Возможность подключить до 10 Zigbee-устройств',
        zigbeeSmallPrice: '990₽',
        zigbeeMediumDescription: 'Возможность подключить до 50 Zigbee-устройств',
        zigbeeMediumPrice: '1990₽',
        zigbeeLargeDescription: 'Возможность подключить до 200 Zigbee-устройств',
        zigbeeLargePrice: '3990₽',

        priceSupportInfo: 'Во всех тарифах вы можете уточнить у нас, есть ли устройство, которое вы хотите подключить, в списке поддерживаемых. Если его нет, мы добавим его в список на подключение к нашей системе и сообщим вам, когда начнем его поддерживать. Обычно добавление поддержки нового устройства занимает не больше недели.',

        pricesIntegrationsDescription: 'Интеграция с любой сторонней системой (Яндекс, Сбер, ВК, Google) кроме Apple Home',
        pricesIntegrationsPrice: '990₽',
        pricesAppleHomeDescription: 'Интеграция с Apple Home',
        pricesAppleHomePrice: '14990₽',
        pricesGetHomeProDescription: 'Премиум тариф, который расширяет возможности умного дома и приложения GETHOME',
        pricesGetHomeProPrice: '2990₽',

        // Form
        formTitle: "Оставьте заявку",
        formName: "Имя",
        formNamePlaceholder: "Ваше имя",
        formTelegram: "Telegram для связи (по желанию)",
        formEmailPlaceholder: "Ваш email",
        formPhone: "Телефон",
        formPhonePlaceholder: "Ваш телефон",
        formType: "Тип помещения",
        formTypePlaceholder: "Выберите...",
        formTypeApartment: "Квартира",
        formTypeHouse: "Дом",
        formTypeOffice: "Офис",
        formLocation: "Город и страна",
        formLocationPlaceholder: "Город и страна дома",
        formComment: "Комментарий (по желанию)",
        formCommentPlaceholder: "Ваш комментарий",
        formButton: "Отправить",
    },
    en: {
        // LandingContainer
        title: 'GETHOME - Smart Home System',

        loginButtonText: 'Login',
        ctaButtonText: 'Get in touch',
        transformYourHomeTitle: <>Smart Home<br/>transformation<br/>you can trust</>,
        transformYourHomeSubtitle: 'Comfort and Security at its best',
        transformYourHomeButtonText: '✦ Smart Home start here!',

        scrollColorChangeText1: 'GETHOME - your smart home turnkey solution.',
        scrollColorChangeText2: 'Make your home smarter with our app that allows you to control all devices through wired and wireless technologies. We guarantee compatibility and security of all devices. Convenience and ease of use is what makes our solution perfect for your smart home.',
        scrollColorChangeText3: 'Enhance your home with GETHOME and enjoy the comfort and security that a smart home offers.',

        // FeaturesBlock
        features1: 'Control every aspect of your home from anywhere in the world',
        features2: '24/7 peace of mind with intrusion protection that alerts you to suspicious activities and ensures your home is secure',
        features3: 'Reduce your energy bills with intelligent energy management system',
        features4: 'Control your smart home with voice commands for maximum convenience',
        features5: 'Protect your home from unexpected events with our leak and smoke prevention solutions',
        features6: 'Maintain the perfect microclimate in your home with automatic temperature and humidity control system',

        // AppFeatures
        titleFeatures: 'Features',
        titleApp: 'of the app',
        appFeatures1Title: 'Device control',
        appFeatures1Description: 'Convenient control of all your home devices with division by floors, rooms and device types.\n\nReal-time display of device states and sensor data.',
        appFeatures2Title: 'Main screen',
        appFeatures2Description: 'Convenient main screen with the necessary information about your home, rooms and devices.\n\nAbility to turn off all lighting or all devices in the house with one click.',
        appFeatures3Title: 'Rooms',
        appFeatures3Description: 'From the main screen, you can turn off all lighting or all devices in the room with one click.\n\nOr go to the room to control the devices in it.',
        appFeatures4Title: 'Favorite devices',
        appFeatures4Description: 'You can add the most important devices to favorites and get quick access to them on the main screen.',
        appFeatures5Title: 'Users',
        appFeatures5Description: 'You can easily add users to the application by phone number and delete them.\n\nWhen adding, you can choose their role - owner, user or guest.',
        appFeatures6Title: 'Adding devices',
        appFeatures6Description: 'You can add new Zigbee devices in the application in a couple of minutes.\n\nJust turn on the device adding mode and connect new devices.',
        appFeatures7Title: 'Desktop control',
        appFeatures7Description: 'In the application version for computer and tablet, you can see the location of devices on the floor plan and control them.',

        // YourCapabilities
        yourCapabilitiesTitle: 'Our Capabilities',

        capability1Title: 'Integrations with Third-Party Services',
        capability1Description: 'All devices from our application can be transferred to other services, such as Google Assistant, Apple Home, Amazon Alexa and others.',

        capability2Title: 'Wired and Wireless Systems',
        capability2Description1: 'If you have an apartment with finishing, then we can install our application for you and connect wireless devices to it.',
        capability2Description2: 'And if you have a house with a large territory and a large number of devices, then we can install a wired smart home system for you. And connect all devices to our application.',

        capability3Title: 'Convenient and Modern Application',
        capability3Description: 'Our application is not only beautiful, but it is also designed with user convenience in mind, providing ease of smart home management.',

        offerAdvantages: 'Advantages',
        offerPeculiarities: 'Features',
        offer1Title: 'Wireless solutions for smart home',
        offer1Advantages: 'The perfect solution for apartments and finished rooms. Easy installation without the need to lay wires.',
        offer1Peculiarities: 'Quick connection and setup, the ability to easily add devices in the future.',
        offer2Title: 'Wired systems for smart home',
        offer2Advantages: 'Suitable for large houses under construction, where you can lay wires. Provides higher reliability and scalability.',
        offer2Peculiarities: 'Installation of a powerful controller and our unique control system. Integration with wireless devices to expand functionality.',

        // ComparisonWithOthers
        comparisonWithOthersTitle: 'Comparison with other smart home systems',

        comparison1Title: 'Google Assistant, Apple Home, Amazon Alexa',
        comparison1Description1: 'Smart home systems from large companies such as Google Assistant, Apple Home and Amazon Alexa have their limitations. One of the main problems is that they are not fully integrated with each other. For example, devices from one system cannot be directly added to another. Users have to manually add each device to each system, which can be a very time-consuming process.',
        comparison1Description2: 'Moreover, some systems may only support certain types of devices, such as Wi-Fi devices from specific manufacturers, but do not support other technologies, such as Zigbee.',
        comparison1Description3: 'Our solution offers integration with various third-party services, allowing users to control their devices in our application as well as in the applications of other services. Thus, users get the opportunity to use all the benefits of a smart home without having to manually add each device to each system.',

        comparison2Title: 'Home Assistant',
        comparison2Description1: 'Comparing our system with HomeAssistant, it is worth noting that working with HomeAssistant requires programming knowledge. The process of creating a full-fledged system and adding devices can take a lot of time, and the result may disappoint you due to the complexity of use and not the most intuitive interface.',
        comparison2Description2: 'We offer a solution that allows you to quickly connect and configure wireless devices, integrating them with various third-party systems such as Google, Apple, Amazon and others. This means that you can easily control all your devices through one convenient and beautiful application.',

        comparison3Title: 'Other smart home systems',
        comparison3Description1: 'Most smart home systems from small companies offer applications that do not always stand out for their high-quality design and functionality. We offer not only a beautiful and modern application, but also a number of advantages:',
        comparison3Description2: '- Integration with the most popular smart home systems and their smart speakers.',
        comparison3Description3: '- The ability to add Zigbee protocol devices, which expands the capabilities of controlling your home.',
        comparison3Description4: '- A multifunctional application available on all major operating systems, as well as the ability to use it directly in the browser on your computer.',
        comparison3Description5: '- Continuous work on improving the application, adding new features, devices and developing innovative products.',
        comparison3Description6: 'In addition, if you have a house with a large territory, we can offer the installation of a wired smart home system. All connected devices will be available in our GETHOME application and can be integrated into third-party smart home services.',

        // PricesBlock
        pricesTitle: 'Prices',
        priceFrequency: '/month',
        priceFrequencyOneTime: 'one-time',

        zigbeeSmallDescription: 'Ability to connect up to 10 Zigbee devices',
        zigbeeSmallPrice: '$19',
        zigbeeMediumDescription: 'Ability to connect up to 50 Zigbee devices',
        zigbeeMediumPrice: '$39',
        zigbeeLargeDescription: 'Ability to connect up to 200 Zigbee devices',
        zigbeeLargePrice: '$79',

        priceSupportInfo: 'In all tariffs, you can check with us if the device you want to connect is in the list of supported devices. If it is not, we will add it to the list to connect to our system and inform you when we start supporting it. Usually adding support for a new device takes no more than a week.',

        pricesIntegrationsDescription: 'Integration with any third-party system (Google, Amazon) except Apple Home',
        pricesIntegrationsPrice: '$19',
        pricesAppleHomeDescription: 'Integration with Apple Home',
        pricesAppleHomePrice: '$299',
        pricesGetHomeProDescription: 'Premium tariff that expands the capabilities of the smart home and GETHOME application',
        pricesGetHomeProPrice: '$59',

        // Form
        formTitle: "Get in touch",
        formName: "Name",
        formNamePlaceholder: "Your name",
        formTelegram: "Telegram for communication (optional)",
        formEmailPlaceholder: "Your email",
        formPhone: "Phone",
        formPhonePlaceholder: "Your phone",
        formType: "Type of premises",
        formTypePlaceholder: "Select...",
        formTypeApartment: "Apartment",
        formTypeHouse: "House",
        formTypeOffice: "Office",
        formLocation: "City and country",
        formLocationPlaceholder: "City and country of the house",
        formComment: "Comment (optional)",
        formCommentPlaceholder: "Your comment",
        formButton: "Send",
    },
};

export default translations;
