
const translations = {
    ru: {
        alertDeviceName: 'Введите название устройства',
        lastUpdate: 'Последнее обновление',
        deviceName: 'Название устройства',
        selectRoom: 'Выберите комнату',
        leftDistance: 'Растояние слева',
        topDistance: 'Растояние сверху',
        cancel: 'Отмена',
        save: 'Сохранить',
        deleteZigbeeDevice: 'Удалить Zigbee-устройство',

        // statuses
        stateKey: 'Статус',
        curtainClosed: 'Закрыты',
        curtainOpened: 'Открыты',
        smokeNo: 'Нет дыма',
        smokeYes: 'Есть дым',
        on: 'Вкл',
        off: 'Выкл',

        batteryKey: 'Заряд батареи',

        batteryLowKey: 'Низкий заряд батареи',
        yes: 'Да',
        no: 'Нет',

        temperatureKey: 'Температура',
        humidityKey: 'Влажность',
        pressureKey: 'Давление',
        pressureValue: 'мм рт. ст.',

        contactKey: 'Контакт',
        contactOpened: 'Открыт',
        contactClosed: 'Закрыт',

        actionKey: 'Последнее действие',
        actionSingle: 'Одиночное нажатие',
        actionDouble: 'Двойное нажатие',
        actionHold: 'Удержание',

        occupancyKey: 'Движение',
        occupancyYes: 'Есть',
        occupancyNo: 'Нет',

        waterLeakKey: 'Протечка воды',
        waterLeakYes: 'Есть',
        waterLeakNo: 'Нет',

        deviceTemperatureKey: 'Температура устройства',
        illuminationKey: 'Освещенность',
        linkqualityKey: 'Качество связи',

        powerKey: 'Мощность',
        powerValue: 'Вт',

        currentKey: 'Ток',
        currentValue: 'А',

        consumerConnectedKey: 'Подключен к электросети',

        chargingKey: 'Зарядка',
        chargingYes: 'Идет',
        chargingNo: 'Не идет',

        powerSourceKey: 'Источник питания',
        powerSourceBattery: 'Батарея',
        powerSourceDC: 'От сети',

        smokeDensityKey: 'Концентрация дыма',
        smokeDensityDbmKey: 'Концентрация дыма',
    },
    en: {
        alertDeviceName: 'Enter device name',
        lastUpdate: 'Last update',
        deviceName: 'Device name',
        selectRoom: 'Select room',
        leftDistance: 'Left distance',
        topDistance: 'Top distance',
        cancel: 'Cancel',
        save: 'Save',
        deleteZigbeeDevice: 'Delete Zigbee device',

        // statuses
        stateKey: 'Status',
        curtainClosed: 'Closed',
        curtainOpened: 'Opened',
        smokeNo: 'No smoke',
        smokeYes: 'Smoke',
        on: 'On',
        off: 'Off',

        batteryKey: 'Battery level',

        batteryLowKey: 'Low battery',
        yes: 'Yes',
        no: 'No',

        temperatureKey: 'Temperature',
        humidityKey: 'Humidity',
        pressureKey: 'Pressure',
        pressureValue: 'mm Hg',

        contactKey: 'Contact',
        contactOpened: 'Opened',
        contactClosed: 'Closed',

        actionKey: 'Last action',
        actionSingle: 'Single press',
        actionDouble: 'Double press',
        actionHold: 'Hold',

        occupancyKey: 'Occupancy',
        occupancyYes: 'Yes',
        occupancyNo: 'No',

        waterLeakKey: 'Water leak',
        waterLeakYes: 'Yes',
        waterLeakNo: 'No',

        deviceTemperatureKey: 'Device temperature',
        illuminationKey: 'Illumination',
        linkqualityKey: 'Link quality',

        powerKey: 'Power',
        powerValue: 'W',

        currentKey: 'Current',
        currentValue: 'A',

        consumerConnectedKey: 'Connected to power',

        chargingKey: 'Charging',
        chargingYes: 'Yes',
        chargingNo: 'No',

        powerSourceKey: 'Power source',
        powerSourceBattery: 'Battery',
        powerSourceDC: 'From network',

        smokeDensityKey: 'Smoke density',
        smokeDensityDbmKey: 'Smoke density',
    },
};

export default translations;
