
const MENU = {
  PAGES: {
    HOME: 'home',
    CONTROLS: 'controls',
    SETTINGS: 'settings',
  },
}

const DEFAULT_PAGE = MENU.PAGES.CONTROLS

module.exports = {
  MENU,
  DEFAULT_PAGE,
}
