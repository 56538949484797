
const translations = {
    ru: {
        devices: 'Устройств',
        on: 'Вкл',
        light: 'Свет',
    },
    en: {
        devices: 'Devices',
        on: 'On',
        light: 'Light',
    },
};

export default translations;
