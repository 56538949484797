import React, { useContext, useEffect, useState, useCallback, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getHomes } from '../../api';
import { identifyProvider, getServiceName } from './utils';
import InfoPopup from '../../../control/components/common/InfoPopup';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/Home.translations.js';

import styles from './styles/Home.module.css';

// TODO: надо сделать getServiceName на английском

const Home = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const location = useLocation();
  const [houses, setHouses] = useState([]);
  const currentQuery = location.search;
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (location.search) {
      const query = location.search;
      const json = {};
      query.replace("?", "").split("&").forEach(pair => {
        const [key, value] = pair.split("=");
        json[key] = decodeURIComponent(value || "");
      });

      if (json) {
        setProvider(identifyProvider(json));
        setIsShowInfo(true);
      }
    }
  }, [location]);

  const getHouses = useCallback(async () => {
    try {
      const { data } = await getHomes();
      if (data?.homes) {
        setHouses(data.homes);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getHouses();
  }, [getHouses]);

  const getHouseImage = useCallback(homeId => {
    const defaultHomeImage = "smart_home_1.png";

    const testHomes = [
      {
        homeIds : [],
        image: "test_home_1.jpg",
      },
    ];

    const home = testHomes.find(home => home.homeIds.includes(homeId));
    return home ? home.image : defaultHomeImage;
  }, []);

  return (
    <div>
      <h1 className={styles.title}>{t.yourHomes}</h1>
      {houses?.length > 0 && (
        <div className={styles.houseContainer}>
          {houses.map(house => (
            <Link key={house.personalId} to={`/app/house/${house.personalId}${currentQuery}`} className={styles.houseCard}>
              <img src={`/images/home/${getHouseImage(house.id)}`} alt={house.name} />
              <h3 className={styles.houseName}>{house.name}</h3>
            </Link>
          ))}
        </div>
      )}
      {houses.length === 0 && (
        <p className={styles.noHouses}>{t.noHomes}</p>
      )}
      {isShowInfo && (
        <InfoPopup isShouldCloseOnOutsideClick={false} onClose={() => { setIsShowInfo(false); }}>
          <h2 className={styles.slideTitle}>Связать дом {getServiceName(provider)}</h2>
          <p className={styles.slideContent}>Для связи вашего дома {getServiceName(provider)} выберите дом, который хотите связать, и на открывшейся странице нажмите на кнопку "Связать дом {getServiceName(provider)}"</p>
        </InfoPopup>
      )}
    </div>
  );
};

export default memo(Home);
