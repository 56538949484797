
const translations = {
    ru: {
        title: 'Подключение Telegram',
        subtitle: 'Подключите свой аккаунт Telegram, чтобы получать уведомления о событиях в вашем доме.',

        connected: 'Подключенный аккаунт',
        notConnected: 'Аккаунт не подключен',
        connectButton: 'Подключить аккаунт',
    },
    en: {
        title: 'Connect Telegram',
        subtitle: 'Connect your Telegram account to receive notifications about events in your home.',

        connected: 'Connected account',
        notConnected: 'Account not connected',
        connectButton: 'Connect account',
    },
};

export default translations;
