
const ROLES = {
    OWNER: 'Owner',
    RESIDENT: 'Resident',
    GUEST: 'Guest',
}

const ROLES_NAME = {
    ru: {
        OWNER: 'Владелец',
        RESIDENT: 'Пользователь',
        GUEST: 'Гость',
    },
    en: {
        OWNER: 'Owner',
        RESIDENT: 'Resident',
        GUEST: 'Guest',
    },
}

const ROLES_ID = {
    OWNER: 1,
    RESIDENT: 2,
    GUEST: 3,
}

module.exports = {
    ROLES,
    ROLES_NAME,
    ROLES_ID,
}
