const DEFAULT_NOTIFICATIONS = {
  sensors: {
    push: true,
    telegram: true,
    sms: false,
  },
  batteryLow: {
    push: true,
    telegram: true,
    sms: false,
  },
  securityToggle: {
    push: true,
    telegram: true,
    sms: false,
  },
  securitySensor: {
    push: true,
    telegram: true,
    sms: false,
  },
};

const TYPE_NOTIFICATION_NAMES = {
  ru: {
    sensors: 'Срабатывание датчиков протечки и задымления',
    batteryLow: 'Низкий заряд батареи устройств',
    securityToggle: 'Включение и выключение охраны',
    securitySensor: 'Срабатывание датчиков движения и открытия дверей при включенной охране',
    // hubDisconnected: 'Отключение хаба',
    // hubUnreachable: 'Недоступность хаба и устройств',
  },
  en: {
    sensors: 'Leak and smoke sensors activation',
    batteryLow: 'Low battery charge of devices',
    securityToggle: 'Security system activation and deactivation',
    securitySensor: 'Motion and door opening sensors activation with security system on',
    // hubDisconnected: 'Hub disconnected',
    // hubUnreachable: 'Hub and devices unreachable',
  },
};

module.exports = {
  DEFAULT_NOTIFICATIONS,
  TYPE_NOTIFICATION_NAMES,
}
