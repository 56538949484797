import React, { useContext, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import { saveUserData } from '../../utils/api';
import { ClipLoader } from 'react-spinners';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/UserSettings.translations.js';

import styles from './styles/settings.module.css';
import pageStyles from './styles/UsersSettings.module.css';

const UserSettings = ({ isMobile, onClose, currentUser, fetchCurrentUser }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [userName, setUserName] = useState(currentUser.name || '');
    const [userAvatar, setUserAvatar] = useState(currentUser.avatar || 'avatar1');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const renderAvatars = useCallback(() => {
        const avatars = [];
        for (let i = 1; i <= 16; i++) {
            avatars.push(
                <div className={userAvatar === `avatar${i}` ? pageStyles.settingsAvatarActive : pageStyles.settingsAvatarUnactive}>
                    <div
                        key={i}
                        className={pageStyles.settingsAvatar}
                        onClick={() => setUserAvatar(`avatar${i}`)}
                    >
                        <img src={`/images/avatars/avatar${i}.jpg`} alt={`avatar${i}`} className={pageStyles.settingsAvatarImg} />
                    </div>
                </div>
            );
        }

        return (
            <div className={pageStyles.settingsAvatars}>
                {avatars}
            </div>
        );
    }, [userAvatar]);

    const saveUser = useCallback(async () => {
        setMessage('');
        setIsLoading(true);

        const { data } = await saveUserData({ name: userName, avatar: userAvatar });
        if (data.success) {
            setMessage(t.successMessage);
            fetchCurrentUser();
        } else {
            setMessage(t.errorMessage);
        }

        setIsLoading(false);
    }, [userName, userAvatar, t, fetchCurrentUser]);

    return (
        <div className={styles.container}>
            {isMobile && <BackButton onClick={onClose} />}
            <div className={styles.formGroup}>
                <h3 className={styles.title}>{t.title}</h3>

                <div className={`${styles.block} ${pageStyles.userSettingsBlock}`}>
                    <div className={pageStyles.editBox}>
                        <input
                            value={userName}
                            onChange={e => setUserName(e.target.value)}
                            placeholder={t.namePlaceholder}
                            required
                            className={pageStyles.slidePhoneNumber}
                        />
                    </div>
                    {renderAvatars()}
                    {message && <p className={pageStyles.messageText}>{message}</p>}
                    {!isLoading && (
                        <button className={pageStyles.saveButton} onClick={saveUser}>{t.saveButton}</button>
                    )}
                    {isLoading && (
                        <div className={pageStyles.loaderBox}>
                            <ClipLoader color="#4A90E2" size={25} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

UserSettings.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    fetchCurrentUser: PropTypes.func.isRequired,
};

export default memo(UserSettings);
