
const translations = {
    ru: {
        title: 'Настройки языка',
        subtitle: 'Выберите язык интерфейса. Он будет настроен только для этого аккаунта и этого устройства. Других пользователей дома это не затронет.',

        chooseLanguage: 'Выберите язык',
    },
    en: {
        title: 'Language settings',
        subtitle: 'Choose the interface language. It will be configured only for this account and this device. Other users in the house will not be affected.',

        chooseLanguage: 'Choose language',
    },
};

export default translations;
