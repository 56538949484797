
const translations = {
    ru: {
        title: 'Настройки Zigbee',
        connect: 'Возможность подключения новых Zigbee-устройств:',

        // devices
        connectedDevices: 'Подключенные устройства',
        noDevices: 'Ни одно устройство не подключено',
    },
    en: {
        title: 'Zigbee settings',
        connect: 'Ability to connect new Zigbee devices:',

        // devices
        connectedDevices: 'Connected devices',
        noDevices: 'No devices connected',
    },
};

export default translations;
