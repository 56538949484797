import React, { useEffect, useState, useCallback, useMemo, useRef, memo } from 'react';
import { Route, Routes, Navigate, useParams, useLocation } from 'react-router-dom';
import { MENU, DEFAULT_PAGE } from '../constants/menu';
import { getControlData } from './utils/api';
import { useWebSocket } from './utils/useWebSocket';
import { EVENTS } from '../constants/socket';
import { SECURITY_TIMEOUT_SECONDS } from '../constants/security';

import PwaInstruction from './components/instructions/PwaInstruction';
import PushNotificationsManager from './components/instructions/PushNotificationsManager';

import MenuButton from './components/common/MenuButton';
import MenuPoint from './components/common/MenuPoint';

import HomeContainer from './components/main/HomeContainer';
import ControlsContainer from './components/main/ControlsContainer';
import SettingsContainer from './components/main/SettingsContainer';

import RoomPage from './components/pages/RoomPage';

import styles from './styles/Control.module.css';

function Control() {
  const { personalId } = useParams();
  const timers = useRef({});
  const location = useLocation();

  const [backgroundColor, setBackgroundColor] = useState('#5a9bd4');

  const [selectedPage, setSelectedPage] = useState(DEFAULT_PAGE);
  const [selectedPageNum, setSelectedPageNum] = useState(1);
  const [isShowIOSInstruction, setIsShowIOSInstruction] = useState(false);
  const [hasShownPushNotification, setHasShownPushNotification] = useState(
    localStorage.getItem('hasShownPushNotificationInstruction')
  );

  const [homeData, setHomeData] = useState({});
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [devices, setDevices] = useState([]);
  const [securityActivationTime, setSecurityActivationTime] = useState(null);

  useEffect(() => {
    document.body.style.background = backgroundColor;
  }, [backgroundColor]);

  const isIOS = useMemo(() => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream, []);
  const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
  const isIOSLandscape = useMemo(() => isIOS && isLandscape, [isIOS, isLandscape]);
  const isPWA = useMemo(() => 'standalone' in window.navigator && window.navigator.standalone, []);

  useEffect(() => {
    const handleOrientationChange = e => setIsLandscape(e.matches);

    const mediaQuery = window.matchMedia("(orientation: landscape)");
    mediaQuery.addListener(handleOrientationChange);

    // Удаляем слушатель при размонтировании компонента
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (isIOS && !isPWA) {
      setIsShowIOSInstruction(true);
    } else {
      setIsShowIOSInstruction(false);
    }
  }, [isIOS, isPWA]);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const selectedPath = pathParts[3];

    // TODO: вынести цвета в константы

    if (selectedPath === MENU.PAGES.HOME) {
      setBackgroundColor('#8289bf');
      setSelectedPageNum(0);
      setSelectedPage(MENU.PAGES.HOME);
    } else if (selectedPath === MENU.PAGES.CONTROLS) {
      setBackgroundColor('#5a9bd4');
      setSelectedPageNum(1);
      setSelectedPage(MENU.PAGES.CONTROLS);
    } else if (selectedPath === MENU.PAGES.SETTINGS) {
      setBackgroundColor('#000000');
      setSelectedPageNum(2);
      setSelectedPage(MENU.PAGES.SETTINGS);
    } else {
      setSelectedPageNum(null);
      setSelectedPage(null);
    }
  }, [location, setSelectedPageNum, setBackgroundColor]);

  const menuProps = {
    selectedPage,
    setSelectedPage,
    setSelectedPageNum,
  };

  const fetchControlData = useCallback(async () => {
    // Получаем данные об устройствах и комнатах при загрузке приложения
    const { data } = await getControlData(personalId);
    console.log('fetchControlData data:', data);
    // TODO: проверять, есть ли у пользователя дом с таким personalId

    if (data) {
      setFloors(data.floors.map(floor => ({
        id: floor.id,
        name: floor.floorName,
        floorImage: floor.floorImage,
      })));

      setRooms(data.rooms.map(room => ({
        id: room.id,
        name: room.roomName,
        floorId: room.floorId,
      })));

      setDevices(data.devices.map(device => {
        const deviceType = device.deviceType;
        let statusText = device.deviceStateJson;
        if (deviceType === 'one_button_switch') {
          statusText.action = null;
        } else if (deviceType === 'ir_remote') {
          statusText = { learned_ir_code: null };
        }

        return {
          id: device.id,
          deviceName: device.deviceName,
          deviceType,
          status: device.deviceStateJson?.state !== undefined ? device.deviceStateJson.state : null,
          statusText,
          roomId: device.roomId,
          roomName: data.rooms.find(room => (room.id === device.roomId))?.roomName || 'Без комнаты',
          floorId: data.rooms.find(room => (room.id === device.roomId))?.floorId,
          positionLeft: device.positionLeft,
          positionTop: device.positionTop,
          isFavorite: device.isFavorite,
          isZigbee: device.zigbeeName !== null,
          updatedAt: device.updated_at,
          remoteButtons: device.remoteButtons || null,
        };
      }));

      const homeData = data.home;
      homeData.permissions = data.permissions || {};
      setHomeData(homeData);

      setSecurityActivationTime(data.home?.security_mode_activated_at ? new Date(data.home.security_mode_activated_at) : null);
    }
  }, [personalId]);

  const { current: resetDevicesStatus } = useRef(deviceId => {
    setDevices(devices => {
      const updatedDevices = [...devices];
      const index = updatedDevices.findIndex(device => device.id === deviceId);
      if (index >= 0) {
        updatedDevices[index] = {
          ...updatedDevices[index],
          status: false,
          statusText: { ...updatedDevices[index].statusText, action: null },
        };
      }
      return updatedDevices;
    });
  });

  const { current: resetIsIgnoreNewStatus } = useRef(deviceId => {
    setDevices(devices => {
      const updatedDevices = [...devices];
      const index = updatedDevices.findIndex(device => device.id === deviceId);
      if (index >= 0) {
        updatedDevices[index] = {
          ...updatedDevices[index],
          isIgnoreNewStatus: false,
        };
      }
      return updatedDevices;
    });
  });

  const setDeviceNewStatus = useCallback(({ deviceId, newStatus = null, newStatusText = null }) => {
    setDevices(devices => {
      const updatedDevices = [...devices];
      const index = updatedDevices.findIndex(device => device.id === deviceId);
      if (index >= 0) {
        const currentDevice = updatedDevices[index];

        if (currentDevice.isIgnoreNewStatus) {
          return updatedDevices;
        }

        updatedDevices[index] = {
          ...currentDevice,
          status: currentDevice.deviceType === 'one_button_switch' ? true : newStatus,
          statusText: newStatusText,
          isIgnoreNewStatus: currentDevice.deviceType === 'curtain' ? true : false,
        };

        // Если устройство - кнопка, то через 3 секунды сбрасываем ее состояние
        if (currentDevice.deviceType === 'one_button_switch') {
          if (timers.current[deviceId]) {
            clearTimeout(timers.current[deviceId]);
          }
          timers.current[deviceId] = setTimeout(() => {
            resetDevicesStatus(deviceId);
            delete timers.current[deviceId];
          }, 3000);
        }

        // Если устройство - штора, то 1.5 секунды не обновляем состояние
        if (currentDevice.deviceType === 'curtain') {
          setTimeout(() => {
            resetIsIgnoreNewStatus(deviceId);
          }, 1500);
        }
      }

      return updatedDevices;
    });
  }, [resetDevicesStatus, resetIsIgnoreNewStatus]);

  const handleMessage = useCallback(event => {
    const message = JSON.parse(event.data);

    // Обрабатываем сообщение
    switch (message.type) {
      case EVENTS.DEVICE_UPDATE:
        // Обновляем состояние устройства в приложении
        setDeviceNewStatus({
          deviceId: message.deviceId,
          newStatus: message.newStatus.state !== undefined ? message.newStatus.state : null,
          newStatusText: message.newStatus,
        });
        break;
      case EVENTS.UPDATE_HOME_DATA:
        fetchControlData();
        break;
      case EVENTS.ACTIVATE_SECURITY_MODE:
        setSecurityActivationTime(new Date(new Date().getTime() + SECURITY_TIMEOUT_SECONDS * 1000)); // текущее время + 15 секунд
        break;
      case EVENTS.DEACTIVATE_SECURITY_MODE:
        setSecurityActivationTime(null);
        break;
      case EVENTS.LEARNED_IR_CODE:
        setDeviceNewStatus({
          deviceId: message.deviceId,
          newStatusText: { learned_ir_code: message.learnedIrCode },
        });
        break;
      default:
        console.error('Unknown message type:', message.type);
    }
  }, [setDeviceNewStatus, fetchControlData]);

  const { ws } = useWebSocket(handleMessage, fetchControlData);

  const handleDeviceToggle = useCallback((deviceIds, newStatus, newStatusJson) => {
    // Отправляем запрос на обновление состояния устройства через WebSocket
    const ids = Array.isArray(deviceIds) ? deviceIds : [deviceIds];
    const message = JSON.stringify({
      type: EVENTS.DEVICE_UPDATE_STATUS,
      deviceIds: ids,
      newStatus: newStatus,
      newStatusJson: newStatusJson,
    });
    ws.send(message);

    // Обновляем состояние каждого устройства в приложении
    ids.forEach(deviceId => {
      setDeviceNewStatus({ deviceId, newStatus, newStatusText: newStatusJson });
      // TODO: надо перейти на newStatusJson (сейчас при отправке данных в виде JSON мы не обновим статус, да и не сможем нормально отправить состояние)
    });
  }, [setDeviceNewStatus, ws]);

  const handleAddToFavorites = useCallback((deviceId, isFavorite) => {
    const message = JSON.stringify({
      type: isFavorite ? EVENTS.REMOVE_FROM_FAVORITES : EVENTS.ADD_TO_FAVORITES,
      deviceId: deviceId,
      homeId: homeData.id,
    });
    ws.send(message);

    setDevices(devices => {
      return devices.map(device => {
        if (device.id === deviceId) {
          return { ...device, isFavorite: !isFavorite };
        }
        return device;
      });
    });
  }, [homeData, ws]);

  const roomsByFloor = useMemo(() => {
    return rooms.reduce((acc, room) => {
        if (!acc[room.floorId]) {
            acc[room.floorId] = [];
        }
        acc[room.floorId].push(room);
        return acc;
    }, {});
  }, [rooms]);

  const floorsWithRooms = useMemo(() => floors.filter(floor => roomsByFloor[floor.id]), [floors, roomsByFloor]);
  const showFloorNames = floorsWithRooms.length > 1;

  const roomAndFloorData = useMemo(() => {
    return {
      roomsByFloor,
      floorsWithRooms,
      showFloorNames,
    };
  }, [roomsByFloor, floorsWithRooms, showFloorNames]);

  const handlePushNotificationInstructionShown = useCallback(() => {
    localStorage.setItem('hasShownPushNotificationInstruction', 'true');
    setHasShownPushNotification(true);
  }, []);

  const handleSecurityToggle = useCallback(shouldActivate => {
    const message = JSON.stringify({
      type: shouldActivate ? EVENTS.ACTIVATE_SECURITY_MODE : EVENTS.DEACTIVATE_SECURITY_MODE,
      homeId: homeData.id,
    });

    ws.send(message);

    if (shouldActivate) {
      setSecurityActivationTime(new Date(new Date().getTime() + SECURITY_TIMEOUT_SECONDS * 1000)); // текущее время + 15 секунд
    } else {
      setSecurityActivationTime(null);
    }
  }, [homeData, ws]);

  return (
    <div className={styles.root}>
      <div className={styles.fons}>
        <div
          className={`${styles.fon4} ${styles.fonDefault}`}
          style={{
            opacity: backgroundColor === '#8289bf' && !isIOSLandscape ? 1 : 0,
          }}
        ></div>
        <div
          className={`${styles.fon2} ${styles.fonDefault}`}
          style={{
            opacity: backgroundColor === '#5a9bd4' && !isIOSLandscape ? 1 : 0,
          }}
        ></div>
        <div
          className={`${styles.fon5} ${styles.fonDefault}`}
          style={{
            opacity: backgroundColor === '#000000' && !isIOSLandscape ? 1 : 0,
          }}
        ></div>
      </div>
      {<div className={styles.main}>
        <Routes>
          <Route path={MENU.PAGES.HOME} element={<HomeContainer
            homeData={homeData}
            devices={devices}
            rooms={rooms}
            floors={floors}
            handleDeviceToggle={handleDeviceToggle}
            roomAndFloorData={roomAndFloorData}
            handleAddToFavorites={handleAddToFavorites}
            securityActivationTime={securityActivationTime}
            handleSecurityToggle={handleSecurityToggle}
          />} />
          <Route path={MENU.PAGES.CONTROLS} element={<ControlsContainer
            devices={devices}
            rooms={rooms}
            floors={floors}
            handleDeviceToggle={handleDeviceToggle}
            roomAndFloorData={roomAndFloorData}
            handleAddToFavorites={handleAddToFavorites}
          />} />
          <Route path={MENU.PAGES.SETTINGS} element={<SettingsContainer
            personalId={personalId}
            devices={devices}
            handleDeviceToggle={handleDeviceToggle}
            roomAndFloorData={roomAndFloorData}
            handleAddToFavorites={handleAddToFavorites}
          />} />

          <Route path="room/:roomId/*" element={<RoomPage
            devices={devices}
            rooms={rooms}
            floors={floors}
            handleDeviceToggle={handleDeviceToggle}
            roomAndFloorData={roomAndFloorData}
            handleAddToFavorites={handleAddToFavorites}
          />} />

          <Route path="*" element={<Navigate to={DEFAULT_PAGE} />} />
        </Routes>
      </div>}
      <div className={styles.menu}>
        <MenuButton {...menuProps} type={MENU.PAGES.HOME} pageNum={0} />
        <MenuButton {...menuProps} type={MENU.PAGES.CONTROLS} pageNum={1} />
        <MenuButton {...menuProps} type={MENU.PAGES.SETTINGS} pageNum={2} />
        {selectedPageNum !== null && <MenuPoint buttonsCount={Object.keys(MENU.PAGES).length} selectedPageNum={selectedPageNum} />}
      </div>

      {isShowIOSInstruction && <PwaInstruction setIsShowIOSInstruction={setIsShowIOSInstruction} />}
      <PushNotificationsManager
        isShow={isPWA && !(hasShownPushNotification === 'true')}
        personalId={personalId}
        onInstructionShown={handlePushNotificationInstructionShown}
      />
    </div>
  );
}

export default memo(Control);
