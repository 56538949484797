
const MENU_SETTINGS = {
  // Home
  ZIGBEE: 'zigbee',
  USERS: 'users',
  USER: 'user',
  PERMISSIONS: 'permissions',

  // Users
  NOTIFICATIONS: 'notifications',
  TELEGRAM: 'telegram',
  LANGUAGE: 'language',
}

module.exports = {
  MENU_SETTINGS,
}
