
// Определение типов устройств
export const DEVICE_TYPES = {
    output: [
        { light: 'Свет' },
        { water: 'Вода' },
        { socket: 'Розетка' },
        { curtain: 'Шторы' },
        { switch: 'Кнопочный выключатель' },
        { ir_remote: 'Пульт' },
    ],
    input: [
        { motion: 'Датчик движения' },
        { magnet: 'Магнит' },
        { switch: 'Выключатель' },
        { water_leak: 'Датчик протечки' },
        { smoke: 'Датчик дыма' },
        { temperature_humidity: 'Датчик температуры и влажности' },
        { temperature: 'Датчик температуры' },
        { one_button_switch: 'Выключатель с одной кнопкой' },
    ],
}

// Создание массива из всех ключей (light, water, motion, etc.)
export const DEVICE_TYPE_KEYS = [
    ...DEVICE_TYPES.output.map(obj => Object.keys(obj)[0]),
    ...DEVICE_TYPES.input.map(obj => Object.keys(obj)[0])
]


export const BUTTONS = {
    BUTTONS_TYPES: {
        LIGHT: 'light',
        SOCKET: 'socket',
        WATER: 'water',
        SENSORS: 'sensors',
        CURTAIN: 'curtain',
        SWITCH: 'switch',
        IR_REMOTE: 'ir_remote',
    },
    BUTTONS_TEXT: {
        ru: {
            light: 'Свет',
            socket: 'Розетки',
            water: 'Вода',
            sensors: 'Датчики',
            curtain: 'Шторы',
            switch: 'Выключатели',
            ir_remote: 'Пульты',
        },
        en: {
            light: 'Light',
            socket: 'Sockets',
            water: 'Water',
            sensors: 'Sensors',
            curtain: 'Curtains',
            switch: 'Switches',
            ir_remote: 'Remotes',
        },
    },
}
