import React, { useContext, useCallback, useState, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { MENU_SETTINGS } from '../../../constants/menuSettings';
import { getCurrentUser } from '../../utils/api';
import { ChevronRight, ChevronRightCircle } from 'lucide-react';
import { ROLES } from '../../../constants/homeRoles';

import UserSettings from '../settings/UserSettings';
import UsersSettings from '../settings/UsersSettings';
import ZigbeeSettings from '../settings/ZigbeeSettings';
import PermissionsSettings from '../settings/PermissionsSettings';

import NotificationsSettings from '../settings/NotificationsSettings';
import TelegramSettings from '../settings/TelegramSettings';
import LanguageSettings from '../settings/LanguageSettings';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/SettingsContainer.translations.js';

import styles from './styles/SettingsContainer.module.css';

const MOBILE_WIDTH = 480;

const SettingsContainer = ({ personalId, devices, handleDeviceToggle, roomAndFloorData, handleAddToFavorites }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const [selectedSetting, setSelectedSetting] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);
  const [currentUser, setCurrentUser] = useState(null);

  const fetchCurrentUser = useCallback(async () => {
    const { data } = await getCurrentUser(personalId);
    if (data.user) {
      setCurrentUser(data.user);
    }
  }, [personalId]);

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleMenuClick = useCallback(setting => {
    setSelectedSetting(setting);
  }, []);

  const renderMenuButton = useCallback((label, setting) => (
    <button
      className={`${styles.button} ${selectedSetting === setting ? styles.active : ''}`}
      onClick={() => handleMenuClick(setting)}
    >
      {label}
    </button>
  ), [handleMenuClick, selectedSetting]);

  const renderAvatar = useCallback(avatar => {
    return `/images/avatars/${avatar || 'avatar1'}.jpg`;
  }, []);

  const renderUserMenuButton = useCallback(() => {
    return (
      <button className={styles.userButton} onClick={() => handleMenuClick(MENU_SETTINGS.USER)}>
        {!currentUser && <div className={styles.userBlock}>
          <div className={styles.userAvatarWait} />
          {t.loading}
        </div>}
        {currentUser && (<>
          <div className={styles.userBlock}>
            <img src={renderAvatar(currentUser.avatar)} alt="avatar" className={styles.userAvatar} />
            {currentUser?.name || `+${currentUser?.phone}`}
          </div>
          {selectedSetting !== MENU_SETTINGS.USER && <ChevronRight size={18} />}
          {selectedSetting === MENU_SETTINGS.USER && <ChevronRightCircle size={20} />}
        </>)}
      </button>
    );
  }, [currentUser, handleMenuClick, renderAvatar, selectedSetting, t]);

  const settingsProps = useMemo(() => ({
    isMobile,
    onClose: () => setSelectedSetting(null),
    personalId,
    devices,
    handleDeviceToggle,
    roomAndFloorData,
    handleAddToFavorites,
  }), [isMobile, personalId, devices, handleDeviceToggle, roomAndFloorData, handleAddToFavorites]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.sideBlock}>
          <p className={styles.title}>{t.title}</p>
        </div>
      </div>

      <div className={styles.container}>
        {(!isMobile || !selectedSetting) && <div className={styles.menu}>
          {renderUserMenuButton()}

          {currentUser?.roleName !== ROLES.GUEST && (<>
            <p className={styles.menuTitle}>{t.menuTitleHome}</p>
            {renderMenuButton(t.menuUsers, MENU_SETTINGS.USERS)}
            {renderMenuButton(t.menuZigbee, MENU_SETTINGS.ZIGBEE)}
            {renderMenuButton(t.menuPermissions, MENU_SETTINGS.PERMISSIONS)}

            <p className={styles.menuTitle}>{t.menuTitleUsers}</p>
            {renderMenuButton(t.menuNotifications, MENU_SETTINGS.NOTIFICATIONS)}
            {renderMenuButton(t.menuTelegram, MENU_SETTINGS.TELEGRAM)}
            {renderMenuButton(t.menuLanguage, MENU_SETTINGS.LANGUAGE)}
          </>)}

          {currentUser?.roleName === ROLES.GUEST && (<>
            <p className={styles.menuTitle}>{t.menuTitleHome}</p>
            {renderMenuButton(t.menuNotifications, MENU_SETTINGS.NOTIFICATIONS)}
            {renderMenuButton(t.menuTelegram, MENU_SETTINGS.TELEGRAM)}
            {renderMenuButton(t.menuLanguage, MENU_SETTINGS.LANGUAGE)}
          </>)}
        </div>}

        {selectedSetting && <div className={styles.content}>
          {selectedSetting === MENU_SETTINGS.USERS && <UsersSettings {...settingsProps} currentUser={currentUser} />}
          {selectedSetting === MENU_SETTINGS.ZIGBEE && <ZigbeeSettings {...settingsProps} />}
          {selectedSetting === MENU_SETTINGS.PERMISSIONS && <PermissionsSettings {...settingsProps} />}

          {selectedSetting === MENU_SETTINGS.NOTIFICATIONS && <NotificationsSettings {...settingsProps} />}
          {selectedSetting === MENU_SETTINGS.TELEGRAM && <TelegramSettings {...settingsProps} />}
          {selectedSetting === MENU_SETTINGS.LANGUAGE && <LanguageSettings {...settingsProps} />}

          {selectedSetting === MENU_SETTINGS.USER &&
            <UserSettings {...settingsProps} currentUser={currentUser} fetchCurrentUser={fetchCurrentUser} />
          }
        </div>}
      </div>
    </div>
  );
};

SettingsContainer.propTypes = {
  personalId: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  handleDeviceToggle: PropTypes.func.isRequired,
  roomAndFloorData: PropTypes.shape({}),
  handleAddToFavorites: PropTypes.func.isRequired,
};

SettingsContainer.defaultProps = {
  devices: [],
  roomAndFloorData: {},
};

export default memo(SettingsContainer);
