import React, { useContext, useRef, useState, useMemo, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { getActiveButtonType } from '../../utils/buttons';
import { BUTTONS } from '../../../constants/deviceTypes';

import ButtonGroup from '../common/ButtonGroup';
import ControlsDevices from '../common/ControlsDevices';
import RoomDevices from '../common/RoomDevices';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/ControlsContainer.translations.js';

import styles from './styles/ControlsContainer.module.css';

const ControlsContainer = ({ devices, rooms, floors, handleDeviceToggle, roomAndFloorData, handleAddToFavorites }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const defaultButton = BUTTONS.BUTTONS_TYPES.LIGHT;
  const [activeButton, setActiveButton] = useState(defaultButton);
  const [floorImg, setFloorImg] = useState(null);
  const [floorId, setFloorId] = useState(null);

  const floorBoxRef = useRef(null);
  const [floorImgWidth, setFloorImgWidth] = useState(600);
  const [imageLoaded, setImageLoaded] = useState(false);

  const updateFloorBoxWidth = useCallback(() => {
    if (floorBoxRef.current) {
      setFloorImgWidth(floorBoxRef.current.offsetWidth - 20);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateFloorBoxWidth);
    
    // Вызываем функцию, чтобы установить начальную ширину
    updateFloorBoxWidth();

    return () => {
      window.removeEventListener('resize', updateFloorBoxWidth);
    };
  }, [updateFloorBoxWidth, imageLoaded]);

  useEffect(() => {
    if (floors.length > 0 && !floorId) {
      const selectedFloorId = Number(localStorage.getItem('selectedFloorId'));
      if (floors.find(floor => floor.id === selectedFloorId)) {
        setFloorId(selectedFloorId);
      } else {
        setFloorId(floors[0].id);
      }
    }
  }, [floorId, floors]);

  useEffect(() => {
    const floor = floors.find(floor => floor.id === floorId);
    if (floor && floor.floorImage) {
      setFloorImg(`${process.env.REACT_APP_BASE_URL}/app/floor-image/${floorId}`);
    } else {
      setFloorImg(null);
    }
  }, [floors, floorId]);

  const allButtons = [
    BUTTONS.BUTTONS_TYPES.LIGHT,
    BUTTONS.BUTTONS_TYPES.SOCKET,
    BUTTONS.BUTTONS_TYPES.SENSORS,
    BUTTONS.BUTTONS_TYPES.WATER,
    BUTTONS.BUTTONS_TYPES.CURTAIN,
    BUTTONS.BUTTONS_TYPES.SWITCH,
  ];
  const allButtonsTexts = BUTTONS.BUTTONS_TEXT[language];

  const handleButtonClick = useCallback(button => {
    setActiveButton(button);
  }, []);

  const allFloorButtons = useMemo(() => floors.map(floor => floor.id), [floors]);
  const allFloorButtonsTexts = useMemo(() => {
    return floors.reduce((acc, floor) => {
      acc[floor.id] = floor.name;
      return acc;
    }, {});
  }, [floors]);

  const existingDeviceTypesForFloor = useCallback(targetFloorId => {
    const devicesForFloor = devices.filter(device => device.floorId === targetFloorId);
    return devicesForFloor.map(device => getActiveButtonType(device.deviceType));
  }, [devices]);

  const handleFloorButtonClick = useCallback(floorId => {
    const typesForNewFloor = existingDeviceTypesForFloor(floorId);
    setFloorId(floorId);
    localStorage.setItem('selectedFloorId', floorId);

    if (!typesForNewFloor.includes(activeButton)) {
      // Надо проверить есть ли дефолтный тип в новом списке девайсов
      setActiveButton(typesForNewFloor.includes(defaultButton) ? defaultButton : typesForNewFloor[0]);
      // setActiveButton(defaultButton);
    }
  }, [activeButton, defaultButton, existingDeviceTypesForFloor]);

  // roomsOnFloor - все комнаты на выбранном этаже (выбранной локации)
  const roomsOnFloor = useMemo(() => rooms.filter(room => room.floorId === floorId), [rooms, floorId]);

  // devicesOnFloor - все девайсы на выбранном этаже (выбранной локации)
  const devicesOnFloor = useMemo(() => devices.filter(device => device.floorId === floorId), [devices, floorId]);
  // typeDevices - девайсы из devicesOnFloor с выбранным типом
  const typeDevices = useMemo(
    () => devicesOnFloor.filter(device => getActiveButtonType(device.deviceType) === activeButton),
    [devicesOnFloor, activeButton]
  );

  const existingDeviceTypesOnFloor = useMemo(() => {
    return devicesOnFloor.map(device => getActiveButtonType(device.deviceType));
  }, [devicesOnFloor]);
  const filteredButtons = allButtons.filter(button => existingDeviceTypesOnFloor.includes(button));

  // Проверяем есть ли дефолтный тип в списке девайсов на этаже
  useEffect(() => {
    if (activeButton === defaultButton) {
      const typesForNewFloor = existingDeviceTypesForFloor(floorId);
      if (!typesForNewFloor.includes(defaultButton) && typesForNewFloor.length > 0) {
        setActiveButton(typesForNewFloor[0]);
      }
    }
  }, [activeButton, defaultButton, existingDeviceTypesForFloor, floorId]);

  // TODO: перенести header в отдельный компонент

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.sideBlockLeft}>
          <p className={styles.title}>{t.devices}</p>
        </div>
        <div className={styles.centerBlock}>
          {floors.length > 1 && (
            <ButtonGroup
              buttons={allFloorButtons}
              buttonTexts={allFloorButtonsTexts}
              activeButton={floorId}
              onChange={handleFloorButtonClick}
              width={90}
            />
          )}
        </div>
        <div className={styles.sideBlockRight}></div>
      </div>

      <div className={styles.container}>
        <div className={styles.allComponentsBox}>
          {floorImg && (
            <div className={styles.floorBox} ref={floorBoxRef}>
              <img src={floorImg} alt="floorImage" className={styles.floorImg} onLoad={() => setImageLoaded(true)} />
              <ControlsDevices devices={typeDevices} handleDeviceToggle={handleDeviceToggle} floorImgWidth={floorImgWidth} />
            </div>
          )}
          <div className={floorImg ? styles.devicesBox : styles.devicesBoxFullWidth}>
            {floorImg && (
              <div className={styles.buttonGroupBox}>
                <ButtonGroup
                  buttons={filteredButtons}
                  buttonTexts={allButtonsTexts}
                  activeButton={activeButton}
                  onChange={handleButtonClick}
                  width={90}
                />
              </div>
            )}
            {roomsOnFloor?.map((room, index) => (
              <RoomDevices
                key={index}
                devices={devices}
                name={room.name}
                roomId={room.id}
                handleDeviceToggle={handleDeviceToggle}
                roomAndFloorData={roomAndFloorData}
                handleAddToFavorites={handleAddToFavorites}
              />
            ))}
            <RoomDevices
              devices={devices}
              name="Без комнаты"
              roomId={null}
              handleDeviceToggle={handleDeviceToggle}
              roomAndFloorData={roomAndFloorData}
              handleAddToFavorites={handleAddToFavorites}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ControlsContainer.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  floors: PropTypes.arrayOf(PropTypes.shape({})),
  handleDeviceToggle: PropTypes.func.isRequired,
  roomAndFloorData: PropTypes.shape({}),
  handleAddToFavorites: PropTypes.func.isRequired,
};

ControlsContainer.defaultProps = {
  devices: [],
  rooms: [],
  floors: [],
  roomAndFloorData: {},
};

export default memo(ControlsContainer);
