import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/ButtonGroup.module.css';

const ButtonGroup = ({ buttons, buttonTexts, activeButton, onChange, width }) => {
  if (!buttons || buttons.length === 0) {
    return null;
  }

  return (
    <div
      className={styles.buttonGroupBox}
      style={{
        width: buttons.length * width
      }}
    >
      <div
        className={styles.buttonGroup}
        style={{
          width: buttons.length * width
        }}
      >
        {buttons.map((button, index) => (
          <button
            key={index}
            className={`${styles.button} ${button === activeButton ? styles.active : ''}`}
            style={{
              width: width
            }}
            onClick={() => onChange(button)}
          >
            {buttonTexts[button]}
          </button>
        ))}
        <div
          className={styles.background}
          style={{
            left: activeButton ? buttons.indexOf(activeButton) * width : 0,
            width: activeButton ? width : 0
          }}
        ></div>
      </div>
    </div>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  buttonTexts: PropTypes.objectOf(PropTypes.string),
  activeButton: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
};

ButtonGroup.defaultProps = {
  width: 100,
};

export default memo(ButtonGroup);

