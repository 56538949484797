import React from 'react';

function ArrowIcon({ className }) {
  return (
    <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 700 700" className={className}>
      <defs></defs>
      <g><path d="M242 298l181 185 182 -185c24,-25 65,16 40,41l-205 211c-9,9 -24,9 -32,0l-207 -211c-24,-25 17,-66 41,-41z"/></g>
    </svg>
  );
}

export default ArrowIcon;
