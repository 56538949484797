import React, { useEffect, useState, useRef } from 'react';
import styles from './styles/ScrollColorChange.module.css';

const ScrollColorChange = ({ text }) => {
  const words = text.split(' '); // Разбиваем текст на слова
  const elementRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const calculateVisibility = index => {
    if (!elementRef.current) return false;
    const elementTop = elementRef.current.getBoundingClientRect().top + window.pageYOffset - 500;
    const elementVisiblePoint = elementTop + index * 20; // Увеличиваем порог видимости для каждого слова
    return scrollPosition >= elementVisiblePoint;
  };

  return (
    <div ref={elementRef}>
      {words.map((word, index) => (
        <span
          key={index}
          className={styles.word}
          style={{ color: calculateVisibility(index) ? 'rgb(41, 40, 36)' : '#dfdfde' }}
        >
          {word}{' '}
        </span>
      ))}
    </div>
  );
};

export default ScrollColorChange;
