import React, { useContext, useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import IosButton from '../common/IosButton';
import { getPermissionsSettings, updatePermissionsSettings } from '../../utils/api';
import { ClipLoader } from 'react-spinners';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/PermissionsSettings.translations.js';

import styles from './styles/settings.module.css';

const PermissionsSettings = ({ isMobile, onClose, personalId }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [isLoading, setIsLoading] = useState(true);
    const [permissions, setPermissions] = useState({});

    const fetchData = useCallback(async () => {
        try {
            const { data } = await getPermissionsSettings(personalId);
            if (data?.dataPermissions) {
                setPermissions(data.dataPermissions);
            }
        } catch (error) {
            console.error('Error fetching permissions settings:', error);
        } finally {
            setIsLoading(false);
        }
    }, [personalId]);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [fetchData]);

    const handleToggle = useCallback((type, channel) => {
        const updatedPermissions = {
            ...permissions,
            [channel]: {
                ...permissions[channel],
                [type]: !permissions[channel]?.[type],
            },
        };

        setPermissions(updatedPermissions);
        updatePermissionsSettings(personalId, updatedPermissions);
    }, [permissions, personalId]);

    return (
        <div className={styles.container}>
            {isMobile && <BackButton onClick={onClose} />}
            <div className={styles.formGroup}>
                <h3 className={styles.title}>{t.title}</h3>
                <p className={styles.text}>{t.subtitle}</p>

                {isLoading ? (
                    <div className={styles.block}>
                        <ClipLoader color="#4A90E2" size={25} />
                    </div>
                ) : (
                    <div className={styles.blockGray}>
                        <p className={styles.blockTitle}>{t.guests}</p>
                        <div className={styles.blockRow}>
                            <p className={styles.blockText}>{t.guestsSecurity}</p>
                            <IosButton
                                onClick={() => handleToggle('security', 'guests')}
                                isActive={permissions?.guests?.security}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

PermissionsSettings.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    personalId: PropTypes.string.isRequired,
};

export default memo(PermissionsSettings);
