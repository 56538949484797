import axios from 'axios';

const controlApp = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/control`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export async function getControlData(personalId) {
  try {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/control/data/${personalId}`, {
      withCredentials: true,
    });
  } catch (error) {
    console.error('Error fetching control data:', error);
  }
}

// Функция для сохранения подписки на сервере
export function saveSubscription(personalId, subscription) {
  return controlApp.post(`/subscribe/${personalId}`, { subscription });
}
export function deleteSubscription(personalId) {
  console.log('personalId:', personalId);
  return controlApp.delete(`/subscribe/${personalId}`);
}

// Функции для работы с устройствами Zigbee
export function getZigbeeData(personalId) {
  return controlApp.get(`/zigbee-data/${personalId}`);
}
export function setZigbeeConnectionState(personalId, isCanConnect) {
  return controlApp.post(`/zigbee-state/${personalId}`, { isCanConnect });
}
export function deleteZigbeeDevice(personalId, deviceId) {
  return controlApp.delete(`/zigbee-device/${personalId}/${deviceId}`);
}

// Обновление данных устройства
export function updateDeviceData(deviceId, name, roomId, positionLeft, positionTop) {
  return controlApp.post(`/device/${deviceId}`, { name, roomId, positionLeft, positionTop });
}

// Работа с пользователями дома
export function getHomeUsersData(personalId) {
  return controlApp.get(`/home-users-data/${personalId}`);
}
export function addHomeUser(personalId, phoneNumber) {
  return controlApp.post(`/home-users-data/${personalId}`, { phoneNumber });
}
export function deleteHomeUser(personalId, userId) {
  return controlApp.delete(`/home-users-data/${personalId}/${userId}`);
}
export function getCurrentUser(personalId) {
  return controlApp.get(`/current-user/${personalId}`);
}
export function saveUserData({ name, avatar }) {
  return controlApp.post(`/current-user/`, { name, avatar });
}

// Telegram-бот
export function getTelegramData() {
  return controlApp.get('/telegram');
}
export function deleteTelegram() {
  return controlApp.delete('/telegram');
}
export function getTelegramLink() {
  return controlApp.get('/telegram-link');
}

// Настройки уведомлений
export function getNotificationSettings(personalId) {
  return controlApp.get(`/notifications/${personalId}`);
}
export function updateNotificationSettings(personalId, settings) {
  return controlApp.post(`/notifications/${personalId}`, settings);
}

// Настройки разрешений
export function getPermissionsSettings(personalId) {
  return controlApp.get(`/permissions/${personalId}`);
}
export function updatePermissionsSettings(personalId, settings) {
  return controlApp.post(`/permissions/${personalId}`, settings);
}

// Работа с IR пультом
export function learnIRCode(personalId, deviceId) {
  return controlApp.post(`/ir-learn/${personalId}/${deviceId}`);
}
export function saveIRCode(personalId, deviceId, commandName, commandCode) {
  return controlApp.post(`/ir-code/${personalId}/${deviceId}`, { commandName, commandCode });
}
export function sendIRCode(personalId, deviceId, commandId) {
  return controlApp.post(`/ir-send/${personalId}/${deviceId}`, { commandId });
}
