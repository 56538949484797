import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { PrivateWrapper, CheckAuthWrapper } from './Wrappers';
import { LanguageProvider } from './LanguageContext';

// landings
import LandingContainer from '../landings/LandingContainer';
import PrivacyPage from '../landings/PrivacyPage';
import CityLanding from '../landings/city-landings/CityLanding';

// login
import Login from '../auth/components/Login';

// app
import App from '../app/components/App';

// app conntrol
import Control from '../control/Control';

const AppRoutes = () => {
  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<LandingContainer />} />
        <Route path="/privacy" element={<PrivacyPage />} />

        <Route path="/en" element={<LandingContainer urlLang="en" />} />
        <Route path="/ru" element={<LandingContainer urlLang="ru" />} />

        <Route path="/moscow" element={<CityLanding cityTag="moscow" cityName="Москве" />} />
        <Route path="/kirov" element={<CityLanding cityTag="kirov" cityName="Кирове" />} />
        <Route path="/sochi" element={<CityLanding cityTag="sochi" cityName="Сочи" />} />

        <Route element={<CheckAuthWrapper />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PrivateWrapper />}>
          <Route path="/app/*" element={<App />} />
          <Route path="/control/:personalId/*" element={<Control />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </LanguageProvider>
  );
};

export default AppRoutes;
