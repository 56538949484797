import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import FavoriteDevicesBlock from '../common/FavoriteDevicesBlock';
import RoomsBlock from '../common/RoomsBlock';
import DateBlock from '../common/DateBlock';
import ScenariosBlock from '../common/ScenariosBlock';

import styles from './styles/HomeContainer.module.css';

const Clock = memo(() => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const hours = time.getHours().toString();
  const minutes = time.getMinutes().toString().padStart(2, '0');

  return (
    <p className={styles.time}>{`${hours}:${minutes}`}</p>
  );
});

const HomeContainer = ({
  homeData,
  devices,
  rooms,
  floors,
  handleDeviceToggle,
  roomAndFloorData,
  handleAddToFavorites,
  securityActivationTime,
  handleSecurityToggle,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.sideBlock}>
          <Clock />
          <div className={styles.divider}></div>
          <div>
            <p className={styles.companyTitle}>GETHOME</p>
            <p className={styles.appTitle}>{homeData.name}</p>
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.allComponentsBox}>
          <div className={styles.firstColumn}>
            <div className={styles.dateAndScenariosRow}>
              <DateBlock devices={devices} />
              <ScenariosBlock
                devices={devices}
                handleDeviceToggle={handleDeviceToggle}
                handleSecurityToggle={handleSecurityToggle}
                securityActivationTime={securityActivationTime}
                homeData={homeData}
              />
            </div>
            <FavoriteDevicesBlock
              devices={devices}
              handleDeviceToggle={handleDeviceToggle}
              roomAndFloorData={roomAndFloorData}
              handleAddToFavorites={handleAddToFavorites}
            />
          </div>
          <RoomsBlock
            devices={devices}
            floors={floors}
            handleDeviceToggle={handleDeviceToggle}
            roomAndFloorData={roomAndFloorData}
          />
        </div>
      </div>
    </div>
  );
};

HomeContainer.propTypes = {
  homeData: PropTypes.object.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  floors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleDeviceToggle: PropTypes.func.isRequired,
  roomAndFloorData: PropTypes.shape({}),
  handleAddToFavorites: PropTypes.func.isRequired,
  securityActivationTime: PropTypes.instanceOf(Date),
  handleSecurityToggle: PropTypes.func.isRequired,
};

HomeContainer.defaultProps = {
  roomAndFloorData: {},
  securityActivationTime: null,
};

export default memo(HomeContainer);
