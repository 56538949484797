import React, { useEffect, useState, useCallback, useContext, useMemo, memo } from 'react';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from '../language/LandingContainer.translations.js';

import styles from './styles/AppFeatures.module.css';

const AppFeatures = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const features = useMemo(() => [
    { title: t.appFeatures1Title, description: t.appFeatures1Description, image: `../../images/site/shots/shots_${language === 'ru' ? 'ru' : 'en'}/shots2.png` },
    { title: t.appFeatures2Title, description: t.appFeatures2Description, image: `../../images/site/shots/shots_${language === 'ru' ? 'ru' : 'en'}/shots3.png` },
    { title: t.appFeatures3Title, description: t.appFeatures3Description, image: `../../images/site/shots/shots_${language === 'ru' ? 'ru' : 'en'}/shots4.png` },
    { title: t.appFeatures4Title, description: t.appFeatures4Description, image: `../../images/site/shots/shots_${language === 'ru' ? 'ru' : 'en'}/shots5.png` },
    { title: t.appFeatures5Title, description: t.appFeatures5Description, image: `../../images/site/shots/shots_${language === 'ru' ? 'ru' : 'en'}/shots6.png` },
    { title: t.appFeatures6Title, description: t.appFeatures6Description, image: `../../images/site/shots/shots_${language === 'ru' ? 'ru' : 'en'}/shots7.png` },
    { title: t.appFeatures7Title, description: t.appFeatures7Description, image: `../../images/site/shots/shots_${language === 'ru' ? 'ru' : 'en'}/shots8.png` },
  ], [language, t]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll = useCallback(() => {
    let closestIndex = 0;
    let smallestDistance = Infinity;

    features.forEach((_, index) => {
      const element = document.getElementById(`feature-${index}`);
      const rect = element.getBoundingClientRect();
      const distance = Math.abs(rect.top - window.innerHeight / 2);

      if (distance < smallestDistance) {
        closestIndex = index;
        smallestDistance = distance;
      }
    });

    if (closestIndex !== currentIndex) {
      setCurrentIndex(closestIndex);
    }
  }, [currentIndex, features]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentIndex, handleScroll]);

  return (
    <div className={styles.featuresBox}>
      <h1 className={styles.title}><span>{t.titleFeatures}</span> {t.titleApp}</h1>
      <div className={styles.featuresContainer}>
        <div className={styles.featuresText}>
          {features.map((feature, index) => (
            <div
              key={index}
              id={`feature-${index}`}
              className={styles.featureBlock}
              style={{ opacity: index >= currentIndex ? 1 : 0 }}
            >
              <h2 className={styles.featureTitle}>{feature.title}</h2>
              <p className={styles.featureDescription}>{feature.description}</p>
            </div>
          ))}
        </div>
        <div className={styles.featureImage}>
          {features.map((feature, index) => (
            <img key={index} src={feature.image} alt={feature.title} style={{ opacity: currentIndex >= index ? 1 : 0 }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(AppFeatures);
