import React, { useContext, useEffect, useState, useCallback, memo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getHome, getAliceCode } from '../../api';
import BackButton from '../common/BackButton';
import { identifyProvider, getServiceName } from './utils';
import InfoPopup from '../../../control/components/common/InfoPopup';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/House.translations.js';

import styles from './styles/House.module.css';

// TODO: Сделать перевод для привязки дома к сервисам (как и в Home.js)

const House = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const { personalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [house, setHouse] = useState(null);
  const [currentQuery, setCurrentQuery] = useState(null);
  const [currentQueryJson, setCurrentQueryJson] = useState(null);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (location.search) {
      setCurrentQuery(location.search);

      const query = location.search;
      const json = {};
      query.replace("?", "").split("&").forEach(pair => {
        const [key, value] = pair.split("=");
        json[key] = decodeURIComponent(value || "");
      });

      if (json) {
        setCurrentQueryJson(json);
        setProvider(identifyProvider(json));
        setIsShowInfo(true);
      }
    }
  }, [location]);

  const getHouse = useCallback(async () => {
    try {
      if (personalId) {
        const { data } = await getHome(personalId);
        if (data?.home) {
          setHouse(data.home);
        } else {
          navigate('/app/home');
        }
      }
    } catch (error) {
      console.error("Failed to fetch house details:", error);
    }
  }, [navigate, personalId]);

  useEffect(() => {
    getHouse();
  }, [getHouse]);

  const handleConnectToAlisa = useCallback(async () => {
    try {
      if (currentQueryJson) {
        const { data } = await getAliceCode({ clientId: currentQueryJson.client_id, personalId, provider });
        if (data?.aliceCode) {
          const redirectUrl = `${currentQueryJson.redirect_uri}?code=${data.aliceCode}&state=${currentQueryJson.state}&client_id=${currentQueryJson.client_id}`;
          window.location.href = redirectUrl;
        }
      }
    } catch (error) {
        console.error("Error connecting to Alisa:", error);
    }
  }, [currentQueryJson, personalId, provider]);

  // TODO: Вынести все кнопки в отдельный компонент в common
  // TODO: Убрать mqttEnabled. mqtt будет создаваться и работать для всех домов. Показывать тип подписки (нет/алиса/pro) и данные mqtt по нажатие кнопке
  // TODO: Не показывать пользователям данные mqtt (пользователь должен знать только свой user id и personal id дома)

  const renderButton = useCallback(() => {
    let buttonClass;

    switch (provider) {
      case 'yandex':
        buttonClass = styles.aliceButton;
        break;
      case 'sber':
        buttonClass = styles.sberButton;
        break;
      case 'google':
        buttonClass = styles.googleButton;
        break;
      case 'vk':
        buttonClass = styles.vkButton;
        break;
      default:
        buttonClass = styles.aliceButton;
        break;
    }

    return (
      <button
        onClick={handleConnectToAlisa}
        className={`${styles.button} ${buttonClass}`}
      >
        Связать дом {getServiceName(provider)}
      </button>
    );
  }, [handleConnectToAlisa, provider]);

  return (
    <>
      <div className={styles.container}>
        <BackButton to={`../home${currentQuery}`} />
        {house ? (
          <>
            <h2>{house.name}</h2>

            {currentQuery && <div>{renderButton()}</div>}

            <div className={styles.houseDataBox}>
              {/* <div className={styles.dataBlock}>
                <h2>Homebridge</h2>
                <a href={`${process.env.REACT_APP_BASE_URL}/app/home-homebridge-config/${personalId}`} className={styles.ctaButton} download>Скачать config</a>
              </div> */}

              <div className={styles.dataBlock}>
                <h2>{t.controlApp}</h2>
                <a href={`/control/${personalId}`} className={styles.ctaButton}>{t.openApp}</a>
              </div>
            </div>
          </>
        ) : (
          <p>{t.loading}</p>
        )}
      </div>
      {isShowInfo && (
        <InfoPopup isShouldCloseOnOutsideClick={false} onClose={() => { setIsShowInfo(false); }}>
          <h2 className={styles.slideTitle}>Связать дом {getServiceName(provider)}</h2>
          <p className={styles.slideContent}>Для связи вашего дома {getServiceName(provider)} нажмите на кнопку "Связать дом {getServiceName(provider)}"</p>
          <div className={styles.buttonBox}>{renderButton()}</div>
        </InfoPopup>
      )}
    </>
  );
};

export default memo(House);
