import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ControlsBigButton from './ControlsBigButton';

import styles from './styles/RoomDevices.module.css';

const RoomDevices = ({ devices, name, roomId, handleDeviceToggle, roomAndFloorData, isShowName, handleAddToFavorites }) => {
  const roomDevices = devices.filter(device => device.roomId === roomId);

  if (roomDevices.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      {isShowName && name && <Link to={`../room/${roomId}`} className={styles.name}>{name}</Link>}
      <div className={styles.buttonsBox}>
        {roomDevices?.map((device, index) => (
          <ControlsBigButton
            key={index}
            type={device.deviceType}
            name={device.deviceName}
            deviceId={device.id}
            handleDeviceToggle={handleDeviceToggle}
            status={device.status}
            statusText={device.statusText}
            roomName={name}
            position={device.position}
            roomAndFloorData={roomAndFloorData}
            floorId={device.floorId}
            isFavorite={device.isFavorite}
            handleAddToFavorites={handleAddToFavorites}
            isZigbee={device.isZigbee}
            roomId={roomId}
            positionLeft={device.positionLeft}
            positionTop={device.positionTop}
            updatedAt={device.updatedAt}
            remoteButtons={device.remoteButtons}
          />
        ))}
      </div>
    </div>
  );
};

RoomDevices.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  handleDeviceToggle: PropTypes.func.isRequired,
  name: PropTypes.string,
  roomId: PropTypes.number,
  roomAndFloorData: PropTypes.shape({}),
  isShowName: PropTypes.bool,
  handleAddToFavorites: PropTypes.func.isRequired,
};

RoomDevices.defaultProps = {
  devices: [],
  name: '',
  roomId: null,
  roomAndFloorData: {},
  isShowName: true,
};

export default memo(RoomDevices);
