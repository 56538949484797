
const translations = {
    ru: {
        home: 'В доме',
    },
    en: {
        home: 'Inside',
    },
};

export default translations;
