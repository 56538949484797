import React, { useContext, useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import { getHomeUsersData, addHomeUser, deleteHomeUser } from '../../utils/api';
import { ROLES, ROLES_NAME } from '../../../constants/homeRoles';
import { Trash2, Check } from 'lucide-react';
import InfoPopup from '../common/InfoPopup';
import InputMask from 'react-input-mask';
import { ClipLoader } from 'react-spinners';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/UsersSettings.translations.js';

import styles from './styles/settings.module.css';
import pageStyles from './styles/UsersSettings.module.css';

const UsersSettings = ({ isMobile, onClose, personalId, currentUser }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [users, setUsers] = useState([]);
    const [deletingUserId, setDeletingUserId] = useState(null);
    const [isShowAddUser, setIsShowAddUser] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);

    const fetchUsers = useCallback(async () => {
        const { data } = await getHomeUsersData(personalId);
        if (data.users) {
            const sortedUsers = data.users.sort((a, b) => a.roleId - b.roleId);
            setUsers(sortedUsers);
        }
        setIsLoadingUsers(false);
    }, [personalId]);

    useEffect(() => {
        setIsLoadingUsers(true);
        fetchUsers();
    }, [fetchUsers]);

    const renderAvatar = useCallback(avatar => {
        if (avatar) {
            return `/images/avatars/${avatar}.jpg`;
        }

        return '/images/avatars/avatar1.jpg';
    }, []);

    const renderNameAndPhone = useCallback((name, phone) => {
        if (name && phone) {
            return (<>{name}<br />+{phone}</>);
        } else if (name) {
            return name;
        } else if (phone) {
            return `+${phone}`;
        }

        return '';
    }, []);

    const renderRole = useCallback(role => {
        switch (role) {
            case ROLES.OWNER:
                return ROLES_NAME[language].OWNER;
            case ROLES.RESIDENT:
                return ROLES_NAME[language].RESIDENT;
            case ROLES.GUEST:
                return ROLES_NAME[language].GUEST;
            default:
                return '';
        }
    }, [language]);

    const handleDeleteClick = useCallback(userId => {
        setDeletingUserId(userId);
    }, []);

    const handleConfirmDelete = useCallback(async userId => {
        try {
            const response = await deleteHomeUser(personalId, userId);
            if (response.status === 200) {
                setUsers(prevUsers => prevUsers.filter(user => user.userId !== userId));
            }
        } catch (error) {
            console.error('Ошибка при удалении пользователя:', error);
        }

        setDeletingUserId(null);
    }, [personalId]);

    const handleAddUser = useCallback(async () => {
        setIsLoading(true);

        try {
            const { data } = await addHomeUser(personalId, phoneNumber);
            if (data) {
                setUsers(prevUsers => [...prevUsers, data]);
                setIsShowAddUser(false);
                setIsLoading(false);
                setPhoneNumber('');
            }
        } catch (error) {
            console.error(`${t.error}:`, error);
            switch (error.response.data.error) {
                case 'User already exists':
                    // setErrorText('Пользователь уже добавлен в дом');
                    setErrorText(t.userAlreadyExists);
                    break;
                case 'User not found':
                    setErrorText(t.userNotFound);
                    break;
                case 'Неверный формат номера телефона':
                    setErrorText(t.wrongPhoneNumber);
                    break;
                default:
                    setErrorText(t.error);
                    break;
            }
            setIsLoading(false);
        }
    }, [personalId, phoneNumber, t]);

    const renderAddUserPopupContent = useCallback(() => {
        if (isLoading) {
            return (
                <div className={pageStyles.slideBox}>
                    <h2 className={pageStyles.slideTitle}>{t.popupTitle}</h2>
                    <div className={pageStyles.loaderBox}>
                        <ClipLoader color="#4A90E2" size={25} />
                    </div>
                </div>
            );
        }

        return (
            <div className={pageStyles.slideBox}>
                <h2 className={pageStyles.slideTitle}>{t.popupTitle}</h2>
                <p className={pageStyles.slideContent}>{t.popupText}</p>
                <div className={pageStyles.editBox}>
                    <InputMask
                        mask={t.popupPhoneMask}
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        placeholder={t.popupPhonePlaceholder}
                        required
                        className={pageStyles.slidePhoneNumber}
                    />
                </div>
                {errorText && <p className={pageStyles.errorText}>{errorText}</p>}
                <div className={pageStyles.editButtonsBox}>
                    <button className={pageStyles.cancelButton} onClick={() => {
                        setIsShowAddUser(false);
                        setPhoneNumber('');
                        setIsLoading(false);
                    }}>{t.popupCancel}</button>
                    <button className={pageStyles.saveButton} onClick={handleAddUser}>{t.popupAdd}</button>
                </div>
            </div>
        );
    }, [errorText, handleAddUser, isLoading, phoneNumber, t]);

    return (
        <div className={styles.container}>
            {isMobile && <BackButton onClick={onClose} />}
            <div className={styles.formGroup}>
                <h3 className={styles.title}>{t.title}</h3>

                <p className={styles.text}>{t.text}</p>

                {isLoadingUsers && (
                    <div className={styles.block}>
                        <div className={pageStyles.loaderBox}>
                            <ClipLoader color="#4A90E2" size={25} />
                        </div>
                    </div>
                )}

                {!isLoadingUsers && (
                    <div className={`${styles.blockGray} ${pageStyles.block}`}>
                        {users?.map(user => (
                            <div key={user.id} className={pageStyles.user}>
                                <img src={renderAvatar(user.avatar)} alt="avatar" className={pageStyles.userAvatar} />
                                <div className={pageStyles.userName}>{renderNameAndPhone(user.name, user.phone)}</div>
                                <div className={pageStyles.userRole}>{renderRole(user.roleName)}</div>
                                <div className={pageStyles.userButton}>
                                    {currentUser.roleName === ROLES.OWNER && user.roleName !== ROLES.OWNER && (
                                        <>
                                            {deletingUserId === user.userId ? (
                                                <button
                                                    className={`${pageStyles.trashButton} ${pageStyles.confirmButton}`}
                                                    onClick={() => handleConfirmDelete(user.userId)}
                                                >
                                                    <Check size={20} />
                                                </button>
                                            ) : (
                                                <button
                                                    className={pageStyles.trashButton}
                                                    onClick={() => handleDeleteClick(user.userId)}
                                                >
                                                    <Trash2 size={18} />
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                        {currentUser.roleName === ROLES.OWNER && (
                            <button className={pageStyles.addUserButton} onClick={() => {
                                setPhoneNumber('');
                                setDeletingUserId(null);
                                setIsLoading(false);
                                setIsShowAddUser(true);
                            }}>
                                {t.addButton}
                            </button>
                        )}
                    </div>
                )}
            </div>
            {isShowAddUser && (
                <InfoPopup isShouldCloseOnOutsideClick={false} onClose={() => {
                    setIsShowAddUser(false);
                    setPhoneNumber('');
                    setIsLoading(false);
                }}>
                    {renderAddUserPopupContent()}
                </InfoPopup>
            )}
        </div>
    );
};

UsersSettings.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    personalId: PropTypes.string.isRequired,
    currentUser: PropTypes.shape({}),
};

UsersSettings.defaultProps = {
    currentUser: {},
};

export default memo(UsersSettings);
