import React, { useContext, memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LanguageContext } from '../../../routes/LanguageContext';

import styles from './styles/BackButton.module.css';

const BackButton = ({ to }) => {
  const { language } = useContext(LanguageContext);

  if (to) {
    return (
      <Link className={styles.backButton} to={to}>← {language === 'ru' ? 'Назад' : 'Back'}</Link>
    );
  }

  return null;
};

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
};

export default memo(BackButton);
