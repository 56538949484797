import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { setLanguage as setLanguageDB, getLanguage as getLanguageDB } from '../utils/db';
import { getLanguageFromLocation } from '../utils/location';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en'); // По умолчанию английский язык

    const location = useLocation();
    const navigate = useNavigate();

    const fetchLanguage = useCallback(async () => {
        const oldLanguage = await getLanguageDB();
        let newLanguage;

        if (location.search) {
            const query = location.search;
            const json = {};
            query.replace("?", "").split("&").forEach(pair => {
                const [key, value] = pair.split("=");
                json[key] = decodeURIComponent(value || "");
            });

            if (json?.lang) {
                newLanguage = json.lang;
            }
        }

        if (!newLanguage && !oldLanguage) {
            // Если ни в URL, ни в локальном хранилище нет языка, пытаемся определить его по IP
            newLanguage = await getLanguageFromLocation();
        }

        if (newLanguage === 'ru' || newLanguage === 'en') {
            setLanguage(newLanguage);
            await setLanguageDB(newLanguage);

            // убираем ?lang=ru или ?lang=en из url
            const newUrl = location.pathname;
            navigate(newUrl);
        } else if (oldLanguage === 'ru' || oldLanguage === 'en') {
            setLanguage(oldLanguage);
        }
    }, [location, navigate]);

    useEffect(() => {
        fetchLanguage();
    }, [fetchLanguage]);

    const toggleLanguage = async () => {
        const newLanguage = language === 'ru' ? 'en' : 'ru';
        setLanguage(newLanguage);
        await setLanguageDB(newLanguage);

        // убираем ?lang=ru или ?lang=en из url
        const newUrl = location.pathname;
        navigate(newUrl);
    };

    const setNewLanguageURL = useCallback(async newLanguage => {
        setLanguage(newLanguage);
        await setLanguageDB(newLanguage);

        // убираем ?lang=ru или ?lang=en из url и если мы пришли с /en или /ru, то убираем и это
        const newUrl = location.pathname.replace(/\/(en|ru)/, "");
        navigate(newUrl);
    }, [location, navigate]);

    const setNewLanguage = useCallback(async newLanguage => {
        setLanguage(newLanguage);
        await setLanguageDB(newLanguage);
    }, []);

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage, setNewLanguageURL, setNewLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
