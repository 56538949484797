import React from 'react';

function LightIcon() {
  return (
    <svg width="18pt" height="18pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '3px' }}>
      <g>
        <path d="m420 451.73h-139.54c-8.3984 0-15.398 6.5352-15.867 14.934 0 25.668 21 46.668 46.668 46.668h77.934c25.668 0 46.668-21 46.668-46.668-0.46875-8.4023-7.4688-14.934-15.867-14.934z"/>
        <path d="m331.33 47.602c-75.602 7.4648-135.33 67.199-143.73 142.8-4.1992 51.801 17.266 102.67 57.867 135.8 12.133 9.332 19.133 23.801 19.133 38.734 0 21.934 17.734 39.668 39.668 40.133h0.93359 89.602c21.934 0 39.668-17.734 39.668-39.668 0-15.398 7-29.398 19.133-38.734 36.867-29.867 58.332-74.199 58.801-121.8 1.3984-93.332-82.602-167.07-181.07-157.27z"/>
      </g>
    </svg>
  );
}

export default LightIcon;
