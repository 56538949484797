import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import FileUpload from './FileUpload';
import { getHomeData, uploadFlourFile, deleteFlourFile, saveDevicesPosition } from '../../../api';

import styles from './styles/DevicePositionBlock.module.css';

const DevicePositionBlock = ({ houseId }) => {
  const [devices, setDevices] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [floors, setFloors] = useState([]);

  const { current: handleImageRemove } = useRef(async floorId => {
    try {
      await deleteFlourFile(floorId);
      setFloors(prev => prev.map(floor => {
        if (floor.id === floorId) {
          return { ...floor, floorImage: null };
        }
        return floor;
      }));
    } catch (error) {
      console.error(error);
    }
  });

  const { current: handleDevicePositionChange } = useRef((deviceId, positionType, value) => {
    setDevices(prev => prev.map(device => {
      if (device.id === deviceId) {
        if (positionType === 'left') {
          return { ...device, positionLeft: Number(value) };
        } else if (positionType === 'top') {
          return { ...device, positionTop: Number(value) };
        }
      }
      return device;
    }));
  });

  const getData = useCallback(async () => {
    try {
      if (houseId) {
        const { data } = await getHomeData(houseId);
        if (data?.home) {
          const floorsData = data.home.floors ? data.home.floors.map(floor => ({
            id: floor.id,
            name: floor.floorName,
            floorImage: floor.floorImage,
          })) : [];
          setFloors(floorsData);

          const roomsData = data.home.rooms ? data.home.rooms.map(room => ({
            id: room.id,
            name: room.roomName,
            floorId: floorsData.find(floor => (floor.id === room.floorId))?.id,
          })) : [];
          setRooms(roomsData);

          const homeDevicesData = data.home.devices ? data.home.devices.map(device => ({
            id: device.id,
            tableId: device.id,
            line: device.lineId,
            name: device.deviceName,
            lineType: device.lineType,
            type: device.deviceType,
            roomId: device.roomId,
            floorId: roomsData.find(room => (room.id === device.roomId))?.floorId,
            positionLeft: device.positionLeft,
            positionTop: device.positionTop,
          })) : [];
          setDevices(homeDevicesData);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [houseId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleFileLoaded = useCallback(data => {
    if (data.filename.length > 0) {
      setFloors(prev => prev.map(floor => {
        if (floor.id === Number(data.floorId)) {
          return { ...floor, floorImage: data.filename };
        }
        return floor;
      }));
    }
  }, []);

  const saveChanges = useCallback(async () => {
    try {
      await saveDevicesPosition({ homeId: houseId, devices });
      alert('Изменения успешно сохранены!');
    } catch (error) {
      console.error("Ошибка при сохранении:", error);
    }
  }, [devices, houseId]);

  return (
    <div>
      {floors && floors.map(floor => (
        <div key={floor.id}>
          <h1 className={styles.title}>{floor.name}</h1>

          {floor.floorImage && (
            <div className={styles.floorBox}>
              <img src={`${process.env.REACT_APP_BASE_URL}/app/floor-image/${floor.id}`} alt="FloorImage" className={styles.floorImage} />
              <div className={styles.floorDevicesBox}>
                {devices.filter(device => device.floorId === floor.id).map(device => {
                  if (!device.positionLeft && !device.positionTop) {
                    return null;
                  }

                  return (
                    <button
                      key={device.id}
                      className={styles.buttonDevice}
                      style={{ left: device.positionLeft || 0, top: device.positionTop || 0 }}
                    >
                      {device.id}
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          <div className={styles.floorImageBox}>
            <FileUpload
              params={{ floorId: floor.id }}
              onFileLoaded={handleFileLoaded}
              uploadFile={uploadFlourFile}
              id={floor.id}
            />
          </div>
          {floor.floorImage && (
            <button onClick={() => handleImageRemove(floor.id)} className={styles.saveButton}>Удалить изображение</button>
          )}

          {rooms && rooms.filter(room => room.floorId === floor.id).map(room => (
            <div key={room.id}>
              <h4 className={styles.title}>{room.name}</h4>
              {devices.filter(device => device.roomId === room.id).map(device => (
              <div key={device.id} className={styles.deviceBox}>
                <div className={styles.textBox}>
                  <p className={styles.number}>{device.id}</p>
                  <p className={styles.text}>{device.name}</p>
                </div>
                <div>
                  <label className={styles.positionText}>Left: </label>
                  <input
                    type="number"
                    className={styles.input}
                    value={device.positionLeft || ''}
                    onChange={(e) => handleDevicePositionChange(device.id, 'left', e.target.value)}
                  />
                  <label className={styles.positionText}>Top: </label>
                  <input
                    type="number"
                    className={styles.input}
                    value={device.positionTop || ''}
                    onChange={(e) => handleDevicePositionChange(device.id, 'top', e.target.value)}
                  />
                </div>
              </div>
              ))}
            </div>
          ))}
        </div>
      ))}

      <button onClick={saveChanges} className={styles.saveButton}>Сохранить изменения</button>
    </div>
  );
}

DevicePositionBlock.propTypes = {
  houseId: PropTypes.number.isRequired,
};

export default memo(DevicePositionBlock);
