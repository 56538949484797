import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './styles/index.css';

// Register service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js');
    });
}

function setZoomLevel() {
    const screenWidth = window.innerWidth;
    document.documentElement.style.setProperty('--page-width', `${screenWidth}`);
}

setZoomLevel();
window.addEventListener('resize', setZoomLevel);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
