import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MENU } from '../../../constants/menu';
import { useNavigate } from 'react-router-dom';

import HomeIcon from '../../../images/icons/HomeIcon';
import LightIcon from '../../../images/icons/LightIcon';
import MenuIcon from '../../../images/icons/MenuIcon';
import SettingsIcon from '../../../images/icons/SettingsIcon';

import styles from './styles/MenuButton.module.css';

const MenuButton = ({ selectedPage, type }) => {
  const navigate = useNavigate();

  const navigateClick = useCallback(() => {
    navigate(`${type}`);
  }, [navigate, type]);

  return (
    <div
      className={`${styles.root} ${selectedPage === type ? styles.rootSelected : styles.rootNotSelected}`}
      onClick={navigateClick}
    >
      {type === MENU.PAGES.HOME && <HomeIcon />}
      {type === MENU.PAGES.CONTROLS && <LightIcon />}
      {type === MENU.PAGES.APPS && <MenuIcon />}
      {type === MENU.PAGES.SETTINGS && <SettingsIcon />}
    </div>
  );
};

MenuButton.propTypes = {
  selectedPage: PropTypes.string,
  type: PropTypes.string.isRequired,
};

MenuButton.defaultProps = {
  selectedPage: null,
};

export default memo(MenuButton);
