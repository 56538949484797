import React, { useContext, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/DateBlock.translations.js';

import styles from './styles/DateBlock.module.css';

const DateBlock = ({ devices }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 60000); // Обновление каждую минуту

        return () => clearInterval(intervalId);
    }, []);

    const locale = language === 'ru' ? 'ru-RU' : 'en-US';
    const dayOfWeek = currentDate.toLocaleString(locale, { weekday: 'long' });
    const date = currentDate.toLocaleString(locale, { day: 'numeric', month: 'long' });

    const isRecentlyUpdated = updatedAt => {
        const lastUpdatedTime = new Date(updatedAt);
        const hoursDiff = (new Date() - lastUpdatedTime) / (1000 * 60 * 60);
        return hoursDiff <= 12; // Проверка, что разница во времени не более 12 часов
    };

    const temperatureSensors = devices.filter(device => {
        return (device.deviceType === 'temperature_humidity' || device.deviceType === 'temperature') 
            && device.statusText 
            && isRecentlyUpdated(device.updatedAt);
    });

    const averageTemperature = temperatureSensors.length 
        ? (temperatureSensors.reduce((sum, sensor) => {
            try {
                const sensorData = sensor.statusText;
                return sum + (sensorData.temperature || 0);
            } catch (e) {
                console.error('Error parsing sensor data:', e);
                return sum;
            }
        }, 0) / temperatureSensors.length).toFixed(1)
        : null;

    return (
        <div className={`${styles.root} ${averageTemperature ? styles.rootTemperature : ''}`}>
            <div className={averageTemperature ? styles.timeBox : ''}>
                <p className={styles.dayOfWeek}>{dayOfWeek}</p>
                <p className={styles.date}>{date}</p>
            </div>
            {averageTemperature && <div className={styles.temperatureBox}>
                <p className={styles.temperature}>{averageTemperature}°C</p>
                <p className={styles.home}>{t.home}</p>
            </div>}
        </div>
    );
};

DateBlock.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.shape({})),
};

DateBlock.defaultProps = {
    devices: [],
};

export default memo(DateBlock);
