import React, { useContext, useEffect, useState, useCallback, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { saveSubscription } from '../../utils/api';
import InfoPopup from '../common/InfoPopup';
import { ClipLoader } from 'react-spinners';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/PushNotificationsManager.translations.js';

import styles from './styles/Instruction.module.css';

const PushNotificationsManager = ({ isShow, personalId, onClose, onSubscribe, onInstructionShown }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [isSubscribed, setIsSubscribed] = useState(true);
    const [isShowSubscriptionInstruction, setIsShowSubscriptionInstruction] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const sendSubscriptionToServer = useCallback(subscription => {
        saveSubscription(personalId, subscription).then(response => {
            console.log('Subscription saved on server:', response);
            onSubscribe();
        }).catch(error => {
            console.error('Failed to save subscription on server:', error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [onSubscribe, personalId]);

    const urlBase64ToUint8Array = useCallback(base64String => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }, []);

    const subscribeToPushManager = useCallback(() => {
        const applicationServerKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

        navigator.serviceWorker.ready.then(registration => {
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
            }).then(subscription => {
                console.log('Subscribed to push notifications:', subscription);
                setIsSubscribed(true);

                // Отправляем объект subscription на сервер
                sendSubscriptionToServer(subscription);
            }).catch(error => {
                setIsLoading(false);
                console.error('Failed to subscribe to push notifications:', error);
            });
        });
    }, [sendSubscriptionToServer, urlBase64ToUint8Array]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                registration.pushManager.getSubscription().then(subscription => {
                    if (subscription) {
                        console.log('User is subscribed to push notifications');
                        setIsSubscribed(true);
                    } else {
                        console.log('User is not subscribed to push notifications');
                        setIsSubscribed(false);
                    }
                });
            });
        }
    }, []);

    const requestNotificationPermission = useCallback(() => {
        setIsLoading(true);
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Permission to receive push notifications granted.');
                    subscribeToPushManager();
                } else {
                    setIsLoading(false);
                    console.error('Permission to receive push notifications was not granted.');
                }
            });
        }
    }, [subscribeToPushManager]);

    const { current: handleClose } = useRef(() => {
        setIsShowSubscriptionInstruction(false);
        onClose();
    });

    if (!isSubscribed && isShow && isShowSubscriptionInstruction) {
        onInstructionShown();

        return (
            <InfoPopup
                onClose={handleClose}
                isShouldCloseOnOutsideClick={false}
            >
                <h1 className={styles.title}>{t.title}</h1>
                <div className={styles.pushButtonBox}>
                    {isLoading && <ClipLoader color="#4A90E2" size={25} />}
                    {!isLoading && (
                        <button onClick={requestNotificationPermission} className={styles.button}>{t.buttonAllow}</button>
                    )}
                </div>
            </InfoPopup>
        );
    }

    return null;
};

PushNotificationsManager.propTypes = {
    isShow: PropTypes.bool,
    personalId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSubscribe: PropTypes.func,
    onInstructionShown: PropTypes.func,
};

PushNotificationsManager.defaultProps = {
    isShow: false,
    onClose: () => {},
    onSubscribe: () => {},
    onInstructionShown: () => {},
};

export default memo(PushNotificationsManager);
