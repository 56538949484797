import React, { useContext, useState, useEffect, memo } from "react";
import PropTypes from 'prop-types';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from '../language/LandingContainer.translations.js';

import styles from './styles/Header.module.css';

const Header = ({ scrollToForm, scrollToTop }) => {
  // const { language, toggleLanguage } = useContext(LanguageContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 100;
      setIsVisible(window.scrollY < threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.headerBox}>

      <div className={styles.gradientContainer}>
        <div className={styles.gradientContainerInner}>
          <div className={styles.gradientItem1}></div>
          <div className={styles.gradientItem2}></div>
          <div className={styles.gradientItem3}></div>
          <div className={styles.gradientItem4}></div>
          <div className={styles.gradientItem5}></div>
          <div className={styles.gradientItem6}></div>
          <div className={styles.gradientItem7}></div>
          <div className={styles.gradientItem8}></div>
        </div>
      </div>

      <div className={styles.headerContent}>
        <img
          src="../../images/logo/icon_black.png"
          alt="GETHOME Logo"
          className="icon"
          onClick={scrollToTop}
          style={{ cursor: "pointer" }}
        />

        <div className={styles.headerButtons}>
          {/* <button onClick={toggleLanguage} className={styles.button} style={{ opacity: isVisible ? 1 : 0, zIndex: isVisible ? 1 : -1 }}>{language}</button> */}
          <a href="/login" className={styles.button} style={{ opacity: isVisible ? 1 : 0, zIndex: isVisible ? 1 : -1, marginRight: "10px" }}>{t.loginButtonText}</a>
          <a onClick={scrollToForm} href="#requestForm" className={styles.ctaButton}>{t.ctaButtonText}</a>
        </div>
      </div>

    </div>
  );
};

Header.propTypes = {
  scrollToForm: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
};

export default memo(Header);
