import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DEVICE_TYPES } from '../../../../constants/deviceTypes';

import styles from './styles/HouseEditBlock.module.css';

const DeviceList = ({
    type,
    devices,
    handleNameChange,
    handleTypeChange,
    handleRoomChange,
    removeDevice,
    floors,
    rooms
}) => {
    return (
        <div>
            {type === 'output' && <h3 className={styles.title}>Выходы</h3>}
            {type === 'input' && <h3 className={styles.title}>Входы</h3>}
            {devices.map(device => (
                <div key={device.id} className={styles.inputBox}>
                    <p className={styles.number}>{device.line}</p>
                    <input
                        type="text"
                        value={device.name}
                        onChange={e => handleNameChange(device.id, e.target.value)}
                        className={styles.input}
                        placeholder="Название устройства"
                    />
                    
                    <select
                        value={device.type === null ? -1 : device.type}
                        onChange={e => handleTypeChange(device.id, e.target.value)}
                        className={`${styles.select} ${device.type === null ? styles.highlight : ''}`}
                    >
                        <option value="-1">Не выбрано</option>
                        {DEVICE_TYPES[type].map(type => (
                            Object.keys(type).map(key => (
                                <option key={key} value={key}>{type[key]}</option>
                            ))
                        ))}
                    </select>
                    
                    <select
                        value={device.roomId === null ? -1 : device.roomId}
                        onChange={e => handleRoomChange(device.id, Number(e.target.value))}
                        className={`${styles.select} ${device.roomId === null ? styles.highlight : ''}`}
                    >
                        <option value={-1}>Выберите комнату</option>
                        {floors.map(floor =>
                            rooms
                            .filter(room => room.floorId === floor.id)
                            .map(room => (
                                <option key={room.id} value={room.id}>
                                    {floor.name} - {room.name}
                                </option>
                            ))
                        )}
                    </select>

                    <button onClick={() => removeDevice(device.id)} className={styles.button}>Удалить</button>
                </div>
            ))}
            {devices.length === 0 && <p className={styles.text}>Нет устройств</p>}
        </div>
    );
};

DeviceList.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.oneOf(["input", "output"]).isRequired,
    handleNameChange: PropTypes.func.isRequired,
    handleTypeChange: PropTypes.func.isRequired,
    handleRoomChange: PropTypes.func.isRequired,
    removeDevice: PropTypes.func.isRequired,
    floors: PropTypes.arrayOf(PropTypes.object),
    rooms: PropTypes.arrayOf(PropTypes.object),
};

DeviceList.defaultProps = {
    devices: [],
    floors: [],
    rooms: [],
};

export default memo(DeviceList);
