import React, { useContext, useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import IosButton from '../common/IosButton';
import { getNotificationSettings, updateNotificationSettings, getTelegramData } from '../../utils/api';
import { ClipLoader } from 'react-spinners';
import PushNotificationsManager from '../instructions/PushNotificationsManager';
import { DEFAULT_NOTIFICATIONS, TYPE_NOTIFICATION_NAMES } from '../../../constants/notifications';

import { LanguageContext } from '../../../routes/LanguageContext';
import translations from './language/NotificationsSettings.translations.js';

import styles from './styles/settings.module.css';
import pageStyles from './styles/NotificationsSettings.module.css';

/* Какие уведомления мы отсылаем:
	- срабатывание датчиков протечек и задымления
	- когда охрана включена, то датчиков движения и открытия дверей
	- когда батарея устройства на низком уровне (battery_low) или battery меньше или равно 15
	- когда отключился и подключился хаб
	- когда мы не можем связаться с хабом длительное время и когда не можем получить данные устройства длительное время (или если какое-то устройства пропало из списка устройств в zigbee, хотя его не отключали)
*/

const NotificationsSettings = ({ isMobile, onClose, personalId }) => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState(DEFAULT_NOTIFICATIONS);
    const [isTelegramConnected, setIsTelegramConnected] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(true);
    const [isShowSubscriptionInstruction, setIsShowSubscriptionInstruction] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            const { data: dataNotification } = await getNotificationSettings(personalId);
            if (dataNotification.notificationData && Object.keys(dataNotification.notificationData).length) {
                setSettings(dataNotification.notificationData);
            } else {
                setSettings(DEFAULT_NOTIFICATIONS);
            }

            const { data: dataTelegram } = await getTelegramData();
            if (dataTelegram.telegramUsername) {
                setIsTelegramConnected(!!dataTelegram.telegramUsername);
            } else {
                setIsTelegramConnected(false);
            }
        } catch (error) {
            console.error('Error fetching notification settings:', error);
        } finally {
            setIsLoading(false);
        }
    }, [personalId]);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [fetchData]);

    const handleToggle = useCallback((type, channel) => {
        const updatedSettings = {
            ...settings,
            [type]: {
                ...settings[type],
                [channel]: !settings[type][channel],
            },
        };
        setSettings(updatedSettings);
        updateNotificationSettings(personalId, updatedSettings)
            .catch(error => console.error('Error updating notification settings:', error));
    }, [personalId, settings]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                registration.pushManager.getSubscription().then(subscription => {
                    setIsSubscribed(!!subscription);
                });
            });
        }
    }, []);

    return (
        <div className={styles.container}>
            {isMobile && <BackButton onClick={onClose} />}
            <div className={styles.formGroup}>
                <h3 className={styles.title}>{t.title}</h3>
                <p className={styles.text}>{t.text1}</p>
                <p className={styles.text}>{t.text2}</p>

                {isLoading ? (
                    <div className={styles.block}>
                        <ClipLoader color="#4A90E2" size={25} />
                    </div>
                ) : (
                    <>
                        {Object.keys(settings).map(type => (
                            <div key={type} className={styles.blockGray}>
                                <p className={styles.blockTitle}>{TYPE_NOTIFICATION_NAMES[language][type]}</p>
                                <div className={pageStyles.notificationChannels}>
                                    <div className={styles.blockRow}>
                                        <label>Push</label>
                                        <IosButton
                                            onClick={() => handleToggle(type, 'push')}
                                            isActive={settings[type]?.push}
                                        />
                                    </div>
                                    <div className={styles.blockRow}>
                                        <label>Telegram</label>
                                        <IosButton
                                            onClick={() => handleToggle(type, 'telegram')}
                                            isActive={settings[type]?.telegram}
                                        />
                                    </div>
                                    <div className={styles.blockRow}>
                                        <label>SMS</label>
                                        <IosButton
                                            onClick={() => handleToggle(type, 'sms')}
                                            isActive={settings[type]?.sms}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}

                        {!isSubscribed && (
                            <div className={styles.blockGray}>
                                <p className={styles.blockTitle}>{t.pushText}</p>
                                <div className={pageStyles.box}>
                                    <button
                                        className={pageStyles.addUserButton}
                                        onClick={() => setIsShowSubscriptionInstruction(true)}
                                    >
                                        {t.pushButton}
                                    </button>
                                </div>
                            </div>
                        )}

                        {!isTelegramConnected && (
                            <div className={styles.blockGray}>
                                <p>{t.telegramText}</p>
                            </div>
                        )}
                    </>
                )}
            </div>

            {isShowSubscriptionInstruction && (
                <PushNotificationsManager
                    personalId={personalId}
                    isShow={true}
                    onClose={() => setIsShowSubscriptionInstruction(false)}
                    onSubscribe={() => setIsSubscribed(true)}
                />
            )}
        </div>
    );
};

NotificationsSettings.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    personalId: PropTypes.string.isRequired,
};

export default memo(NotificationsSettings);
