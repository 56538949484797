
const translations = {
    ru: {
        devices: 'Устройства',
    },
    en: {
        devices: 'Devices',
    },
};

export default translations;
