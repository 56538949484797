
const translations = {
    ru: {
        yourHomes: 'Ваши Дома',
        noHomes: 'У вас нет домов',
    },
    en: {
        yourHomes: 'Your Homes',
        noHomes: 'You have no homes',
    },
};

export default translations;
