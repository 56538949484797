
const translations = {
    ru: {
        title: 'Разрешите приложению отправлять вам push-уведомления',
        buttonAllow: 'Разрешить уведомления',
    },
    en: {
        title: 'Allow the app to send you push notifications',
        buttonAllow: 'Allow notifications',
    },
};

export default translations;
