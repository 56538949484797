import React from 'react';

function MotionIcon() {
  return (
    <svg width="18pt" height="18pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: '4px' }}>
      <path d="m128.33 46.668c-12.887 0-23.332 10.445-23.332 23.332v420c0 12.887 10.445 23.332 23.332 23.332h443.34c12.887 0 23.332-10.445 23.332-23.332v-420c0-12.887-10.445-23.332-23.332-23.332zm0-46.668h443.34c38.66 0 70 31.34 70 70v420c0 38.66-31.34 70-70 70h-443.34c-38.66 0-70-31.34-70-70v-420c0-38.66 31.34-70 70-70zm221.67 396.67c-64.434 0-116.67-52.234-116.67-116.67s52.234-116.67 116.67-116.67 116.67 52.234 116.67 116.67-52.234 116.67-116.67 116.67zm0-46.668c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70z"/>
    </svg>
  );
}

export default MotionIcon;
