import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../../images/icons/CloseIcon';

import styles from './styles/HouseForm.module.css';

const HouseForm = ({ title, closeForm, children }) => {
  return (
    <div className={styles.form}>
      <button onClick={closeForm} className={styles.closeIconBtn}>
        <CloseIcon className={styles.closeIcon} />
      </button>
      {title && title !== '' && <h2 className={styles.formTitle}>{title}</h2>}
      {children}
    </div>
  );
};

HouseForm.propTypes = {
  title: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

HouseForm.defaultProps = {
  title: '',
};

export default memo(HouseForm);
