import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const PrivateWrapper = () => {
    const [cookies] = useCookies(['token']);
    const location = useLocation();

    if (!cookies.token) {
        return <Navigate to={`/login${location.search}`} state={{ from: location }} />;
    }

    return <Outlet />;
};

const CheckAuthWrapper = () => {
    const [cookies] = useCookies(['token']);
    const location = useLocation();

    if (cookies.token) {
        return <Navigate to={`/app${location.search}`} />;
    }

    return <Outlet />;
};

export { PrivateWrapper, CheckAuthWrapper };
