import React, { useEffect, useContext, memo } from "react";
import { Link } from "react-router-dom";

import { LanguageContext } from '../routes/LanguageContext';
import translations from './language/PrivacyPage.translations.js';

import styles from './styles/LandingContainer.module.css';

const PrivacyPage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  useEffect(() => {
    document.title = t.title;
  }, [t]);

  return (
    <div className={styles.landingPage}>
      <div className={styles.privacyHeaderSection}>
        <Link to="/">
          <img src="../../images/logo/icon_black.png" alt="GetHome Logo" className="icon" />
        </Link>
      </div>

      <div className={styles.privacyContainer}>
        <h1>{t.titlePrivacy}</h1>

        <p>{t.section1}</p>

        <h2>{t.section2}</h2>
        <p>{t.section2_1}</p>
        <p>{t.section2_2}</p>

        <h2>{t.section3}</h2>
        <p>{t.section3_1}</p>
        <p>{t.section3_2}</p>

        <h2>{t.section4}</h2>
        <p>{t.section4_1}</p>
        <p>{t.section4_2}</p>
        <p>{t.section4_3}</p>
        <p>{t.section4_4}</p>

        <h2>{t.section5}</h2>
        <p>{t.section5_1}</p>
        <p>{t.section5_2}</p>

        <h2>{t.section6}</h2>
        <p>{t.section6_1}</p>

        <h2>{t.section7}</h2>
        <p>{t.section7_1}</p>

        <h2>{t.section8}</h2>
        <p>{t.section8_1}</p>
        <p>{t.section8_2}</p>

        <h2>{t.section9}</h2>
        <p>{t.section9_1}</p>
        <p>{t.section9_2}</p>
        <p>{t.section9_3}</p>

        <h2>{t.section10}</h2>
        <p>{t.section10_1}</p>
        <p>{t.section10_2}</p>
        <p>{t.section10_3}</p>
      </div>
    </div>
  );
};

export default memo(PrivacyPage);
