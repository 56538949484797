
const translations = {
    ru: {
        title: 'Настройки уведомлений',
        text1: 'Здесь вы можете настроить уведомления о событиях в вашем доме.',
        text2: 'Уведомления настраиваются только для вашего аккаунта.',

        // push
        pushText: 'Push-уведомления для этого устройства не включены',
        pushButton: 'Подключить push-уведомления',

        // telegram
        telegramText: 'Чтобы получать уведомления в Telegram, подключите его в настройках во вкладке Telegram-бот.',
    },
    en: {
        title: 'Notification settings',
        text1: 'Here you can configure notifications about events in your home.',
        text2: 'Notifications are configured only for your account.',

        // push
        pushText: 'Push notifications for this device are not enabled',
        pushButton: 'Connect push notifications',

        // telegram
        telegramText: 'To receive notifications in Telegram, connect it in the settings in the Telegram bot tab.',
    },
};

export default translations;
