import React, { useContext, useState, useEffect, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { getUser } from '../api';
import ArrowIcon from '../images/icons/ArrowIcon';

import { LanguageContext } from '../../routes/LanguageContext';
import translations from './app/language/App.translations.js';

import styles from './app/styles/App.module.css';

// app components
import Home from './app/Home';
import Settings from './app/Settings';
import House from './app/House';

// admin components
import Admin from './admin/Admin';

const Menu = memo(({ admin, currentQuery, avatar }) => {
  // const { language, toggleLanguage } = useContext(LanguageContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  useEffect(() => {
    document.title = t.title;
  }, [t]);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['jbtoken']);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const menuButtonsRef = useRef();
  const menuRef = useRef();

  const { current: handleLogout } = useRef(() => {
    removeCookie('token', { path: '/' });
  });

  const toggleDropdown = useCallback(e => {
    e.stopPropagation();
    setDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  const { current: handleClickOutside } = useRef(e => {
    if (menuButtonsRef.current && !menuButtonsRef.current.contains(e.target) && menuRef.current && !menuRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  });

  const { current: handleMenuButton } = useRef(() => {
    setDropdownOpen(false);
  });

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isDropdownOpen]);

  const renderAvatar = useCallback(avatar => {
    return `../../../images/avatars/${avatar || 'avatar1'}.jpg`;
  }, []);

  return (
    <div className={styles.headerSection}>
      <Link to={currentQuery !== '' ? `../app/home${currentQuery}` : '../../'}>
        <img src="../../../images/logo/icon_black.png" alt="GetHome Logo" className="icon" />
      </Link>
      <div className={styles.headerButtons}>
        {/* <button onClick={toggleLanguage} className={styles.button}>{language}</button> */}
        <div className={styles.menuButton} onClick={toggleDropdown} ref={menuButtonsRef}>
          <img src={renderAvatar(avatar)} alt="User Avatar" className={styles.avatar} />
          <ArrowIcon className={styles.arrow} />
        </div>
        {isDropdownOpen && 
          <div className={styles.dropdownMenu} ref={menuRef}>
            {admin && <Link onClick={handleMenuButton} to="admin" className={styles.menuItem}>{t.menuAdmin}</Link>}
            <Link onClick={handleMenuButton} to="home" className={styles.menuItem}>{t.menuHome}</Link>
            {/* <Link onClick={handleMenuButton} to="settings" className={styles.menuItem}>{t.menuSettings}</Link> */}
            <Link onClick={handleLogout} className={styles.menuItem}>{t.menuLogout}</Link>
          </div>
        }
      </div>
    </div>
  );
});

Menu.propTypes = {
  admin: PropTypes.bool,
  currentQuery: PropTypes.string,
  avatar: PropTypes.string,
};

Menu.defaultProps = {
  admin: false,
  currentQuery: '',
  avatar: null,
};

const App = () => {
  const location = useLocation();
  const currentQuery = location.search;
  const [user, setUser] = useState(false);

  const { current: getUserData } = useRef(async () => {
    try {
      const response = await getUser();
      const userData = response.data.user;
      if (userData) {
        setUser(userData);
      }
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  if (user) {
    return (
      <div>
        <Menu admin={user?.admin} currentQuery={currentQuery} avatar={user?.avatar} />
        <div className={styles.appContainer}>
          <Routes>
            <Route path="home" element={<Home />} />
            <Route path="settings" element={<Settings />} />
            <Route path="house/:personalId/*" element={<House />} />

            {user.admin && <Route path="admin/*" element={<Admin />} />}

            <Route path="*" element={<Navigate to={`/app/home${currentQuery}`} />} />
          </Routes>
        </div>
      </div>
    );
  }

  return null;
};

export default memo(App);
